
import Login from '@/view/Login/Index'
import ForgetPWD from '@/view/Login/ForgetPWD'
import SetPwd from '@/view/Login/SetPwd'

export default [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/forget',
    name: 'ForgetPWD',
    component: ForgetPWD
  },
  {
    path: '/SetPwd',
    name: 'SetPwd',
    component: SetPwd
  }
]
