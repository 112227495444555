<template>
  <div class="wrap">
    <van-nav-bar title="编辑资料" left-text right-text left-arrow :border="false" @click-left="goBack" />
    <!-- 资料 S -->
    <div class="user-info">
      <div>
        <div class="user-avatar">头像</div>
        <single-upload v-model="userInfo.avatar" :iStyle="photoStyle"></single-upload>
      </div>
      <div>
        <div>名称</div>
        <input v-model="userInfo.realName" placeholder="请输入名称" />
      </div>
      <div>
        <div>角色</div>
        <div>{{userInfo.roleName}}</div>
      </div>
    </div>
    <!-- 资料 E -->

    <div class="common-button" @click="updateInfo">更新资料</div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import mine from "@/store/mine/index";
import { GETUSERINFO, UPDATEMEMBERINFO } from "@/store/mine/types";
import singleUpload from "@/components/Upload/singleUpload";
import { Toast } from "vant";
export default {
  data() {
    return {
      photoStyle: ` width: 40px;height: 40px;border-radius: 50%;overflow: hidden;`
    };
  },
  components: {
    singleUpload
  },
  computed: {
    ...mapState({
      userInfo: state => mine.state.userInfo
    })
  },
  created() {
    this.$store.dispatch(GETUSERINFO, { type: 1 });
  },
  methods: {
    // 返回上一个页
    goBack() {
      this.$router.go(-1);
    },
    // 更新资料
    updateInfo() {
      if (this.userInfo.avatar) {
        if (this.userInfo.realName) {
          this.$store.dispatch(UPDATEMEMBERINFO, {
            avatar: this.userInfo.avatar,
            realName: this.userInfo.realName
          });
        } else {
          Toast("名称不能为空");
        }
      } else {
        Toast("请先上传头像");
      }
    }
  }
};
</script>


<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;
  background-color: #eff0f5;
  .user-info {
    background: #fff;
    margin: 10px 13px 0 13px;
    border-radius: 10px;
    // 头像
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    > div {
      width: 90%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      font-size: 15px;
      color: #232b38;
      border-bottom: 1px solid #e6e7ed;
      padding: 20px 0;
      vertical-align: middle;
    }
    // 头像
    & > div:nth-of-type(1) {
      padding: 8px 0;
      div.user-avatar {
        position: relative;
        top: 17px;
      }
    }
    //名称
    & > div:nth-of-type(2) {
      input {
        text-align: right;
        font-size: 15px;
      }
    }
    // 角色
    & > div:nth-last-of-type(1) {
      border-bottom: none;
    }
  }
}
.common-button {
  margin: 27px 13px 0 13px;
}
/* placeholder  */
input::-webkit-input-placeholder {
  color: #babdc2;
  font-size: 15px;
}
</style>

