<template>
  <div class="container">
          <van-nav-bar title="常见问题" left-text right-text left-arrow :border="false" @click-left="goBack" />
    <van-tabs v-model="type">
      <van-tab title="单向通话">
        <div class="font24">
          {{ conetnt }}
        </div>
        <van-cell-group>
          <van-field
            maxlength="11"
            type="tel"
            @input="phonetext"
            v-model="phone"
            label="号码"
            placeholder="请输入电话号码"
          />
        </van-cell-group>
        <div class="mg20">
          <van-button
            color="#2c92f9"
            type="primary"
            @click="handlePhone"
            class="btn-phone"
            >拨打</van-button
          >
        </div>
      </van-tab>
      <van-tab title="双向通话">
        <div class="font24">
          {{ conetnt }}
        </div>
        <van-cell-group>
          <van-field
            v-model="phone"
            label="号码"
            placeholder="请输入电话号码"
          />
        </van-cell-group>
        <div class="mg20">
          <van-button
            color="#2c92f9"
            type="primary"
            @click="handlePhone"
            class="btn-phone"
            >拨打</van-button
          >
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import http from "@/utils/http";
import { Toast } from "vant";
export default {
  data() {
    return {
      reg: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      value: "",
      phone: "", //监护电话字段
      type: 0, //0、单线通话 1、双向通话
      conetnt: `提示：使用此功能，必须办理实名认证的移动SIM卡，（设备自带卡为物联网卡不能进行电话听音与通话）点击电话按钮成功后设备会拨打下方设置的号码，注意接听！
`,
    };
  },
  methods: {
    //       goBack() {
    //   this.$router.go(-1);
    // },
    // 验证手机号码输入
    phonetext(value) {
      var reg = /[0-9]/;
      if (value.length >= 11) {
        this.phone = this.phone.slice(0, 11);
      }
      if (!reg.test(value.charAt(value.length - 1))) {
        console.log(this.phone);
        // this.account = this.account.slice(0, this.account.length - 1)
        this.phone = this.phone.replace(/[^0-9]/gi, "");
        console.log(this.phone);
      } else {
      }
    },
    handlePhone() {
      if (this.phone) {
        if (this.reg.test(this.phone)) {
          http.post("/p-gps-callPhone", {
              type: this.type,
              phone: this.phone,
              id: this.id,
            })
            .then((res) => {
              if (res.code == "ok") {
                this.$toast("拨打成功");
              } else {
                Toast.fail(res.message);
              }
            });
        } else {
          this.$toast("请输入正确的手机号码");
        }
      } else {
        this.$toast("请输入手机号");
      }
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
  },
};
</script>
<style lang="less" scoped>
.btn-phone {
  width: 100%;
  box-sizing: border-box;
  // margin:0 auto;
}
.mg20 {
  margin: 20px;
}
.font24 {
  font-size: 20px;
  margin: 20px;
  line-height: 30px;
}
</style>
