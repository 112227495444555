<template>
  <div>
    <div class="search-wrap"
         @click="searchLocus">
      {{date}}
      <span v-if="beginTime">({{beginTime}} - {{endTime}})</span>
      <span class="more"></span>
    </div>
    <!-- 暂无相关轨迹点 S -->
    <div class="no-points-data no-data"
         v-if="noPoint ||noimei">没有相关轨迹信息</div>
    <!--   <div v-if="noPoint||noimei"
         class="no-data">
      <img src="../../assets/imgs/icon_kong.png" />
      <div>暂无相关轨迹点</div>
    </div>-->
    <!-- 暂无相关轨迹点 E -->
    <div id="mapPanel">
      <div class="map-loading-center">
        <van-loading type="spinner"
                     color="#1989fa"
                     style="margin: 0 auto" />
      </div>
    </div>
    <!----->
    <v-bar :active="active"
           style="position: fixed;buttom:0;left:0"></v-bar>
  </div>
</template>
<script>
import { loadingMap } from '@/utils/load'
import { mapState } from 'vuex'
import mine from '@/store/mine/index'
import vanBar from '@/view/componse/vartarbbar'
import { LOCQUERY } from '@/store/mine/types'
import { constants } from 'fs'
export default {
  components: {
    'v-bar': vanBar
  },
  data () {
    return {
      map: null, // 地图实例
      walking: null, //
      points: [],
      centerPoint: null,
      car: null,
      label: null,
      index: 0,
      active: 1,
      timer: null,
      imei: '',
      noimei: true
    }
  },
  mounted () {
    let nowEquiment_teacher = sessionStorage.getItem('nowEquiment_teacher') ? JSON.parse(sessionStorage.getItem('nowEquiment_teacher')) : null

    // console.log(nowEquiment_teacher, nowEquiment_teacher.IMEI, nowEquiment_teacher && nowEquiment_teacher.IMEI)
    if (nowEquiment_teacher && (nowEquiment_teacher.IMEI || nowEquiment_teacher.imei)) {
      this.noimei = false
      this.imei = nowEquiment_teacher.IMEI ? nowEquiment_teacher.IMEI : nowEquiment_teacher.imei
      this.$store.dispatch(LOCQUERY, {
        IMEI: this.imei,
        startTime: this.date + ' ' + this.beginTime,
        endTime: this.date + ' ' + this.endTime
      })
      this.initMap()
    } else {
      this.noimei = true
      this.initMap()
    }
  },
  watch: {
    pointList: {
      handler: function () {
        console.log(this.pointList)
        this.points = []
        if (this.pointList.length > 0) {
          this.pointList.map((item, index) => {
            this.points.push(new BMap.Point(item.lon, item.lat))
          })
          this.initRoute()
        } else {
          console.log('watch-pointList')
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      noPoint: state => mine.state.noPoint,
      pointList: state => mine.state.pointList,
      beginTime: state => mine.state.beginTime,
      endTime: state => mine.state.endTime,
      date: state => mine.state.date
    })
  },
  methods: {
    initRoute () {
      this.map ? '' : this.initMap() // 地图尚未实例化
      this.map.centerAndZoom(this.points[0], 15)
      this.map.enableScrollWheelZoom()
      this.walking = new BMap.WalkingRoute(this.map, { renderOptions: { map: this.map, autoViewport: true } })
      var sdatae = this.points.slice(0, -1)
      // 随机抽选20个点
      // var arr = []

      // var j = 20
      // for (var i = 0; i < j; i++) {
      //   let n = this.getRandomNumberByRange(Math.floor(sdatae.length / j) * i, Math.floor(sdatae.length / j) * (i + 1))
      //   arr.push(sdatae[n])
      // }

      // this.walking.search(this.points[0], this.points[this.points.length - 1], { waypoints: arr })
      // this.walking.search(new BMap.Point(this.points[0].lng, this.points[0].lat), new BMap.Point(this.points[this.points.length - 1].lng, this.points[this.points.length - 1].lat))
      let _this = this
      function run () {
        console.log(132)
        _this.walking.setSearchCompleteCallback(() => {
          // 得到路线上的所有point
          var points = _this.walking.getResults().getPlan(0).getRoute(0).getPath()
          // 画面移动到起点和终点的中间
          // _this.centerPoint = new BMap.Point((points[0].l + points[_this.points.length - 1].lng) / 2, (_this.points[0].lat + points[points.length - 1].lat) / 2)
          // _this.map.panTo(_this.centerPoint)
          // 连接所有点
          _this.map.addOverlay(new BMap.Polyline(points, { strokeColor: 'red', strokeWeight: 0, strokeOpacity: 1 }))
        })
        //   _this.play(0)
      }
      // 每十个点循环 画一次
      var chartData = []
      chartData = Object.assign([], this.points)
      console.log(chartData, this.points)
      // var group = Math.floor(chartData.length / 10)
      // var mode = chartData.length % 10
      // if (mode != 0) {
      //   if (group < 0) {
      //     var waypoints = chartData.slice(1, chartData.length - 1)
      //     this.walking.search(chartData[0], chartData[chartData.length - 1], { waypoints: waypoints })
      //   } else {
      //     var waypoints = chartData.slice(group * 10, chartData.length - 1)
      //     this.walking.search(chartData[group * 10], chartData[chartData.length - 1], { waypoints: waypoints })
      //   }
      //   // run()
      // }

      // for (var i = 0; i < group; i++) {
      //   var waypoints = chartData.slice(i * 10 + 1, (i + 1) * 10)

      //   this.walking.search(chartData[i * 10], chartData[(i + 1) * 10], { waypoints: waypoints })
      // }
      // run()
      // 定义起点终点
      var myIcon2 = new BMap.Icon('http://wwmimgs.oss-cn-shenzhen.aliyuncs.com/gps/2019-07-29/icon_zhogndian.png', new BMap.Size(34, 40), { imageOffset: new BMap.Size(0, 0) })// 起点
      var myIcon1 = new BMap.Icon('http://wwmimgs.oss-cn-shenzhen.aliyuncs.com/gps/2019-07-29/icon_qidian.png', new BMap.Size(34, 40), { imageOffset: new BMap.Size(0, 0) })/// 终点
      var marker1 = new BMap.Marker(chartData[0], { icon: myIcon1 })
      var marker2 = new BMap.Marker(chartData[chartData.length - 1], { icon: myIcon2 })

      setTimeout(function () {
        _this.map.setViewport(chartData)
        _this.map.addOverlay(marker1)
        _this.map.addOverlay(marker2)
      }, 3000)
      this.map.enableScrollWheelZoom() // 启用滚轮放大缩小，默认禁用
      this.map.enableContinuousZoom() // 启用地图惯性拖拽，默认禁用
      this.map.clearOverlays()
      // 使用折现
      var linePoints = chartData
      var polyline = new BMap.Polyline(linePoints, { strokeColor: 'red', strokeWeight: 2, strokeOpacity: 0.5 })
      this.map.addOverlay(polyline)
      // 多点跑
      // 实例化一个驾车导航用来生成路线

      // var arr = chartData
      // console.log(arr)
      // var drv = new BMap.WalkingRoute(chartData[0], {
      //   onSearchComplete: function (res) {
      //     if (drv.getStatus() == BMAP_STATUS_SUCCESS) {
      //       var plan = res.getPlan(0)
      //       var arrPois = []
      //       for (var j = 0; j < plan.getNumRoutes(); j++) {
      //         var route = plan.getRoute(j)
      //         arrPois = arrPois.concat(route.getPath())
      //       }
      //       _this.map.addOverlay(new BMap.Polyline(arrPois, { strokeColor: '#FF432F' }))
      //       _this.map.setViewport(arrPois)
      //     }
      //   }
      // })

      // for (var i = 0; i < arr.length - 1; i++) {
      //   var start = arr[i]
      //   var end = arr[i + 1]
      //   drv.search(start, end)
      // }
    },
    // 选择时间
    searchLocus () {
      this.$store.commit('SETLOCUSPOINT', { pointList: 0, noPoint: false })
      console.log(this.imei)
      this.$router.push('/mine/device/selectdate')
    },
    // 取随机数
    getRandomNumberByRange (start, end) {
      return Math.floor(Math.random() * (end - start) + start)
    },
    play (index) {
      console.log(index)
      var point = this.points[index]
      if (index > 0) {
        this.map.addOverlay(new BMap.Polyline([this.points[index - 1], point], { strokeColor: 'red', strokeWeight: 1, strokeOpacity: 1 }))
      }
      this.label.setContent('经度: ' + point.lng + '<br>纬度: ' + point.lat)
      this.car.setPosition(point)
      index++
      if (index < this.points.length) {
        this.timer = setTimeout(this.play(index), 600)
      } else {
        this.map.panTo(point)
      }
    },
    // 返回上一页
    goBack () {
      this.$router.push('/mine/devicemanage')
    },
    // 初始化地图
    initMap () {
      this.map = new BMap.Map('mapPanel')
      this.map.enableScrollWheelZoom() // 启用滚轮放大缩小，默认禁用
      this.map.enableContinuousZoom() // 启用地图惯性拖拽，默认禁用
      // 初始城市上海
      this.map.centerAndZoom(new BMap.Point(121.480539, 31.235929), 15)
      this.map.addEventListener('dragend', () => {
      })
    }
  }
}
</script>

<style lang="less" scoped>
#mapPanel {
  height: 650px;
  width: 100%;
  background-color: rgba(76, 76, 76, 0.4);
}
.no-data {
  top: 80px;
}
.search-wrap {
  text-align: center;
  position: absolute;
  width: 94%;
  top: 20px;
  z-index: 100;
  left: calc((100% - 94%) / 2);
  background: #fff;
  color: #232b38;
  font-size: 17px;
  border-radius: 10px;
  padding: 17px 0;
  box-shadow: 0px 2px 10px 0px rgba(51, 51, 51, 0.2);
  span.more {
    width: 0;
    height: 0;
    border-width: 7px 7px 0;
    border-style: solid;
    border-color: #232b38 transparent transparent; /*灰 透明 透明 */
    position: relative;
    top: 12px;
    left: 6px;
  }
}

.map-loading-center {
  position: absolute;
  top: 100px;
  left: 180px;
}
</style>
