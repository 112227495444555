import Vue from 'vue'
import Vuex from 'vuex'
// 状态管理模块
import example from './example/index'
import find from './find/index'
import mine from './mine/index'
import login from './login/index'
import position from './position/index'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    example,
    find,
    mine,
    login,
    position
  }
})
