<template>
  <div class="container">
    <van-nav-bar title="忘记密码"
                 left-arrow
                 :border="false"
                 @click-left="onClickLeft" />
    <div class="content">
      <van-cell-group class="cell_group_phone">
        <van-field v-model="phone"
                   class="count_pwd"
                   maxlength="11"
                   type="tel"
                   @input="phonetext"
                   placeholder="请输入手机号" />
      </van-cell-group>
      <van-cell-group class="cell_group_code">
        <van-field v-model="vertifyCode"
                   clearable
                   @input="hanldleInput"
                   class="count_pwd"
                   placeholder="请输入验证码">
          <van-button slot="button"
                      size="small"
                      round
                      @click="handleSend"
                      :disabled="s60s"
                      type="info">{{s60s?times+'秒后重新获取':'获取动态码'}}</van-button>
        </van-field>
      </van-cell-group>
      <div @click="handlelogin"
           :disabled="disabled"
           class="login-button common-button">下一步</div>
    </div>
  </div>
</template>
<script>
import http from '@/utils/http'
import login from '@/store/login/index'
import { setInterval, clearInterval } from 'timers'
import { Toast } from 'vant'
export default {
  data () {
    return {
      phone: '',
      vertifyCode: '',
      s60s: false,
      disabled: true,
      times: 60,
      timer: null,
      reg: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/
    }
  },
  computed: {},
  methods: {
    handleSend () {
      this.times = 60
      if (this.phone) {
        if (this.reg.test(this.phone)) {
          this.s60s = true
          let _this = this
          this.timer = setInterval(() => {
            this.times--
            console.log(_this.times)
            if (_this.times <= 0) {
              _this.s60s = false
              clearInterval(_this.timer)
            }
          }, 1000)
          // 发送验证码
          http.post('/m-sms-vertifycode-send', {
            phone: this.phone,
            comeFrom: 'gps-user-forgetpwd'
          }).then(res => {
            if (res.code != 'ok') {
              Toast.fail(res.message)
            } else {

            }
          })
        } else {
          this.$toast('请输入正确的手机号码')
        }
      } else {
        this.$toast('请输入手机号')
      }
    },

    onClickLeft () {
      this.$router.go(-1)
    },
    hanldleInput () {
      this.disabled = false
    },
    // 验证手机号码输入
    phonetext (value) {
      var reg = /[0-9]/
      if (value.length >= 11) {
        this.phone = this.phone.slice(0, 11)
      }
      if (!reg.test(value.charAt(value.length - 1))) {
        console.log(this.phone)
        // this.account = this.account.slice(0, this.account.length - 1)
        this.phone = this.phone.replace(/[^0-9]/gi, '')
        console.log(this.phone)
      } else {
      }
    },
    handlelogin () {
      if (this.phone) {
        if (this.reg.test(this.phone)) {
          http.post('/m-sms-vertifycode-check', {
            phone: this.phone,
            vertifyCode: this.vertifyCode,
            comeFrom: 'gps-user-forgetpwd'
          })
            .then(res => {
              if (res.code == 'ok') {
                this.$router.push('/SetPwd')
                let userd = {
                  vertifyCode: this.vertifyCode,
                  phone: this.phone
                }
                sessionStorage.setItem('userd', JSON.stringify(userd))
              } else {
                Toast.fail(res.message)
              }
            })
        } else {
          this.$toast('请输入正确的手机号码')
        }
      } else {
        this.$toast('请输入手机号')
      }
    }
  },
  mounted () { },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>
<style scoped>
.count_pwd {
  font-family: Medium, "PingFangSC-Medium";
  font-size: 16px;
  color: #babdc2;
  height: 63px;
  margin: auto;
}
.van-cell__value--alone {
  margin: auto;
}
.header {
  position: absolute;
  top: 16px;
  left: 152px;
  font-size: 18px;
  font-family: Medium, "PingFangSC-Medium";
  color: #2d3e4b;
}
.header_img {
  position: absolute;
  top: 14px;
  left: 14px;
}
.container {
  position: relative;
  background: #ffffff;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  text-align: left;
  height: 666px;
  border: none;
  width: 375px;
}
.container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  text-align: left;
}
.content {
  width: 100%;
  margin-top: 35px;
}
.login-button {
  margin-top: 50px;
}
.cell_group_code {
  margin: 0 20px;
}
.cell_group_phone {
  margin: 0 20px;
}
.cell_group_phone.van-hairline--top-bottom::after {
  border-width: 0;
}
.count_pwd {
  padding: 15px 0 18px 0;
}
</style>
