import * as types from './types'
import http from '@/utils/http'

let state = {
  locLastquerydata: {}, // p-gps-locLast-query   GPS项目-设备位置经纬度查询(黄进安)
  equipmentEasyquerydata: {}, // p-gps-equipmentEasy-query  GPS项目-获取所属设备列表(黄进安)
  equipmentadddata: {}// p-gps-equipment-add  GPS项目-添加设备(黄进安)
}

let actions = {
  [types.WECHATSIGN] (context, params) { // 获取微信签名
    http.post('/p-gps-wechant-sign', params).then(data => {
      context.commit(types.WECHATSIGN, data)
    })
  },
  [types.LOCLASTQUERY] (context, params) { // 短信模块-短信校验码校验
    http.post('/p-gps-locLast-query', params).then(data => {
      context.commit(types.LOCLASTQUERY, data)
    })
  },
  [types.EQUIPMENTEASYQUERY] (context, params) { // 短信模块-短信校验码校验
    http.post('/p-gps-equipmentEasy-query', params).then(data => {
      context.commit(types.EQUIPMENTEASYQUERY, data)
    })
  },
  [types.EQUIPMENTADD] (context, params) { // 短信模块-短信校验码校验
    http.post('/p-gps-equipment-add', params).then(data => {
      context.commit(types.EQUIPMENTADD, data)
    })
  }
}

let mutations = {
  [types.WECHATSIGN] (state, data) {
    if(data.code == "ok") {
      console.log("get-success", data)
      state.signData = data.data
    }
  },
  [types.EQUIPMENTEASYQUERY] (state, data) {
    state.equipmentEasyquerydata = data
  },
  [types.EQUIPMENTADD] (state, data) {
    state.equipmentadddata = data
  }
}

export default { state, actions, mutations }
