<template>
  <div class="wrap">
    <van-search placeholder="请输入搜索关键词" v-model="keyword" />
    <!-- @click="mapClick" -->
    <baidu-map
      :center="center"
      :zoom="5"
      @ready="handler"
      class="map"
      @touchstart="tStart"
      @touchend="tEnd"
    >
      <!-- 关键字检索结果面板:panel="false" -->
      <bm-local-search
        :keyword="keyword"
        class="search-penel"
        :auto-viewport="true"
        :location="location"
      ></bm-local-search>
      <!-- 标记 -->
      <bm-marker
        :position="{lng: 116.404, lat: 39.915}"
        :dragging="true"
        animation="BMAP_ANIMATION_BOUNCE"
        @dragend="makerEnd"
      ></bm-marker>
      <!-- 城市控件 -->
      <bm-city-list anchor="BMAP_ANCHOR_BOTTOM_LEFT"></bm-city-list>
      <!-- 右下角自动定位 -->
      <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
      <!-- 地图类型 -->
      <bm-map-type
        :map-types="['BMAP_NORMAL_MAP', 'BMAP_PERSPECTIVE_MAP','BMAP_HYBRID_MAP','BMAP_SATELLITE_MAP']"
        offset="5"
        anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
      ></bm-map-type>
      <!-- 海量点 -->
      <bm-point-collection
        :points="points"
        shape="BMAP_POINT_SHAPE_STAR"
        color="green"
        size="BMAP_POINT_SIZE_SMALL"
        @click="clickHandler"
      ></bm-point-collection>
    </baidu-map>
  </div>
</template>
<script>
import Vue from 'vue'
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'w0xIuTu7ZCymWOc8U9Sma0pXiWFFK1Wc'
})
export default {
  data () {
    return {
      center: { lng: 0, lat: 0 },
      zoom: 3,
      value: '',
      keyword: '',
      location: '',
      points: []
    }
  },
  created () { },
  methods: {
    handler ({ BMap, map }) {
      console.log(BMap, map)
      this.center.lng = 116.404
      this.center.lat = 39.915
      this.zoom = 18
    },
    addPoints () {
      const points = []
      for (var i = 0; i < 1000; i++) {
        const position = { lng: Math.random() * 40 + 85, lat: Math.random() * 30 + 21 }
        points.push(position)
      }
      this.points = points
    },
    clickHandler () {
      alert(`单击点的坐标为：${e.point.lng}, ${e.point.lat}`)
    },
    // mapClick(e) {
    //     console.log("e", e)
    // },
    tStart () {

    },
    tEnd (e) {
      // alert("e",e)
    },
    makerEnd (e) {
      console.log('e', e)
    }
  }
}
</script>
<style scoped>
.map {
  width: 100%;
  height: 20rem;
}
.van-search {
  width: 95%;
  position: absolute;
  top: 3rem;
  left: 0.5rem;
  z-index: 9999;
  background: transparent !important;
}
.wrap .van-search__content.van-search__content--square {
  opacity: 0.4;
  padding: 0.3rem;
  background: rgb(125, 134, 131);
  color: #fff;
}
.search-penel {
  /* position: absolute;
    top: 327px;
    left: 50px; */
}
</style>
