/**
 * 时间日期相关操作
 */


/**
 * 时间格式化
 * 将 2018-09-23T11:54:16.000+0000 格式化成 2018/09/23 11:54:16
 * @param datetime 国际化日期格式
 */
export function format (datetime) {
    return formatWithSeperator(datetime, "/", ":");
  }
  
  /**
   * 时间格式化
   * 将 2018-09-23T11:54:16.000+0000 格式化成类似 2018/09/23 11:54:16
   * 可以指定日期和时间分隔符
   * @param datetime 国际化日期格式
   */
  export function formatWithSeperator (datetime, dateSeprator, timeSeprator) {
    if (datetime != null) {
      const dateMat = new Date(datetime);
      const year = dateMat.getFullYear();
      const month = dateMat.getMonth() + 1;
      const day = dateMat.getDate();
      const hh = dateMat.getHours();
      const mm = dateMat.getMinutes();
      const ss = dateMat.getSeconds();
      const timeFormat = year + dateSeprator + month + dateSeprator + day + " " + hh + timeSeprator + mm + timeSeprator + ss;
      return timeFormat;
    }
  }

  // 时间中文格式化
  export function formatWithSeperatorstr(datetime) {
    var h = 0, i = 0, s = datetime;
    if (s > 60) {
      i = parseInt(s / 60);
      s = parseInt(s % 60);
      if (i > 60) {
        h = parseInt(i / 60);
        i = parseInt(i % 60);
      }
    }
    // 补零
    var zero = function (v) {
      return (v >> 0) < 10 ? "0" + v : v;
    };
    const timeFormat = zero(h) + '时' + zero(i) + '分' + zero(s) + '秒';
    return [zero(h), zero(i), zero(s)];
  }
  
  /**
   * 时间格式化
   * 将 2018-09-23T11:54:16.000+0000 格式化成类似 2018/09/23
   * 可以指定日期和时间分隔符
   * @param datetime 国际化日期格式
   */
  export function formatWithSeperatorOnday (datetime, dateSeprator) {
    console.log(datetime)
    if (datetime != null) {
      datetime=datetime.split("T")[0]
      const dateMat = datetime.split("-");
      const year = dateMat[0];
      const month =dateMat[1];
      const day = dateMat[2];
     
      const timeFormat = year + dateSeprator + month + dateSeprator + day ;
      console.log(timeFormat)
      return timeFormat;
    }
  }
  
  /**
   * 时间格式化
   * 将 2018-09-23T11:54:16.000+0000 格式化成 2018/09/23
   * @param datetime 国际化日期格式
   */
  export function formatDay(datetime){
    datetime=formatWithSeperatorOnday(datetime, "-");
    return  datetime
  }
  /**
   * 
   * 获取系统时间格式
   */
  export function getDateLocation(s){
        let nowDate = new Date();
        let year = nowDate.getFullYear();
        let month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1)
          : nowDate.getMonth() + 1;
        let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate
          .getDate();
        s = year + "-" + month + "-" + day;  // "2018-03-26"
        return s;
  }
  
  export function formatDate(date, fmt) {
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
    };
    for (let k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        let str = o[k] + '';
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
      }
    }
    return fmt;
  }
  function padLeftZero(str) {
    return ('00' + str).substr(str.length);
  }
  