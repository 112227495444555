<template>
  <div class="container">
    <div class="header">恩嘉爱尔管理系统(老师版)</div>
    <van-cell-group class="cell_group_acc">
      <van-field v-model="account"
                 class="count_pwd"
                 @input="phonetext"
                 maxlength="11"
                 type="tel"
                 placeholder="请输入手机号" />
    </van-cell-group>
    <van-cell-group class="cell_group_pwd">
      <van-field v-model="password"
                 clearable
                 class="count_pwd"
                 :error="pwError"
                 maxlength="12"
                 :error-message="pwErrorText"
                 :type="ispassword"
                 placeholder="请输入登录密码">
        <img :src="yincang"
             class="van-icon"
             v-if="ispassword=='password'"
             slot="right-icon"
             @click="ispassword='text'" />
        <img :src="xianshi"
             slot="right-icon"
             class="van-icon"
             v-if="ispassword=='text'"
             @click="ispassword='password'" />
      </van-field>
    </van-cell-group>
    <div @click="handlelogin"
         v-if="!loading"
         class="login-button common-button">立即登录</div>
    <van-button v-else
                :loading="loading"
                type="info"
                class="login-button common-button width335"
                disabled
                round
                loading-text="正在登陆..." />
    <div class="forget-wrap">
      <span class="forget"
            @click="handleclick">忘记密码</span>
    </div>
    <van-overlay :show="show">登陆中……</van-overlay>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { MEMBERLOGIN } from '@/store/login/types'
import { Overlay, Toast } from 'vant'
import login from '@/store/login/index'
import Cookies from 'js-cookie'
import http from '@/utils/http'

export default {
  components: {
    'van-overlay': Overlay
  },
  data () {
    return {
      yincang: require('../../assets/imgs/icon_yincang@2x.png'),
      xianshi: require('../../assets/imgs/icon_xianshi@2x.png'),
      ispassword: 'password',
      password: '',
      account: '',
      request: {},
      user: '',
      regphone: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/, //
      regPw: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/,
      pwErrorText: '',
      pwError: false,
      show: false,
      loading: false
    }
  },
  computed: {
    ...mapState({
      memberlogindata: state => login.state.memberlogindata
    })
  },
  methods: {
    // 验证密码输入
    pwtest () {
      // 验证输入的最后一个值是不是符合数字或者字母
      let reg = /[^a-zA-Z0-9]/
      if (reg.test(this.password)) {
        this.pwError = true
        this.pwErrorText = '必须输入字母加数字的组合密码'
      } else {
        this.pwError = false
        this.pwErrorText = ''
      }
    },
    // 验证手机号码输入
    phonetext (value) {
      var reg = /[0-9]/
      if (value.length >= 11) {
        this.account = this.account.slice(0, 11)
      }
      if (!reg.test(value.charAt(value.length - 1))) {
        // this.phoneErrorMess = '手机号码为数字'
        console.log(this.account)
        // this.account = this.account.slice(0, this.account.length - 1)
        this.account = this.account.replace(/[^0-9]/gi, '')
        console.log(this.account)
      } else {
      }
    },
    start () {
      this.user = Cookies.get('user')
      let token = this.user
      if (token) {
        Cookies.set('user', token)
        this.account = token.account
        this.password = token.password
        // this.xtoken = token
      } else {
        this.xtoken = window.localStorage.getItem('xtoken')
      }
    },
    handlelogin () {
      sessionStorage.setItem('nowEquiment_teacher', '')
      if (this.account) {
        if (this.regphone.test(this.account)) {
          if (this.password) {
            this.loading = true
            this.request.account = this.account
            this.request.password = this.password
            this.request.type = 1
            http.post('/p-gps-member-login', this.request).then(res => {
              this.loading = false
              if (res.code == 'ok') {
                localStorage.setItem('phone', this.request.account)
                let xtoken = { xtoken: res.data.xtoken, type: 1 }
                Cookies.set('gps_xtoken_teacher', JSON.stringify(xtoken))
                Cookies.set('user', {
                  account: this.request.account,
                  password: this.request.password,
                  id: res.data.id
                })
                this.$router.push({ path: '/' })
              } else {
                Toast.fail(res.message || '网络不可用')
              }
            })
          } else {
            this.$toast('请输入密码')
          }
        } else {
          this.$toast('请输入正确手机号')
          this.loading = false
        }
      } else {
        this.$toast('请输入手机号')
      }
    },
    handleclick () {
      this.$router.push({ path: '/forget' })
    }
  },
  mounted () {
    console.log(this.yincang)
  }
}
</script>
<style lang="less" scoped>
.count_pwd {
  font-family: Medium, "PingFangSC-Medium";
  font-size: 16px;
  color: #babdc2;
  margin: auto;
}
.van-cell__value--alone {
  margin: auto;
}
.van-icon {
  width: 20px;
  height: 14px;
  display: inline-block;
}
.cell_group_pwd {
  margin: 0 20px;
}
.forget-wrap {
  text-align: right;
  padding: 5px 23px 0 0;
}
.forget {
  font-size: 14px;
  color: #0068b7;
}
.width335 {
  width: 335px;
  height: 50px;
}
/** 账号 */
.cell_group_acc {
  margin: 0 20px;
}
input:-internal-autofill-selected {
  background-color: #ffffff !important;
}
.login-button {
  margin-top: 26px;
}
.header {
  padding: 82px 0 22px 20px;
  font-size: 22px;
  font-weight: bold;
  font-family: Medium, "PingFangSC-Medium";
  color: #142029;
}
.container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  text-align: left;
}
.cell_group_acc.van-hairline--top-bottom::after {
  border-width: 0;
}
.count_pwd {
  padding: 15px 0 18px 0;
}
</style>
