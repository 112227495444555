<template>
  <div class="wrap">
    <div class="title">使用vuex count: {{count}}</div>
    <van-row offset="4">
      <van-col span="3">
        <van-button type="default"
                    @click="increment">+</van-button>
      </van-col>
      <van-col span="3">
        <van-button type="primary"
                    @click="decrement">-</van-button>
      </van-col>
    </van-row>

    <div class="title">mock(先要开启mock服务):</div>
    <van-row>
      <van-col span="21">
        <div v-for="(item, index) in data"
             :key="index"
             class="mock-item">
          名字：{{item.name}}
          时间：{{item.date}}
          住址：{{item.address}}
        </div>
      </van-col>
    </van-row>

    <div class="title">vue-baidu-map实例(参考):</div>
    <van-row>
      <van-col span="7">
        <van-button type="default"
                    @click="viewDemo">查看实例</van-button>
      </van-col>
    </van-row>
    <!-- <! -- <b-map class='map' v-if='longitude' :longitude='longitude' :latitude='latitude' :width="w" :height="h"></b-map> -->
    <!-- <baidu-map :center="center" :zoom="zoom" @ready="handler" class="map"></baidu-map> -->

    <div class="title">百度地图(参考):</div>
    <van-row>
      <van-col span="7">
        <van-button type="default"
                    @click="viewBMap">查看实例</van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import BMap from "@/components/BMap";
import example from '@/store/example/index';
import vanBar from '@/view/componse/vartarbbar';
import { INCREMENT, DECREMENT, TABLEDATA } from '@/store/example/types';
export default {
  data () {
    return {
      longitude: '116.404',
      latitude: '39.915',
      w: 0,
      h: 752,
      center: { lng: 0, lat: 0 },
      zoom: 3,
      active: 0
    }
  },
  components: {
    // "b-map": BMap,
    'v-bar': vanBar
  },
  computed: {
    ...mapState({
      count: state => example.state.count,
      data: state => example.state.data
    })
  },
  mounted () { },
  created () {
    /**
       dispatch 异步操作
       * params1: actions方法名
       * params2: 提交的值
      */
    this.w = window.innerWidth - 20
    this.$store.dispatch(TABLEDATA, { id: 1 })
  },
  methods: {
    increment () {
      /**
       commit 同步操作
       * params1: mutations方法名
       * params2: 提交的值
       * */
      this.$store.commit(INCREMENT)
    },
    decrement () {
      this.$store.commit(DECREMENT)
    },
    handler ({ BMap, map }) {
      console.log(BMap, map)
      this.center.lng = 116.404
      this.center.lat = 39.915
      this.zoom = 15
    },
    viewDemo () {
      this.$router.push('/example/vbmap')
    },
    viewBMap () {
      this.$router.push('/example/bmap')
    }
  }
}
</script>

<style scoped>
.wrap {
  margin: 20px 13px;
}
.title {
  text-align: left;
  font-weight: bold;
  font-size: 15px;
  margin: 5px 0;
}
.van-row {
  /* margin: .5rem 0; */
}
.map {
  width: 100%;
  height: 600px;
}
.mock-item {
  /* margin: .5rem 0; */
  font-size: 15px;
}
</style>
