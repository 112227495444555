<template>
  <button @click="getStatus" :disable="isDisabled">{{text}}</button>
</template>

<script>
import { Toast } from "vant";
import mine from "@/store/mine/index";
import { GETVERIFYCODE } from "@/store/mine/types";

const textArr = ["获取验证码", "秒后重新获取"];

export default {
  name: "GetCode",
  data() {
    return {
      text: textArr[0],
      isDisabled: false
    };
  },
  props: ["phone", "password", "member"],
  methods: {
    getStatus() {
      if (this.isMobile(this.phone)) {
        if (this.isDisabled) {
          // 禁用
        } else {
          this.$store
            .dispatch(GETVERIFYCODE, {
              phone: this.phone,
              comeFrom: "gps-user-forgetpwd"
            })
            .then(
              setTimeout(() => {
                this.getCode();
              }, 100)
            );
        }
      } else {
        Toast("请输入正确的手机号!");
      }
    },
    getCode() {
      // 获取验证码
      let times = 60;
      const countDown = () => {
        setTimeout(() => {
          times--;
          this.text = times + textArr[1];
          if (times) {
            countDown();
          } else {
            this.isDisabled = false;
            this.text = textArr[0];
          }
        }, 1000);
      };
      if (!this.isDisabled) {
        this.isDisabled = true;
        this.text = times + textArr[1];
        countDown();
      }
    },
    // 判断电话
    isMobile(mobile) {
      return /^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1})|(17[0-9]{1}))+\d{8})$/.test(
        mobile
      );
    }
  }
};
</script>

<style scoped>
button {
  position: absolute;
  top: -0.25px;
  right: 0;
  color: #fff;
  background: #2c92f9;
  font-size: 12px;
  padding: 4px 12px;
  border-radius: 15px;
  z-index: 999;
}
</style>
