import Vue from 'vue'
import Router from 'vue-router'
import Cookies from 'js-cookie'

import examlpe from '@/router/common/example'
import find from '@/router/common/find'
import mine from '@/router/common/mine'
import Login from '@/router/common/Login'
import Position from '@/router/common/Position'
import guiji from '@/router/common/guiji'

Vue.use(Router)

const router = new Router({
  routes: [
    ...examlpe,
    ...find,
    ...mine,
    ...Login,
    ...Position,
    ...guiji
  ]
})

router.beforeEach((to, from, next) => {
  let token = Cookies.get('gps_xtoken_teacher')
  if (token && token !== 'undefined') { // xtoken 存在
    if (to.path === '/') {
      next({ path: '/Position' })
    } else {
      next()
    }
  } else { // xtoken 不存在
    if (to.path === '/login' || to.path === '/forget' || to.path === '/SetPwd' || to.path === '/mine/appquestion') {
      next()
    } else {
      next({ path: '/login' })
    }
  }
})
export default router
