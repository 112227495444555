<template>
  <div class="wrap">
    <van-nav-bar title="账号安全" left-text right-text left-arrow :border="false" @click-left="goBack" />
    <!-- 内容 S-->
    <div class="content-wrap">
      <van-cell-group>
        <van-cell>
          <template>
            <div class="sec-wrap">
              <div>手机号</div>
              <input
                v-model="form.phone"
                type="tel"
                maxlength="11"
                @input="handleInput"
                placeholder="请输入手机号码"
                readonly="true"
              />
            </div>
          </template>
        </van-cell>
        <van-cell>
          <template>
            <div class="sec-wrap">
              <div>验证码</div>
              <input v-model="form.vertifyCode" placeholder="请输入验证码" type="number"/>
              <get-code :phone="form.phone" :password="form.password"></get-code>
            </div>
          </template>
        </van-cell>
        <van-cell>
          <template>
            <div class="sec-wrap">
              <div>新密码</div>
              <input v-model="form.password" type="password" maxlength="12" placeholder="请输入新密码" />
            </div>
          </template>
        </van-cell>
        <van-cell>
          <template>
            <div class="sec-wrap last">
              <div>确认新密码</div>
              <input v-model="comPwd" type="password" maxlength="12" placeholder="请输入新密码" />
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <!--  内容 E-->

    <!-- 确认修改 S -->
    <div class="common-button" @click="submit">确认修改</div>
    <!-- 确认修改 E -->
  </div>
</template>
<script>
import { Toast } from "vant";
import GetCode from "./child/GetCode";
import mine from "@/store/mine/index";
import { GETVERIFYCODE, PHONECHANGEPWD } from "@/store/mine/types";
export default {
  data() {
    return {
      form: {
        phone: localStorage.getItem('phone'),
        vertifyCode: "",
        password: ""
      },
      comPwd: "",
      reg: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/
    };
  },
  components: { GetCode },
  methods: {
    // 判断电话
    isMobile(mobile) {
      return /^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1})|(17[0-9]{1}))+\d{8})$/.test(
        mobile
      );
    },
    goBack() {
      this.$router.go(-1);
    },
    // 限制只输入数字
    handleInput(e) {
      this.form.phone = e.target.value.replace(/[^\d]/g, "");
    },
    // 确认修改
    submit() {
      if (this.form.phone === "") {
        Toast("请输入手机号码");
      } else if (!this.isMobile(this.form.phone)) {
        Toast("请输入正确的手机号");
      } else if (this.form.vertifyCode === "") {
        Toast("验证码不为空");
      } else if (this.form.password === "") {
        Toast("请输入新密码");
      } else if (this.comPwd === "") {
        Toast("请再次输入新密码");
      } else if (!this.reg.test(this.form.password)) {
        Toast("密码格式错误，必须输入6-12位字母加数字的组合密码");
      } else if (this.form.password !== this.comPwd) {
        Toast("两次输入密码不一致");
      } else {
        this.$store.dispatch(PHONECHANGEPWD, {
          phone: this.form.phone,
          vertifyCode: this.form.vertifyCode,
          password: this.form.password,
          comeFrom: "gps-user-forgetpwd"
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;
  background-color: #eff0f5;
  .common-button {
    margin: 25px 13px 0 13px;
  }
  & .sec-wrap.last input {
    margin: 0 0 0 20px;
  }
  .sec-wrap {
    display: flex;
    position: relative;
    // border: 1px solid red;
    padding: 6px 0;
    & > div {
      color: #232b38;
      font-size: 15px;
      font-weight: 400;
    }
    & > input {
      margin: 0 0 0 49px;
      font-size: 15px;
      color: #babdc2;
    }
    & > span {
      position: absolute;
      top: -0.25px;
      right: 0;
      color: #fff;
      background: #2c92f9;
      font-size: 12px;
      padding: 4px 12px;
      border-radius: 15px;
      z-index: 999;
    }
  }
}
/* placeholder  */
input::-webkit-input-placeholder {
  color: #babdc2;
  font-size: 15px;
}
</style>
