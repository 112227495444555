import * as types from './types'
import http from '@/utils/http'
import router from '../../router/index'
import { Toast } from 'vant'
function add0(m) {
  return m < 10 ? '0' + m : m
}
function format(t) {
  if (t) {
    var time = new Date(t)
  } else {
    var time = new Date()
  }
  var y = time.getFullYear() // 年
  var m = time.getMonth() + 1 // 月
  var d = time.getDate() // 日
  var h = time.getHours()
  var mm = time.getMinutes()
  var s = time.getSeconds()
  if (t) {
    return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);  // 精确到秒
  } else {
    return y + '-' + add0(m) + '-' + add0(d)
  }
}
// 格式化开关状态
function formatStatus(status) {
  if (status == 1) { // 状态开启
    return true
  } else { // 状态关闭
    return false
  }
}
// 格式化 通知方式参数
function formatWarnWay(status) {
  if (status == 0) { // 通知方式没选
    return status = []
  } else if (status == 3) { // 全选
    return status = ['1', '2']
  } else { // 选择一种
    return status = [status]
  }
}
var EARTH_RADIUS = 6378137.0 // 单位M
var PI = Math.PI
function getRad(d) {
  return d * PI / 180.0
}
// 计算经纬度两点之间距离
function getFlatternDistance(lat1, lng1, lat2, lng2) {
  var f = getRad((lat1 + lat2) / 2)
  var g = getRad((lat1 - lat2) / 2)
  var l = getRad((lng1 - lng2) / 2)

  var sg = Math.sin(g)
  var sl = Math.sin(l)
  var sf = Math.sin(f)

  var s, c, w, r, d, h1, h2
  var a = EARTH_RADIUS
  var fl = 1 / 298.257

  sg = sg * sg
  sl = sl * sl
  sf = sf * sf

  s = sg * (1 - sl) + (1 - sf) * sl
  c = (1 - sg) * (1 - sl) + sf * sl

  w = Math.atan(Math.sqrt(s / c))
  r = Math.sqrt(s * c) / w
  d = 2 * w * a
  h1 = (3 * r - 1) / 2 / c
  h2 = (3 * r + 1) / 2 / s
  return d * (1 + fl * (h1 * sf * (1 - sg) - h2 * (1 - sf) * sg)) // 返回单位 m
}
function calTimeDiff(start, end) {
  var utc = end - start
  utc = Math.abs(utc)
  return utc / (60 * 60 * 1000).toFixed(2)
}
let state = {
  noPoint: false,
  noData: false,
  noQuestionList: false, // 常见问题
  noPositionPoint: false,
  data: [],
  questionList: [],
  userInfo: {}, // 用户信息
  // 设备管理
  allLoaded: false, // 加载完成
  isLoad: true, // 正在加载
  pageSize: 5,
  page: 0,
  total: 0,
  equipmentStatus: '',
  deviceList: [],
  deviceInfo: [],
  equipInfo: {}, // 设备详情
  lEquipInfo: {},
  equipInfoId: '',
  enclosure: [],
  locationTimeText: '',
  begin: '',
  end: '',
  selectAutoTime: '',
  setD: '',
  isSet: false,
  speed: 0, // 速度
  // 下拉列表
  isGetList: false, // 是否请求列表
  gradeList: [],
  classList: [],
  atuoPositionTime: [], // 自动定位时间
  locationModel: [], // 定位模式
  sltModel: [], // 选择的定位模式
  sltText: [],
  lng: '', // 最新经纬度点
  lat: '',
  type: '', // 定位模式
  updateTime: '', // 更新时间
  site: '', // 最新经纬度点 定位
  // 轨迹
  pointList: [],
  date: format(),
  beginTime: '00:00',
  endTime: '23:59'
}

let actions = {
  [types.GETUSERINFO](context, params) { // 获取用户信息
    http.post('/p-gps-getLoginUserInfo', params).then(data => {
      context.commit(types.GETUSERINFO, data)
    })
  },
  [types.DROPDOWNOPTION](context, params) { // 获取下拉框选项
    http.post('/p-gps-dropdownBox-query', params).then(data => {
      context.commit(types.DROPDOWNOPTION, data, params)
    })
  },
  [types.GRADELIST](context, params) { // 获取年级列表
    http.post('/p-gps-gradeDept-query', params).then(data => {
      context.commit(types.GRADELIST, data)
    })
  },
  [types.CLASSLIST](context, params) { // 获取班级列表
    http.post('/p-gps-classDept-query', params).then(data => {
      context.commit(types.CLASSLIST, data)
    })
  },
  [types.UPDATEMEMBERINFO](context, params) { // 更新用户信息
    http.post('/p-gps-memberForH5-update', params).then(data => {
      context.commit(types.UPDATEMEMBERINFO, data)
    })
  },
  [types.DICTIONARY](context, params) { // 常见问题
    http.post('/p-gps-dictionary-query', params).then(data => {
      context.commit(types.DICTIONARY, data)
    })
  },
  // 设备管理
  [types.EQUIPMENTLIST](context, params) { // 设备管理列表
    state.page += 1
    state.equipmentStatus = params.equipmentStatus
    http.post('/p-gps-equipmentMore-query', { page: state.page, pageSize: state.pageSize, equipmentStatus: state.equipmentStatus }).then(data => {
      context.commit(types.EQUIPMENTLIST, data)
    })
  },
  [types.EQUIPMENTDELETE](context, params) { // 删除设备/设备解绑
    http.post('/p-gps-equipment-detele', params).then(data => {
      context.commit(types.EQUIPMENTDELETE, data)
    })
  },
  [types.EQUIPMENTDETAIL](context, params) { // 设备详情
    http.post('/p-gps-equipment-detail', params).then(data => {
      context.commit(types.EQUIPMENTDETAIL, data)
    })
  },
  [types.LEQUIPMENTDETAIL](context, params) { // 定位设备详情
    http.post('/p-gps-equipment-detail', params).then(data => {
      context.commit(types.LEQUIPMENTDETAIL, data)
    })
  },
  [types.EQUIPMENTUPDATE](context, params) { // 编辑设备信息
    state.equipInfoId = params.id
    http.post('/p-gps-equipment-update', params).then(data => {
      context.commit(types.EQUIPMENTUPDATE, data)
    })
  },
  // 安全围栏
  [types.ENCOLSUREEDIT](context, params) { // 安全围栏编辑
    http.post('/p-gps-enclosure-update', params).then(data => {
      context.commit(types.ENCOLSUREEDIT, data.data)
    })
  },
  [types.ENCOLSUREQUERY](context, params) { // 安全围栏查询
    http.post('/p-gps-enclosureByUser-query', params).then(data => {
      context.commit(types.ENCOLSUREQUERY, data)
    })
  },
  [types.ENCOLSUREADD](context, params) { // 新增安全围栏
    http.post('/p-gps-enclosure-add', params).then(data => {
      context.commit(types.ENCOLSUREADD, data)
    })
  },
  [types.EQUIPMENTSETUPUPDATE](context, params) { // 编辑设备设置
    http.post('/p-gps-equipmentWarnSetUp-update', params).then(data => {
      context.commit(types.EQUIPMENTSETUPUPDATE, data)
    })
  },
  // 设备位置经纬度查询
  [types.LOCQUERY](context, params) {
    http.post('/p-gps-loc-query', params).then(data => {
      context.commit(types.LOCQUERY, data)
    })
  },
  // 设备最新轨迹点
  [types.LOCLAST](context, params) {
    http.post('/p-gps-locLast-query', params).then(data => {
      context.commit(types.LOCLAST, data)
    })
  },
  // 账户安全
  [types.GETVERIFYCODE](context, params) { // 手机获取验证码
    http.post('/m-sms-vertifycode-send', params).then(data => {
      context.commit(types.GETVERIFYCODE, data)
    })
  },
  [types.PHONECHANGEPWD](context, params) { // 账号改变密码
    http.post('/p-gps-user-phoneChangePwd', params).then(data => {
      context.commit(types.PHONECHANGEPWD, data)
    })
  }

}

let mutations = {
  SETID(state, data) {
    state.equipInfoId = data.id
  },
  // 重置参数设备列表 分页参数
  RESETPARAMS(state) {
    state.page = 0
    state.equipmentStatus = ''
    state.noData = false
    state.allLoaded = false
    state.isLoad = true
    // 清空累加的数据
    state.deviceList = []
  },
  // 改变分页加载状态
  CHANGELOAFDSTATUS(state, data) {
    // console.log('改变分页加载状态', data);
    state.isLoad = data.isLoad
  },
  // 设置全部加载完成
  ALLLOAD(state) {
    state.allLoaded = true
    state.isLoad = false
  },
  // 设置设备轨迹 默认选取的时间
  SETLOCUSDATE() {
    state.date = format()
    state.beginTime = "00:00"
    state.endTime = "23:59"
  },
  // 设置设备轨迹 无数据状态
  SETLOCUSPOINT(state) {
    state.noPoint = false
    state.pointList = []
  },
  // 设置设备定位 无数据状态
  SETPOSITIONPOINT(state, data) {
    state.noPositionPoint = false
  },
  // 设备设置选择定位模式
  SELETEMODEL(state, data) {
    state.sltModel.forEach((item) => {
      if (data.length >= 1) { // 有选
        if (data.indexOf(item.value) != -1) {
          if (item.value == data[data.indexOf(item.value)]) {
            item.status = 1
          }
        } else {
          item.status = 2
        }
      } else { // 没选
        item.status = 2
      }
    })
  },
  GETGRADEANDCLASS(state, data) {
    state.isGetList = data
  },
  // 年级、班级列表
  [types.GRADELIST](state, data) {
    if (data.code == 'ok') {
      if (data.data.length > 0) {
        data.data.map((item, index) => {
          item.text = item.fullname
        })
        state.gradeList = data.data
      } else {
        state.gradeList = []
        // Toast('暂无学校可选')
      }
    } else {
      Toast(data.message)
    }
  },
  [types.CLASSLIST](state, data) {
    if (data.code == 'ok') {
      if (data.data.length > 0) {
        data.data.map((item, index) => {
          item.text = item.fullname
        })
        state.classList = data.data
      } else {
        state.classList = []
      }
      // Toast('暂无班级可选')
    } else {
      Toast(data.message)
    }
  },
  // 常见问题
  [types.DICTIONARY](state, data) {
    if (data.code == 'ok') {
      let list = data.data
      if (list.length > 0) {
        state.noQuestionList = false
        list.map((item, index) => {
          item.visible = false
        })
        state.questionList = list
      } else {
        state.noQuestionList = true
      }
    } else {
      Toast(data.message)
    }
  },
  // 账户安全
  [types.PHONECHANGEPWD](state, data) {
    if (data.code == 'ok') {
      Toast('修改成功')
      setTimeout(() => {
        router.go(-1)
      }, 3000)
    } else {
      Toast(data.message)
    }
  },
  [types.GETVERIFYCODE](state, data) {
    if (data.code == 'ok') {
      Toast('验证码正在发送...')
    } else {
      Toast(data.message)
    }
  },
  [types.GETUSERINFO](state, data) {
    if (data.code == 'ok') {
      state.userInfo = data.data
      if (state.userInfo.hasOwnProperty('avatar')) {
      } else {
        state.userInfo.avatar = 'http://wwmimgs.oss-cn-shenzhen.aliyuncs.com/gps/2019-07-26/icon_touxiang%402x.png'
      }
    } else {
      Toast(data.message)
    }
  },
  [types.DROPDOWNOPTION](state, data, p) {
    if (data.code == 'ok') {
      data.data.automaticPositioningTime.map((item, index) => {
        item.text = item.dictLaber
      })
      data.data.locationModel.map((item, index) => {
        item.text = item.dictLaber
      })
      state.atuoPositionTime = data.data.automaticPositioningTime
      state.locationModel = data.data.locationModel
    } else {
      Toast(data.message)
    }
  },
  [types.UPDATEMEMBERINFO](state, data) {
    if (data.code == 'ok') {
      Toast('更新成功')
      setTimeout(() => {
        router.go(-1)
      }, 2000)
    } else {
      Toast(data.message)
    }
  },
  // 设备
  [types.EQUIPMENTLIST](state, data) {
    if (data.code == 'ok') {
      let index = data.data
      if (index.hasOwnProperty('totalNum')) {
      } else {
        index.totalNum = 0
        index.onlineNum = 0
        index.outNum = 0
      }
      Promise.all(index.dataList.map(item => {
        var geoc = new BMap.Geocoder()
        if (item.hasOwnProperty('lastLoc')) {
          var point = new BMap.Point(item.lastLoc.lon, item.lastLoc.lat) // 更新后经纬度点
          return new Promise(r => {
            geoc.getLocation(point, (rs) => {
              var site = rs.address
              item.site = site;
              r(site)
            })
          })
        } else {
          item.site = '暂无位置信息';
        }
      })).then(function (posts) {
        if (index.page = 1) {
          if (index.dataList.length >= 1) {
            state.deviceList = state.deviceList.concat(index.dataList)
            state.isLoad = false
          }
        }
      })
      // 分页判断
      if (index.page = 1) {
        if (index.dataList.length >= 1) {
          if (index.total <= state.pageSize) { // 只有一页
            state.allLoaded = true
          }
        } else { // 暂无数据
          state.noData = true
        }
      }
      state.deviceInfo = index // 赋值
    } else {
      Toast(data.message)
    }
  },
  [types.EQUIPMENTDELETE](state, data) {
    if (data.code == 'ok') {
      Toast('设备解绑成功')
      // 更新列表数据
      setTimeout(() => {
        state.page = 0
        state.deviceList = []
        this.dispatch(types.EQUIPMENTLIST, {
          page: state.page,
          pageSize: 5,
          equipmentStatus: state.equipmentStatus
        });
      }, 2000)
    } else {
      Toast(data.message)
    }
  },
  [types.LEQUIPMENTDETAIL](state, data) {
    if (data.code == 'ok') {
      state.lEquipInfo = data.data
    } else {
      Toast(data.message)
    }
  },
  SETAUTOTIME(state, data) {
    state.selectAutoTime = data.val + '-' + data.text
  },
  SETGET(state) {
    state.isSet = false
    state.begin = ''
    state.end = ''
  },
  // 设置 休眠时间开关
  SETDRMANCYSTATUS(state, data) {
    state.setD = data
    state.isSet = true
    if (state.setD) {  // 休眠时间
      state.equipInfo.dormancyStatus = formatStatus(state.setD)
    } else {
      state.equipInfo.dormancyStatus = formatStatus(state.equipInfo.dormancyStatus)
    }
    if (state.begin) { // 若有重新设置开始时间
      state.equipInfo.dormancyStartTime = state.begin
    } else {
      state.equipInfo.dormancyStartTime = state.equipInfo.dormancyStartTime
    }
    if (state.end) { // 若有重新设置结束时间
      state.equipInfo.dormancyEndTime = state.end
    } else {
      state.equipInfo.dormancyEndTime = state.equipInfo.dormancyEndTime
    }
    if (state.selectAutoTime) { // 重新选择过自动定位时间
      state.equipInfo.locationTime = state.selectAutoTime.split('-')[0]
    } else {
    }
  },
  [types.EQUIPMENTDETAIL](state, data) {
    if (data.code == 'ok') {
      state.sltModel = []
      state.equipInfo = data.data
      state.equipInfo.enclosureWarnStatus = formatStatus(state.equipInfo.enclosureWarnStatus) // 围栏报警状态
      state.equipInfo.sleepStatus = formatStatus(state.equipInfo.sleepStatus) // 深度睡眠
      state.equipInfo.lowElectricWarnStatus = formatStatus(state.equipInfo.lowElectricWarnStatus) // 低电报警状态
      state.equipInfo.dormancyStatus = formatStatus(state.equipInfo.dormancyStatus) // 休眠时间
      state.equipInfo.equipmentLamp = formatStatus(state.equipInfo.equipmentLamp) // 设备灯开关
      state.equipInfo.enclosureWarnMode = formatWarnWay(state.equipInfo.enclosureWarnMode) // 围栏-通知方式
      state.equipInfo.powerWarnMode = formatWarnWay(state.equipInfo.powerWarnMode) // 低电-通知方式

      // 定位模式
      state.sltModel.push({ value: 1, status: state.equipInfo.locationModeGPS, text: 'GPS+A-GPS+北斗' },
        { value: 2, status: state.equipInfo.locationModeWIFI, text: 'WIFI' },
        { value: 3, status: state.equipInfo.locationModeLBS, text: 'LBS基站' })
      if (state.isGetList) {
        this.dispatch(types.GRADELIST, { schoolId: state.equipInfo.schoolDeptId })
        this.dispatch(types.CLASSLIST, { gradeId: state.equipInfo.gradeDeptId })
        state.isGetList = false
      }
      if (state.equipInfo.hasOwnProperty('equipmentPic')) {
      } else {
        state.equipInfo.equipmentPic = 'http://wwmimgs.oss-cn-shenzhen.aliyuncs.com/gps/2019-07-24/%E9%BB%98%E8%AE%A42.png'
      }
    }
  },
  [types.EQUIPMENTUPDATE](state, data) {
    if (data.code == 'ok') {
      Toast('保存成功')
      setTimeout(() => {
        this.dispatch(types.EQUIPMENTDETAIL, { id: state.equipInfoId })
      }, 2000)

    } else {
      Toast(data.message)
    }
  },
  [types.ENCOLSUREADD](state, data) {
    console.log('tjhis', this)
  },
  [types.ENCOLSUREQUERY](state, data) {
    if (data.code == 'ok') {
      state.enclosure = data.data
    }
  },
  [types.ENCOLSUREEDIT](state, data) {
    if (data.code == 'ok') {
      Toast('更新成功')
    }
  },
  [types.EQUIPMENTSETUPUPDATE](state, data) {
    if (data.code == 'ok') {
      Toast('保存成功')
      state.begin = ''
      state.end = ''
      state.selectAutoTime = ''
      state.setD = ''
      setTimeout(() => {
        this.dispatch(types.EQUIPMENTDETAIL, { id: data.data })
      }, 5000)
    } else {
      Toast(data.message)
    }
  },
  // 轨迹 1:没有信息 2:只有newPoint 3:两点都有
  [types.LOCLAST](state, data) { // 最近经纬度点
    if (data.code == 'ok') {
      let condition;
      if (data.data.hasOwnProperty('newPoint') && data.data.hasOwnProperty('nextPoint')) {
        // 两个点
        condition = 3
      }
      if ((data.data.hasOwnProperty('newPoint') == true) && (data.data.hasOwnProperty('nextPoint') == false)) {
        // 只有一个点 nextPoint
        condition = 2
      }
      switch (condition) {
        case 3: //  /** 3 两点都有*/
          state.lng = data.data.newPoint.loc.lon
          state.lat = data.data.newPoint.loc.lat
          state.type = data.data.newPoint.loc.type
          state.updateTime = data.data.newPoint.versionTime
          // 速度计算 参数
          let lat1 = data.data.newPoint.loc.lat
          let lng1 = data.data.newPoint.loc.lon
          let lat2 = data.data.nextPoint.loc.lat
          let lng2 = data.data.nextPoint.loc.lon
          let time1 = data.data.newPoint.versionTime
          let time2 = data.data.nextPoint.versionTime
          // 速度计算 公式
          let distance = getFlatternDistance(lat1, lng1, lat2, lng2) / 1000 // 单位：km
          let timeGap = calTimeDiff(time1, time2)  // 单位：h
          state.speed = (distance / timeGap).toFixed(2)
          console.log('距离-时间-速度=', distance, timeGap, state.speed)
          break;
        case 2: //  /** 2 只有newPoint*/
          state.lng = data.data.newPoint.loc.lon
          state.lat = data.data.newPoint.loc.lat
          state.type = data.data.newPoint.loc.type
          state.updateTime = data.data.newPoint.versionTime
          state.speed = 0
          console.log('只有一个点-速度为', state.speed)
          break;
        default:
          console.log("else");
      }
      // 地址
      var geoc = new BMap.Geocoder()
      var point = new BMap.Point(state.lng, state.lat) // 更新后经纬度点
      geoc.getLocation(point, function (rs) {
        state.site = rs.address
      })
      this.dispatch(types.LEQUIPMENTDETAIL, { id: state.equipInfoId })
    } else {
      /** 1  没有点*/
      Toast(data.message)
      // 暂无设备定位
      state.lng = ''
      state.lat = ''
      state.type = ''
      state.site = ''
      state.speed = 0
      state.updateTime = ''
      state.noPositionPoint = true
      this.dispatch(types.LEQUIPMENTDETAIL, { id: state.equipInfoId })
    }
  },
  [types.LOCQUERY](state, data) { // 设备轨迹点
    if (data.code == 'ok') {
      state.noPoint = false
      let list = data.data
      list.map((item, index) => {
        let obj = {}
        obj.lon = item.g.loc.lon
        obj.lat = item.g.loc.lat
        state.pointList.push(obj)
      })
    } else {
      state.pointList = []
      state.noPoint = true
      Toast(data.message)
    }
  },
  LOCSET(state, data) {
    state.date = data.date
    state.beginTime = data.begin
    state.endTime = data.end
    console.log("index-123", state.beginTime, state.endTime)
  },
  SETBEGTIME(state, data) {
    state.begin = data
  },
  SETENDTIME(state, data) {
    state.end = data
  }
}

export default { state, actions, mutations }
