<template>
  <div class="wrap">
    <van-nav-bar title="个人中心"
                 left-text
                 right-text
                 :left-arrow="false"
                 :border="false" />
    <!-- 个人信息 S-->
    <div class="user-info">
      <img :src="userInfo.avatar" />
      <div>
        <div class="user-name">{{userInfo.realName}}</div>
        <div class="user-role">角色：{{userInfo.roleName}}</div>
      </div>
      <span class="enter"
            @click="$router.push('/mine/editinfo')"></span>
    </div>
    <!-- 个人信息 E-->

    <!-- 个人中心导航 S  -->
    <div class="cells-group">
      <van-cell-group>
        <van-cell title="设备管理"
                  is-link
                  class="a"
                  to="/mine/devicemanage">
          <template slot="icon">
            <div class="cell-icon shebei"></div>
            <!-- <img class="cell-icon" src="../../assets/imgs/mine/icon_shebei@2x.png"> -->
          </template>
        </van-cell>
        <van-cell title="安全围栏"
                  is-link
                  class="a"
                  to="/mine/fence">
          <template slot="icon">
            <div class="cell-icon weilan"></div>
          </template>
        </van-cell>
        <van-cell title="常见问题"
                  is-link
                  class="a"
                  to="/mine/question">
          <template slot="icon">
            <div class="cell-icon wenti"></div>
          </template>
        </van-cell>
        <van-cell title="账号安全"
                  is-link
                  class="a"
                  to="/mine/security">
          <template slot="icon">
            <div class="cell-icon anquan"></div>
          </template>
        </van-cell>
        <!-- <van-cell title="当前版本"
                  class="a">
          <template slot="icon">
            <div class="cell-icon banben"></div>
          </template>
        </van-cell>-->
      </van-cell-group>
    </div>
    <!-- 个人中心导航 E  -->

    <!-- 退出登录 S -->
    <div class="login-out common-button"
         :class="loading? 'in-loadimg': ''">
      <van-loading type="spinner"
                   class="load-loading"
                   v-if="loading" />
      <span @click="logOut">{{loading ? '正在退出': '退出登录'}}</span>
    </div>
    <!-- 退出登录 E -->

    <!-- 技术支持 S-->
    <div class="tech-tip">技术支持：福建问问猫网络科技有限公司</div>
    <!-- 技术支持 E-->
    <v-bar :active="active"></v-bar>
  </div>
</template>
<script>
import vanBar from '@/view/componse/vartarbbar';
import { mapState } from 'vuex';
import mine from '@/store/mine/index';
import { GETUSERINFO } from '@/store/mine/types';
import Cookies from 'js-cookie';
import config from '@/utils/config';
import { setTimeout } from 'timers';
export default {
  data () {
    return {
      active: 3,
      loading: false
    }
  },
  computed: {
    ...mapState({
      userInfo: state => mine.state.userInfo
    })
  },
  components: {
    'v-bar': vanBar
  },
  created () {
    this.$store.dispatch(GETUSERINFO, { type: 1 })
  },
  methods: {
    // 退出登录
    logOut () {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        Cookies.set('gps_xtoken_teacher', '')
        localStorage.setItem('phone', '')
        sessionStorage.setItem('nowEquiment', '')
        config.headers.xtoken = '';
        this.$router.push('/')
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;
  background-color: #eff0f5;
}
.user-info {
  position: relative;
  padding: 18px 0px 38px 25px;
  background: #fff;
  > div {
    width: 65%;
    display: inline-block;
  }
  img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background: #f3f4f7;
  }
  .user-name {
    color: #232b38;
    font-size: 30px;
    position: relative;
    top: 10px;
    left: 8px;
    max-width: 99%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .user-role {
    color: #95979b;
    font-size: 15px;
    position: relative;
    top: 19px;
    left: 8px;
  }
  // 查看详情图标
  .enter {
    width: 9px;
    height: 15px;
    position: absolute;
    top: 43px;
    right: 19px;
    background: url("../../assets/imgs/mine/icons.png") no-repeat -75px -37px;
    background-size: 116px 91px;
  }
}
.cells-group {
  margin: 10px 0 0 0;
}
.common-button {
  margin: 75px 13px 0 13px;
}
.tech-tip {
  color: #878d97;
  font-size: 11px;
  text-align: center;
  padding: 30px 0 60px 0;
}
/* 图标 */
.cell-icon {
  width: 24px;
  height: 24px;
  margin: 0 10px 0 0;
  background: url("../../assets/imgs/mine/mine_sprite.png") no-repeat;
  background-size: 86px 54px;
}
.shebei {
  background-position: 0px -30px;
}
.weilan {
  background-position: -31px -31px;
}
.wenti {
  background-position: -64px 0px;
}
.anquan {
  background-position: 0px 0px;
}
.banben {
  background-position: -32px 0px;
}
.van-cell {
  padding: 17px 15px 20px 15px;
}
/* 箭头颜色  */
.a.van-cell--clickable .van-icon.van-cell__right-icon {
  color: #d2d4d7;
}
.a.van-cell:not(:last-child)::after {
  border-bottom: 1px solid #babdc2;
  right: 0.5rem;
  opacity: 0.2;
}
// 退出加载
.login-out.in-loadimg {
  opacity: 0.7;
}
.common-button.in-loadimg {
  padding: 14px 0;
}
.load-loading {
  color: #2d3e4b;
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  top: -2px;
}
</style>
