<template>
  <div>
    <router-view />
    <!-- v-model: currentIndex 绑定更改 -->
    <van-tabbar
      v-model="currentIndex"
      fixed
      border
      active-color="#232B38"
      route
      class="custom-tabbar"
      style="z-index: 999;"
    >
      <van-tabbar-item replace to="/">
        <span>位置</span>
        <img slot="icon" :src="active == 0 ? icon.weizhictive : icon.weizhinormal" />
      </van-tabbar-item>
      <van-tabbar-item replace to="/guiji">
        <span>轨迹</span>
        <img slot="icon" :src="active == 1? icon.guijiactive : icon.guijinormal" />
      </van-tabbar-item>
      <van-tabbar-item replace to="/find">
        <span>发现</span>
        <img slot="icon" :src="active == 2 ? icon.faxianactive : icon.faxiannomal" />
      </van-tabbar-item>
      <van-tabbar-item replace to="/mine/index">
        <span>我的</span>
        <img slot="icon" :src="active == 3? icon.wodeactive : icon.wodenormal" />
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  name: 'vanBar',
  data: function () {
    return {
      currentIndex: this.active,
      icon: {
        weizhinormal: require('../../assets/imgs/tabbar/icon_weizhi2@2x.png'),
        weizhictive: require('../../assets/imgs/tabbar/icon_weizhi1@2x.png'),
        faxiannomal: require('../../assets/imgs/tabbar/icon_faxian2@2x.png'),
        faxianactive: require('../../assets/imgs/tabbar/icon_faxian1@2x.png'),
        guijinormal: require('../../assets/imgs/tabbar/icon_guiji2@2x.png'),
        guijiactive: require('../../assets/imgs/tabbar/icon_guiji1@2x.png'),
        wodenormal: require('../../assets/imgs/tabbar/icon_wode2@2x.png'),
        wodeactive: require('../../assets/imgs/tabbar/icon_wode1@2x.png')
      }
    }
  },
  props: ['active']
}
</script>
<style lang="less" scoped>
.van-tabbar.custom-tabbar {
  z-index: 999;
  box-shadow: 0px -1px 10px 0px rgba(51, 51, 51, 0.06);
}
</style>
