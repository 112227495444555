<template>
  <div class="wrap">
    <van-nav-bar title="编辑资料" left-text right-text left-arrow :border="false" @click-left="goBack" />
    <!-- 资料 S -->
    <div class="user-info">
      <div>
        <div>
          <span>头像</span>
        </div>
        <single-upload v-model="equipInfo.equipmentPic" :iStyle="photoStyle"></single-upload>
      </div>
      <div>
        <div>学校</div>
        <div>{{equipInfo.schoolDeptName}}</div>
      </div>
      <div>
        <div>年级</div>
        <div>
          {{equipInfo.gradeDeptName}}
          <span @click="selGrade" class="enter"></span>
        </div>
      </div>
      <div>
        <div>班级</div>
        <div>
          {{equipInfo.classDeptName}}
          <span @click="selClass" class="enter"></span>
        </div>
      </div>
      <div>
        <div>IMEI</div>
        <div>{{equipInfo.imei}}</div>
      </div>
      <div>
        <div>名称</div>
        <div>
          <input
            type="text"
            placeholder="请输入名字"
            v-model="equipInfo.equipmentName"
            style="text-align:right;"
          />
        </div>
      </div>
    </div>
    <!-- 资料 E -->
    <div class="common-button" @click="save">保存</div>
    <!-- 年级选择 弹出层 S -->
    <van-popup v-model="gpopShow" position="bottom" :overlay="true" class="pop">
      <div class="pop-wrap">
        <div>
          <van-picker :columns="gradeList" ref="gradePicker" />
        </div>
        <div>
          <div @click="gchangeCancel">取消</div>
          <div style="color:#2C92F9" @click="gchangeComfirm">确认</div>
        </div>
      </div>
    </van-popup>
    <!-- 年级选择 弹出层 S -->

    <!-- 班级选择 弹出层 S -->
    <van-popup v-model="cpopShow" position="bottom" :overlay="true" class="pop">
      <div class="pop-wrap">
        <div>
          <van-picker :columns="classList" ref="classPicker" />
        </div>
        <div>
          <div @click="cchangeCancel">取消</div>
          <div style="color:#2C92F9" @click="cchangeComfirm">确认</div>
        </div>
      </div>
    </van-popup>
    <!-- 班级选择 弹出层 S -->
  </div>
</template>
<script>
import { Toast } from "vant";
import { mapState } from "vuex";
import mine from "@/store/mine/index";
import singleUpload from "@/components/Upload/singleUpload";
import {
  EQUIPMENTDETAIL,
  EQUIPMENTUPDATE,
  GRADELIST,
  CLASSLIST
} from "@/store/mine/types";
export default {
  data() {
    return {
      photoStyle: ` width: 40px;height: 40px;border-radius: 50%;overflow: hidden;`,
      gpopShow: false,
      cpopShow: false,
      id: "",
      gId: "",
      gName: "",
      cId: "",
      cName: "",
      gIndex: "",
      cIndex: ""
    };
  },
  components: {
    singleUpload
  },
  computed: {
    ...mapState({
      equipInfo: state => mine.state.equipInfo,
      gradeList: state => mine.state.gradeList,
      classList: state => mine.state.classList
    })
  },
  created() {
    this.id = this.$route.params.id;
    this.$store.dispatch(EQUIPMENTDETAIL, { id: this.id });
    this.$store.commit("GETGRADEANDCLASS", true);
  },
  methods: {
    // 返回上一页
    goBack() {
      this.$router.go(-1);
    },
    // gChange(picker, value, index) {
    //   this.gId = value.id;
    //   this.gName = value.fullname;
    // },
    // cChange(picker, value, index) {
    //   this.cId = value.id;
    //   this.cName = value.fullname;
    // },
    gchangeCancel() {
      this.gpopShow = false;
      setTimeout(() => {
        // console.log("123", this.$refs.gradePicker.getValues()); // 原先
        this.$refs.gradePicker.setIndexes([String(this.gIndex)])
        console.log("cancel-after", this.$refs.gradePicker.getIndexes()); // 原先
      }, 800);
    },
    cchangeCancel() {
      this.cpopShow = false;
    },
    gchangeComfirm() {
      console.log("grade", this.$refs.gradePicker.getValues()[0]);
      // if (this.gName) {
      //   this.sure();
      // } else {
      //   // 点击确认没有选择 默认选第一个
      //   if (this.gradeList.length > 0) {
      //     this.gId = this.gradeList[0].id;
      //     this.gName = this.gradeList[0].fullname;
      //     this.sure();
      //   }
      // }
      this.gId = this.$refs.gradePicker.getValues()[0].id
      this.gName = this.$refs.gradePicker.getValues()[0].fullname
      
      this.sure();
      this.gpopShow = false;
      console.log("gid", this.gId,"gname", this.gName);
    },
    sure() {
      this.equipInfo.gradeDeptId = this.gId;
      this.equipInfo.gradeDeptName = this.gName;
      return
      this.$store.dispatch(CLASSLIST, { gradeId: this.gId });
      // 重新选择年级后，同样重置班级
      this.equipInfo.classDeptName = "";
    },
    cchangeComfirm() {
      this.cId=this.$refs.classPicker.getValues()[0].id;
      this.cName=this.$refs.classPicker.getValues()[0].fullname;
      console.log(this.cId,this.cName)
      if (this.cName) {
      } else {
        // 点击确认没有选择 默认选第一个
        if (this.classList.length > 0) {
          this.cId = this.classList[0].id;
          this.cName = this.classList[0].fullname;
        }
      }
      this.equipInfo.classDeptId = this.cId;
      this.equipInfo.classDeptName = this.cName;
      this.cpopShow = false;
    },
    // 选择年级
    selGrade() {
      if (this.gradeList.length <= 0) {
        Toast("暂无年级可选");
      }
      this.gpopShow = true;
      setTimeout(() => {
        this.gIndex = this.$refs.gradePicker.getIndexes()[0];
        // console.log("selGrade", this.$refs.gradePicker.getIndexes()); // 原先
      }, 800);
    },
    // 选择班级
    selClass() {
      if (this.classList.length <= 0) {
        Toast("暂无班级可选");
      }
      this.cpopShow = true;
    },
    // 更新设备信息 (只有设备名称/设备头像可修改)
    save() {
      if (this.equipInfo.equipmentPic) {
        if (this.equipInfo.equipmentName) {
          this.$store.dispatch(EQUIPMENTUPDATE, {
            id: this.id,
            equipmentName: this.equipInfo.equipmentName,
            equipmentPic: this.equipInfo.equipmentPic,
            deptId: this.equipInfo.classDeptId
          });
        } else {
          Toast("设备名称不能为空");
        }
      } else {
        Toast("请先上传设备头像");
      }
    }
  }
};
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;
  background-color: #eff0f5;
  position: fixed;
  .user-info {
    background: #fff;
    margin: 10px 13px 0 13px;
    border-radius: 10px;
    .enter {
      width: 9px;
      height: 15px;
      position: absolute;
      top: -1px;
      right: 0;
      background: url("../../../assets/imgs/mine/icons.png") no-repeat -75px -37px;
      background-size: 116px 91px;
    }
    > div {
      width: 90%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      font-size: 15px;
      color: #232b38;
      border-bottom: 1px solid #e6e7ed;
      padding: 20px 0;
      vertical-align: middle;
    }
    // 头像
    & > div:nth-of-type(1) {
      padding: 8px 0;
      span {
        position: relative;
        display: inline-block;
        top: 17px;
      }
    }
    // 年级 班级
    & > div:nth-of-type(4),
    & > div:nth-of-type(3) {
      & > div:nth-of-type(2) {
        position: relative;
        padding-right: 20px;
      }
    }

    & > div:nth-last-of-type(1) {
      border-bottom: none;
    }
  }
  .pop-wrap {
    overflow: hidden;
    & > div {
      color: #000;
      font-size: 15px;
      font-weight: 400;
      text-align: center;
    }
    // 年级、班级列表
    & > div:nth-of-type(1) {
      padding: 29px 0 38px 0;
    }
    // 确认、取消
    & > div:nth-of-type(2) {
      display: flex;
      margin: 0 13px;
      border-top: 1px solid #e6e7ed;
      padding: 18px 0;
      justify-content: space-around;
    }
  }
}
input::-webkit-input-placeholder {
  /* placeholder位置  */
  text-align: right;
}
.common-button {
  margin: 26px 13px 0 13px;
}
</style>
