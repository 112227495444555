<template>
  <div class="wrap">
    <!-- 暂无数据 S-->
    <div class="no-data" v-if="noQuestionList">
      <img src="../../assets/imgs/icon_kong.png" />
      <div>暂无数据</div>
    </div>
    <!-- 暂无数据 E-->

    <!-- 折叠面板 S-->
    <div class="question-wrap" v-else>
      <van-cell-group class="cells">
        <div v-for="(item, index) in list" :key="index" :name="index">
          <van-cell :title="item.dictCol" is-link @click="expandItem(item)" class="a" />
          <div class="line"></div>
          <!-- 更多 弹出层 S -->
          <van-popup v-model="item.visible" position="bottom" :overlay="true" class="pop">
            <div class="pop-wrap">
              <div class="title">{{item.dictCol}}</div>
              <div v-html="item.dictLaber" class="content"></div>
            </div>
          </van-popup>
          <!-- 更多弹出层 S -->
        </div>
      </van-cell-group>
    </div>
    <!-- 折叠面板 E-->
  </div>
</template>
<script>
import { mapState } from "vuex";
import mine from "@/store/mine/index";
import { DICTIONARY } from "@/store/mine/types";
export default {
  data() {
    return {
      activeNames: ["0"],
      popShow: false
    };
  },
  computed: {
    ...mapState({
      list: state => mine.state.questionList,
      noQuestionList: state => mine.state.noQuestionList
    })
  },
  created() {
    this.$store.dispatch(DICTIONARY);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    expandItem: function(item) {
      item.visible = !item.visible;
    }
  }
};
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;
  background-color: #eff0f5;
  .question-wrap {
    margin: 10px 13px 0 13px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 1px 10px 0px rgba(51, 51, 51, 0.06);
  }
  .pop {
    .pop-wrap {
      padding: 0 13px;
      // 标题
      .title {
        border-bottom: 1px solid #e6e7ed;
        padding: 20px 0 20px 10px;
        color: #232b38;
        font-size: 15px;
        position: relative;
      }
      .title:before {
        content: "";
        position: absolute;
        width: 5px;
        height: 15px;
        background: #2c92f9;
        top: 20px;
        left: 0;
        border-radius: 3px;
      }
      // 内容
      .content {
        color: #232b38;
        font-size: 14px;
        padding: 22px 0 32px 0;
      }
    }
  }
}
.cells .a.van-cell:not(:last-child)::after {
  border-bottom: 1px solid #babdc2;
  right: 0.5rem;
  opacity: 0.3;
  bottom: 1px;
}
</style>
