<template>
  <div class="container">
    <van-nav-bar>
      <div slot="title"
           class="rowju"
           @click="alertsetting">
        <span class="titletext">{{navtitle}}</span>
        <img class="titleimg"
             src="../../assets/imgs/Position/xiala.png" />
      </div>
      <span class="navrighttitle"
            slot="right"
            @click="alertingore">忽略提醒</span>
    </van-nav-bar>
    <!--弹出修改预警类型-->
    <van-popup v-model="settingpopup"
               class="transback">
      <div class="radiusbox">
        <div class="poplist"
             v-for="(item,index) in settingarr"
             :key=" 'setting' + index "
             @click="handletype(item,index)">
          {{item.dictLaber}}
        </div>
      </div>
    </van-popup>
    <!--处理-->
    <van-popup v-model="handlepopup" :lockScroll="false"	
               class="transback">
      <div class="radiusbox">
        <div class="poplist"
             v-for="(item,index) in handlearr"
             :key=" 'handle' + index "
             @click="handlesetting(item,index)">
          {{item}}
        </div>
      </div>
    </van-popup>
    <!--忽略提醒-->
    <van-popup v-model="ignorepopup"
               class="transback">
      <div class="radiusbox">
        <div class="poplist"
             v-for="(item,index) in ignorearr"
             :key=" 'ignore' + index "
             @click="handleignore(item,index)">
          {{item}}
        </div>
      </div>
    </van-popup>
    <!-- 暂无数据 S -->
      <div v-if="allList.length < 1" class="no-data">
        <img src="../../assets/imgs/icon_kong.png" />
        <div>暂无数据</div>
      </div>
    <!--设备列表-->
    <div v-if="allList.length">
      <van-list :finished="finished"
                finished-text="">
        <div class="list-history"
             style="justify-content:space-between;">
          <div v-for="(item,index) in allList"
               :key=" 'result' + index "
               class='cellbox'>
            <img :src="item.equipmentPic"
                 class="cellimg" />
            <div>
              <p>设备名称：{{item.equipmentName}}</p>
              <p>类型：{{item.warningTypeName}}</p>
              <p>围栏报警：{{item.createTimeStr}}</p>
            </div>
            <div class="rightredbtn"
                 @click="showhandlepopup(item)">处理</div>
          </div>
        </div>
      </van-list>
    </div>
    <!--加载更多 -->
    <div class="load" v-if="allList.length">
      <van-loading type="spinner"
                   class="load-loading"
                   v-if="isLoad" />
      <span class="all-loaded"
            :class="allLoaded? '': 'border'"
            v-else-if="allLoaded">加载完成</span>
      <span class="all-loaded"
      :class="allLoaded? '': 'border'"
      @click="onLoad(1)"
      v-else>{{allLoaded? '加载完成': '加载更多'}}</span>
    </div>
    <div class="space"></div>
    <v-bar :active="active"></v-bar>
  </div>
</template>
<script>
import cardList from '@/view/componse/cardList'
import vanBar from '@/view/componse/vartarbbar'
import http from '@/utils/http'
import { websoketUrl } from '@/utils/global'
import { Toast, Dialog } from 'vant'
import Cookies from 'js-cookie'
export default {
  data: function () {
    return {
      active: 2,
      warningType: '',
      nomore: false,
      finished: false,
      offset: 50,
      page: 1,
      isLoad: false,
      allLoaded: this.finished,
      firstcheck: true,
      ignorepopup: false,
      handlepopup: false,
      handlearr: ['查看实时定位', '一个小时内不在提醒', '今天不再提醒', '忽略本次提醒', '暂不处理'],
      ignorearr: ['忽略全部提醒', '忽略围栏报警', '忽略低电报警', '忽略返回围栏提醒'],
      settingarr: ['全部', '围栏报警', '低电报警', '返回围栏'],
      settingpopup: false,
      loading: false,
      show: false,
      allList: [], // 列表所有内容
      handleitem: {},
      ws: {},
      navtitle:'设备报警'
    }
  },
  mounted () {
    this.page = 1
    this.getsettypearr()
    this.websoketwom()
    
  },
  methods: {
    websoketwom () {
      this.ws = null

      var that = this
      if ('WebSocket' in window) {
        //  ws://127.0.0.1:8704/websocketPrint/admin
        let user = JSON.parse(Cookies.get('user'))

        that.ws = new WebSocket(websoketUrl + user.id)
        var ws = that.ws
        ws.onopen = function () {
          // Web Socket 已连接上，使用 send() 方法发送数据
          ws.send('发送数据')
          // console.log(heartCheck)
          // heartCheck.reset()
          // heartCheck.start()   // 成功建立连接后，重置心跳检测
        }
        // 获取消息成功
        ws.onmessage = function (evt) {
          // heartCheck.reset()
          // heartCheck.start()   // 如果获取到消息，说明连接是正常的，重置心跳检测
          var received_msg = evt.data
          if (received_msg == 'refresh') {
            this.page = 1
            that.onLoad(0)
          }
          // that.voiceText(received_msg)
        }
        ws.onclose = function () {
          ws.close() // 关闭TCP连接
          // 关闭 websocket
        }
      } else {
        // 浏览器不支持 WebSocket
        alert('您的浏览器不支持 WebSocket!')
      }
    },
    getsettypearr(){
      http.post('/p-gps-dropdownBox-query', {}).then(res => {
        if (res.code == 'ok') {
          let typearr = res.data && res.data.warningType || []
          this.settingarr = typearr
          console.log('router',this.$route.query)
          if(this.$route.query.warntype){
            let index = this.$route.query.warntype
            this.warningType = index === 0 ? null : index
            this.navtitle = index === 0 ? '设备报警' : this.settingarr[index].dictLaber
          }
          this.onLoad(0)
        }
      })
    },
    onLoad (d) {
      this.nomore = false
      http.post('/p-gps-findMessage-app-query', {
        warningType: this.warningType,
        page: this.page,
        pageSize: 10
      }).then(res => {
        this.loading = false
        if (res.code == 'ok') {
          this.page = this.page + 1
          var rlist = res.data.dataList || []
          this.allLoaded = res.data.page >= res.data.totalPage
          this.allList = res.data.page === 1 ? rlist : this.allList.concat(rlist)
          if (this.allList.length == res.data.total || res.data.dataList.length <= 0) {
            this.nomore = true
          }
          if (this.allList.length == res.data.total) {
            this.finished = true
          }
        }
      })
    },
    handleignore (item, index) {
      let that = this
      Dialog.confirm({
        title: '危险操作！',
        message: '请在确保设备使用对象均无异常的情况下进行此操作，否则将影响预警的准确性'
      }).then(() => {
        http.post('/p-gps-findMessage-updateIgnore', {
          warningType: index === 0 ? '' : index
        }).then(res => {
          that.ignorepopup = false
          if (res.code == 'ok') {
            that.page = 1
            that.onLoad(0)
          } else {
            Toast.fail(res.message || res.optionMsg)
          }
        })
      }).catch(() => {
        // on cancel
      })
    },
    handletype (item, index) {
      this.warningType = index === 0 ? null : index
      this.navtitle = index === 0 ? '设备报警' : item.dictLaber
      this.$router.push({ path:'/find', query: {warntype:this.warningType} })
      this.page = 1
      this.settingpopup = false
      this.allList = []
      this.onLoad(0)
    },
    handlesetting (item, index) {
      if (index === 0) {
        this.$router.push({ path: `/mine/deviceposition/${this.handleitem.equipmentId}`, query: { imei: this.handleitem.IMEI, status: this.handleitem.equipmentStatusName,locationTime:this.handleitem.locationTime } })
      } else if (index === (this.handlearr.length - 1)) {
        this.handlepopup = false
      } else {
        http.post('/p-gps-findMessage-update', {
          handleResult: index + 1,
          id: this.handleitem.id
        }).then(res => {
          this.handlepopup = false
          if (res.code == 'ok') {
            this.page = 1
            this.onLoad(0)
          } else {
            Toast.fail(res.message || res.optionMsg)
          }
        })
      }
    },
    alertingore () {
      this.ignorepopup = true
    },
    alertsetting () {
      this.settingpopup = true
    },
    showhandlepopup (item) {
      this.handleitem = item || {}
      if(item.warningTypeName == "返回围栏") {
        this.handlearr=['查看实时定位', '暂不处理']
      } else {
        this.handlearr=['查看实时定位', '一个小时内不在提醒', '今天不再提醒', '忽略本次提醒', '暂不处理']
      }
      this.handlepopup = true
    },
    onClosep () {
      if (this.ws) {
        this.ws.close()
      }
    }

  },
  // 监听窗口事件，当窗口关闭时，主动断开websocket连接，防止连接没断开就关闭窗口，server端报错
  beforeDestroy () {
    this.onClosep()
  },
  components: {
    'v-bar': vanBar,
    cardList
  }
}
</script>
 <style lang="stylus" scoped>
 .load
   bottom 0
   height 50px
   width 100%
   text-align center
   margin-top 30px
   .van-loading.load-loading
     margin 0 auto
   .all-loaded
     display inline-block
     font-size 15px
     padding 5px 10px
 *
   box-sizing border-box
 .container, .navrighttitle
   color #2C92F9
   font-size 16px
 .rowju
   display flex
   flex-direction row
   align-items center
   justify-content center
 .titletext
   color #232B38
   font-size 18px
   margin-right 6px
 .titleimg
   width 12px
   height 8px
 .radiusbox
   border-radius 10px
   background #fff
   width 7rem
   padding-top 10px
   box-sizing border-box
   padding-bottom 10px
   text-align center
 .radiusbox .poplist
   color #232B38
   font-size 15px
   line-height 40px
   text-align center
 .radiusbox .poplist:active
   color #2C92F9
 .cellbox
   display flex
   border-radius 5px
   flex-direction row
   align-items center
   justify-content space-between
   background #fff
   width 9.3rem
   margin 10px auto
   box-sizing border-box
   padding 0.3rem
   color #232B38
   font-size 14px
   line-height 26px
 .cellimg
   width 1.7rem
   height 2.1rem
   border-radius 3px
 .rightredbtn
   width 1.2rem
   height 1.2rem
   background #FF432F
   box-shadow 0 1px 10px rgba(200, 4, 4, 0.3)
   border-radius 16px
   color #fff
   line-height 1.2rem
   text-align center
 .space
   height 50px
 .nomore
   color #aaa
   text-align center
   padding 10px 0px
</style>
