
<template>
  <div>
    <van-loading size="24px"
                 v-if='loading'
                 class="loading"
                 vertical>图片上传中...</van-loading>
    <van-uploader v-else
                  :before-read="beforeRead"
                  :after-read="onRead"
                  :auto-upload="false"
                  :accept="'image/*'"
                  class="uploadclassi"
                  :style="iStyle">
      <i v-if='!url'
         :size='30'
         class="van-icon van-icon-plus van-uploader__upload-icon"></i>
      <img class="head-img"
           fit='fill'
           v-else
           :src='url'
           ref="refImg">
    </van-uploader>
  </div>
</template>
<script>
import { getDateLocation } from '@/utils/datetime'
import compress from './imgSet'
export default {
  data () {
    return {
      url: '',
      imageUrl: '',
      uploadFile: {},
      loading: false
    }
  },
  props: {
    value: {
      value: String,
      default: ''
    },
    iStyle: {
      value: String,
      default: ''
    }
  },
  mounted () {
    // this.$refs.refImg.src = this.imageUrl
    // 设置默认头像
    // this.imageUrl ? (this.$refs.refImg.src = this.imageUrl) : (this.$refs.refImg.src = 'https://dummyimage.com/65x65&text=icon')
  },
  computed: {
  },
  methods: {
    emitInput (val) {
      this.$emit('input', val)
      this.$emit('checkadd')
    },
    beforeRead (file) {
      // const isLt2M = file.size / 1024 / 1024 < 2
      // if (!isLt2M) {
      //   this.$toast({ message: '上传设备头像图片大小不能超过 2MB!', type: 'error' })
      //   return false
      // } else {
      //   return true
      // }
      return true
    },
    onRead (file) {
      console.log(2222, file)
      this.uploadFile = file
      var self = this
      let img = new Image()
      img.src = file.content
      var big = file.file.size / 1024
      this.$emit('afterr', true)
      if (big <= 2048) {
        this.url = file.content
      } else {
        img.onload = function () {
          let data = compress(img, 6)
          self.url = data
        }
        // this.url = img.src
      }
    },
    // 点击上传图片 到oss
    afterRead () {
      var file = this.uploadFile
      var that = this
      let OSS = require('ali-oss')
      // base64格式的图片文件
      let urlData = this.url
      const base64 = urlData.split(',').pop()
      const fileType = urlData.split(';').shift().split(':').pop()
      function toBlob (urlData, fileType) {
        let bytes = window.atob(urlData)
        let n = bytes.length
        let u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bytes.charCodeAt(n)
        }
        return new Blob([u8arr], { type: fileType })
      }
      // base64转blob
      const blob = toBlob(base64, fileType)
      // blob转arrayBuffer
      const reader = new FileReader()
      reader.readAsArrayBuffer(blob)
      reader.onload = (event) => {
        const client = new OSS({
          region: 'oss-cn-shenzhen',
          accessKeyId: 'LTAIdNHaMjUjOmmX',
          accessKeySecret: 'wtr6YCZufyrDgc52I30kRhoHTVl5LQ',
          bucket: 'wwmimgs'
        })

        // 文件名:文件夹名字 + 今日日期 + 文件名字
        const objectKey = `gps/${getDateLocation()}/${file.file.name}`
        // arrayBuffer转Buffer
        const buffer = new OSS.Buffer(event.target.result)
        // 上传
        client.put(objectKey, buffer).then((result) => {
          console.log('上传成功：', result.url)
          this.url = result.url
          this.imageUrl = result.url
          //  this.$refs.refImg.src = result.url
          this.emitInput(this.imageUrl)
        })
          .catch((err) => {
            console.log('上传失败', err)
          })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.van-uploader {
  img {
    width: 100%;
    height: 100%;
  }
}
.uploadclassi {
  width: 100%;
  height: 100%;
  border: 2px solid #eef2f5;
  position: relative;
}
.van-icon-plus {
  width: 100px;
  height: 100px;
  font-size: 35px;
  vertical-align: middle;
  position: absolute !important;
  top: 33px;
  left: 33px;
}
.van-icon-plus:before {
  width: 30px;
  height: 30px;
  font-size: 30px;
}
.loading {
  width: 50px !important;
  height: 50px !important;
  padding-left: 10px;
  padding-top: 10px;
}
</style>

<style>
.uploadclassi .van-uploader__input{
 position:inherit !important;
}
</style>
