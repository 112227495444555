import MineIndex from '@/view/mine/Index'
import EditInfo from '@/view/mine/EditInfo'
import DeviceManage from '@/view/mine/DeviceManage'
import Question from '@/view/mine/Question'
import QuestionForApp from '@/view/mine/QuestionForApp'
import AccSecurity from '@/view/mine/AccSecurity'
import Fence from '@/view/mine/Fence'
import DeviceEdit from '@/view/mine/child/DeviceEdit'
import DeviceSet from '@/view/mine/child/DeviceSet'
import SleepTime from '@/view/mine/child/SleepTime'
import DeviceLocus from '@/view/mine/child/DeviceLocus'
import SelectDate from '@/view/mine/child/SelectDate'
import DevicePosition from '@/view/mine/child/DevicePosition'
import DevicePanorama from '@/view/mine/child/DevicePanorama'

export default [
  {
    path: '/mine/index', // 我的
    name: 'MineIndex',
    component: MineIndex
  },
  {
    path: '/mine/editinfo', // 编辑我的资料
    name: 'EditInfo',
    component: EditInfo
  },
  {
    path: '/mine/devicemanage', // 设备管理
    name: 'DeviceManage',
    component: DeviceManage
  },
  {
    path: '/mine/devicepanorama/:site', // 设备位置实时街景
    name: 'DevicePanorama',
    component: DevicePanorama
  },
  {
    path: '/mine/deviceedit/:id', // 设备编辑
    name: 'DeviceEdit',
    component: DeviceEdit
  },
  {
    path: '/mine/deviceposition/:id', // 设备定位
    name: 'DevicePosition',
    component: DevicePosition
  },
  {
    path: '/mine/deviceset/:id', // 设备设置
    name: 'DeviceSet',
    component: DeviceSet
  },
  {
    path: '/mine/devicelocus/:imei', // 设备轨迹
    name: 'DeviceLocus',
    component: DeviceLocus
  },
  {
    path: '/mine/sleeptime', // 休眠时间
    name: 'SleepTime',
    component: SleepTime
  },
  {
    path: '/mine/question', // 常见问题
    name: 'Question',
    component: Question
  },
  {
    path: '/mine/appquestion', // app端常见问题
    name: 'QuestionForApp',
    component: QuestionForApp
  },
  {
    path: '/mine/device/selectdate', //  选择日期
    name: 'SelectDate',
    component: SelectDate
  },
  {
    path: '/mine/security', // 账号安全
    name: 'AccSecurity',
    component: AccSecurity
  },
  {
    path: '/mine/fence', // 安全围栏
    name: 'Fence',
    component: Fence
  }
]
