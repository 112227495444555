<template>
  <div class="wrap">
    <van-nav-bar title="安全围栏"
                 left-text
                 :right-text=" isset === 1 ? '完成设置' : isset === 2 ? '重新设置' : '设置围栏' "
                 left-arrow
                 :border="false"
                 @click-left="goBack"
                 @click-right="setFence" />
    <div data-v-c7be7294=""
         class="search-wrap mapsearch">
      <div data-v-c7be7294=""
           class="van-search__content">
        <i class="van-icon van-icon-search serach-icon">
          <!----></i>
        <div class="van-cell__value van-cell__value--alone">
          <div class="van-field__body">
            <input type="search"
                   placeholder="搜索地址"
                   id="suggestId"
                   class="van-field__control" />
            <div id="searchResultPanel"
                 class="searchbox"
                 style="border:1px solid #C0C0C0;width:150px;height:auto; display:none;"></div>
          </div>
        </div>
      </div>

    </div>

    <!-- style="border: 1px solid red;" -->
    <!-- <div>
        <input type="search" id="address-detail" name="address-detail" placeholder="搜索地址">
      </div> -->
    <!-- <span class="sl-switch" style="border: 1px solid red;">
        <span class="icon icon-ctrl pos"></span>
      </span>-->
    <div id="mapPanel">
      <div class="map-loading-center ">
        <van-loading type="spinner"
                     color="#1989fa"
                     style="margin: 0 auto" />
      </div>
    </div>
    <div class="bottomtext"
         @click="showtimepopup">
      预警时间设置
      <img src="../../assets/imgs/mine/icon_shangla.png" />
    </div>
    <van-popup v-model="timepickshow"
               position="bottom"
               :overlay="true"
               class="transback">
      <div class="topheader"
           @click=" timepickshow = false ">
        <img src="../../assets/imgs/mine/icon_hebing.png" />
      </div>
      <div class="popcontainer">
        <div class="headertitle">
          预警时间设置
        </div>
        <van-radio-group v-model="dataform.openType"
                         class="popupradio"
                         @change="typechange">
          <van-radio checked-color="#2C92F9"
                     name="1"
                     :class=" dataform.openType == 1 ? 'van-radio-checked' : '' ">
            <img slot="icon"
                 slot-scope="props"
                 class="cellicon"
                 :src="props.checked ? icon.active : icon.normal">
            <span class="radiolabel">开启方式1：跟随法定节假日调休日</span>
          </van-radio>
          <van-radio name="2"
                     :class=" dataform.openType == 2 ? 'van-radio-checked' : '' ">
            <img slot="icon"
                 slot-scope="props"
                 class="cellicon"
                 :src="props.checked ? icon.active : icon.normal">
            <span class="radiolabel">开启方式2： 始终开启</span>
          </van-radio>
          <van-radio name="3"
                     :class=" dataform.openType == 3 ? 'van-radio-checked' : '' ">
            <img slot="icon"
                 slot-scope="props"
                 class="cellicon"
                 :src="props.checked ? icon.active : icon.normal">
            <span class="radiolabel">开启方式3：始终关闭</span>
          </van-radio>
        </van-radio-group>
        <div class='line' />
        <div class="headcell spacebetween">
          <div class="headcell"
               @click="showtimespicker">
            <span class="celltitle">产生预警时连续提醒次数：{{dataform.remindNumber}}次</span>
            <img class="cellicon"
                 src="../../assets/imgs/mine/icon_naozhogn.png" />
          </div>
          <span class="cellinfo"
                @click="showtimesinfo">查看说明</span>
        </div>
        <div class="headcell spacebetween">
          <div class="headcell"
               @click="showovertimepicker">
            <span class="celltitle">预警间隔时间：{{dataform.intervalDurationstr}}</span>
            <img class="cellicon"
                 src="../../assets/imgs/mine/icon_naozhogn.png" />
          </div>
          <span class="cellinfo"
                @click="showtimeinfo">查看说明</span>
        </div>
        <div class='line' />
        <div class="headcell spacebetween"
             v-for="(item,index) in enclosureTimearr"
             :key=" 'time' + index">
          <div class="headcell"
               @click="settimearrindex(index)">
            <span class="celltitle">时间{{item.startTime}} 至 {{item.endTime}}</span>
            <img class="cellicon"
                 src="../../assets/imgs/mine/icon_shezhi.png" />
          </div>
          <van-switch v-model="item.check"
                      active-color="#2C92F9"
                      size="22px"
                      inactive-color="#BABDC2"
                      @change="submit" />
        </div>
      </div>
    </van-popup>
    <!-- 产生预警时连续提醒次数说明示例 -->
    <van-popup v-model="timesshow"
               :overlay="true"
               class="transback">
      <div class="infobox">
        <div class="headcell spacebetween">
          <span class="celltitle"></span>
          <van-icon name="cross"
                    size="24px"
                    @click="timesshow = false" />
        </div>
        <p class="infotitle">产生预警时连续提醒次数说明示例</p>
        <span class="infodesc">当确定目标对象离开围栏范围并且没在指定时间内返回围栏内时，最多发送给用户的预警信息的次数。</span>
      </div>
    </van-popup>
    <!-- 预警间隔时间说明示例 -->
    <van-popup v-model="overtimeshow"
               :overlay="true"
               class="transback">
      <div class="infobox">
        <div class="headcell spacebetween">
          <span class="celltitle"></span>
          <van-icon name="cross"
                    size="24px"
                    @click="overtimeshow = false" />
        </div>
        <p class="infotitle">预警间隔时间说明示例</p>
        <span class="infodesc">当确定目标对象离开围栏范围并且没在指定时间内返回围栏内时，连续发送给用户指定信息的间隔时间。</span>
      </div>
    </van-popup>
    <!-- 产生预警时连续提醒次数设置 -->
    <van-popup v-model="timespickershow"
               position="bottom"
               :overlay="true"
               class="transback">
      <div class="pickerbox">
        <van-picker :columns="columns"
                    @change="ontimesChange"
                    :default-index="remindNumberindex" />
        <div class="bottombox headcell">
          <div class="buttombutton"
               @click="timespickershow = false">
            取消
          </div>
          <div class="buttombutton surebutton"
               @click="suredatatimes">
            确定
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 预警间隔时间设置 -->
    <van-popup v-model="timepickershow"
               position="bottom"
               :overlay="true"
               class="transback">
      <div class="pickerbox">
        <van-picker :columns="blanktimecolumns"
                    @change="timeonChange" />
        <div class="bottombox headcell">
          <div class="buttombutton"
               @click="timepickershow = false">
            取消
          </div>
          <div class="buttombutton surebutton"
               @click="sureformtime">
            确定
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 预警时间设置 -->
    <van-popup v-model="timearrpickershow"
               position="bottom"
               :overlay="true"
               class="transback">
      <div class="pickerbox">
        <div class="headcell">
          <van-datetime-picker v-model="startTime"
                               :show-toolbar="false"
                               @change="setstarttime"
                               type="time"
                               :formatter="formatter"
                               style="width:50%;" />
          <span class="colmidtext">至</span>
          <van-datetime-picker v-model="endTime"
                               :show-toolbar="false"
                               type="time"
                               :formatter="formatter"
                               style="width:50%;" />
        </div>

        <div class="bottombox headcell">
          <div class="buttombutton"
               @click="timearrpickershow = false">
            取消
          </div>
          <div class="buttombutton surebutton"
               @click="suretimearr">
            确定
          </div>
        </div>
      </div>
    </van-popup>
    <!--  -->
    <div class="pos-icon"
         v-if="mapShow">
      <div class="obj"></div>
    </div>
  </div>
</template>
<script>
import { loadingMap } from '@/utils/load'
import { ENCOLSUREQUERY } from '@/store/mine/types'
import { Dialog, Toast } from 'vant'
import http from '@/utils/http'
import { formatWithSeperatorstr } from '@/utils/datetime'
import mine from '@/store/mine/index'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      enclosure: [],
      map: null, // 地图实例
      geoLocation: null,
      mapShow: false,
      curcity: '定位中',
      ac: null,
      poiShow: false,
      timesshow: false,
      timespickershow: false,
      timepickershow: false,
      currentTime: '',
      startTime: '',
      endTime: '',
      timearrpickershow: false,
      overtimeshow: false,
      value: '',
      isset: 0,
      enclosurePoint: [],
      pointarr: [],
      icon: {
        normal: require('../../assets/imgs/mine/icon_weixuan.png'),
        active: require('../../assets/imgs/mine/icon_xuanz.png')
      },
      amchecked: false,
      pmchecked: false,
      mmchecked: false,
      dataform: {
        enclosurePoint: '',
        openType: '1',
        remindNumber: 3,
        intervalDurationstr: '10分钟',
        intervalDuration: 10 * 60,
        enclosureTime: '',
        remarks: ''
      },
      enclosureTimeindex: null,
      enclosureTimearr: [
        {
          'startTime': '8:00',
          'endTime': '12:00',
          'status': '1',
          'check': true
        },
        {
          'startTime': '12:00',
          'endTime': '18:00',
          'status': '1',
          'check': true
        },
        {
          'startTime': '18:00',
          'endTime': '20:00',
          'status': '1',
          'check': true
        }
      ],
      intervalDurationstr: '10分钟',
      intervalDuration: 10 * 60,
      remindNumber: 3,
      polygon: null,
      timepickshow: false,
      columns: ['1次', '2次', '3次', '4次', '5次'],
      blanktimecolumns: [
      ],
      remindNumberindex: 2
    }
  },
  created () {
    this.start()
  },
  mounted () {
    // console.log('ss', this.enclosure)
  },
  methods: {
    start () {
      http.post('/p-gps-enclosureByUser-query', {}).then(data => {
        let timearr = []
        if (data.data.id) {
          this.enclosure = data.data || []
          this.dataform.openType = this.enclosure.openType && this.enclosure.openType.toString()
          this.dataform.remindNumber = this.enclosure.remindNumber
          this.remindNumberindex = parseInt(this.dataform.remindNumber) - 1
          this.dataform.id = this.enclosure.id
          this.enclosureTimearr = this.enclosure.enclosureTime || []
          this.enclosureTimearr.map((item, index) => {
            item.check = item.status === 1
          })
          this.dataform.intervalDuration = this.enclosure.intervalDuration
          this.intervalDuration = this.enclosure.intervalDuration
          timearr = formatWithSeperatorstr(this.enclosure.intervalDuration)
          this.dataform.intervalDurationstr = timearr[0] + '小时' + timearr[1] + '分钟' + timearr[2] + '秒'
          this.intervalDurationstr = [timearr[0] + '小时', timearr[1] + '分钟', timearr[2] + '秒'].toString()
        }
        let minutesarr = []
        let secondsarr = []
        let hoursarr = []
        for (let i = 0; i < 60; i++) {
          minutesarr.push((i) + '分钟')
          secondsarr.push((i) + '秒')
        }
        for (let i = 0; i < 24; i++) {
          hoursarr.push((i) + '小时')
        }
        this.blanktimecolumns = [
          {
            values: hoursarr,
            defaultIndex: parseInt(timearr[0]) || 0
          },
          {
            values: minutesarr,
            defaultIndex: parseInt(timearr[1]) || 10
          },
          {
            values: secondsarr,
            defaultIndex: parseInt(timearr[2]) || 0
          }
        ]
        this.loadScript()
      })
    },
    // 打开预警时间设置总弹框
    showtimepopup () {
      this.timepickshow = true
    },
    // 显示次数说明
    showtimesinfo () {
      this.timesshow = true
    },
    // 显示间隔时间段说明
    showtimeinfo () {
      this.overtimeshow = true
    },
    // 显示次数设置选择器
    showtimespicker () {
      this.timespickershow = true
    },
    // 预警间隔时间设置
    showovertimepicker () {
      this.timepickershow = true
    },
    // 次数选择器变化
    ontimesChange (picker, value, index) {
      let times = ''
      times = value.replace('次', '')
      this.remindNumber = times
    },
    // 确定次数选择
    suredatatimes () {
      this.$set(this.dataform, 'remindNumber', this.remindNumber)
      this.timespickershow = false
      this.submit()
    },
    // 间隔时间选择器变化
    timeonChange (picker, values) {
      this.intervalDurationstr = values.toString()
      // picker.setColumnValues(1, citys[values[0]]);
    },
    // 间隔时间选择确认
    sureformtime () {
      let timearr = this.intervalDurationstr.split(',') || []
      let timestr = ''
      timearr.map((item, index) => {
        if (this.strtonum(item)) {
          timestr = timestr + item
        }
      })
      this.$set(this.dataform, 'intervalDurationstr', timestr)
      this.intervalDuration = this.strtonum(timearr[0]) * 60 * 60 + this.strtonum(timearr[1]) * 60 + this.strtonum(timearr[2])
      this.$set(this.dataform, 'intervalDuration', this.intervalDuration)
      this.timepickershow = false
      this.submit()
    },
    // 预警时间段点击
    settimearrindex (index) {
      this.enclosureTimeindex = index
      this.startTime = this.enclosureTimearr[index].startTime
      this.endTime = this.enclosureTimearr[index].endTime
      this.timearrpickershow = true
    },
    suretimearr () {
      this.$set(this.enclosureTimearr, this.enclosureTimeindex, Object.assign(this.enclosureTimearr[this.enclosureTimeindex], {
        startTime: this.startTime,
        endTime: this.endTime
      }))
      this.timearrpickershow = false
      this.submit()
    },
    // 提交
    submit () {
      this.dataform.enclosurePoint = JSON.stringify(this.enclosurePoint)
      this.enclosureTimearr.map((item, index) => {
        item.status = item.check ? 1 : 0
      })
      this.dataform.enclosureTime = JSON.stringify(this.enclosureTimearr)
      let api = this.enclosure.remindNumber ? '/p-gps-enclosure-update' : '/p-gps-enclosure-add'
      http.post(api, this.dataform).then(data => {
        if (data.code === 'ok') {
          if (this.isset === 1) {
            Dialog.confirm({
              title: '围栏设置成功',
              message: '开始设置预警时间段'
            }).then(() => {
              // on confirm
              this.isset = 2
              this.timepickshow = true
            }).catch(() => {
              this.isset = 2
              // on cancel
            })
          }
          this.start()
        } else {
          Dialog.confirm({
            title: data.message
          }).then(() => { }).catch(() => {
            // on cancel
          })
        }
      })
    },
    setstarttime (picker) {
      // console.log('value', this.startTime)
    },
    setendtime () {
      // console.log('value', this.endTime)
    },
    typechange (name) {
      // console.log('name', name)
      this.submit()
    },
    strtonum (value, str) {
      let time = value.replace('str', '') || ''
      return parseFloat(time)
    },
    formatter (type, value) {
      if (type === 'hour') {
        return `${value}小时`
      } else if (type === 'minute') {
        return `${value}分钟`
      }
      return value
    },
    // 百度地图API功能
    G (id) {
      return document.getElementById(id)
    },
    setPlace (myValue) {
      let that = this
      this.map.clearOverlays() // 清除地图上所有覆盖物
      function myFun () {
        var pp = local.getResults().getPoi(0).point // 获取第一个智能搜索的结果
        that.map.centerAndZoom(pp, 18)
        that.map.addOverlay(new BMap.Marker(pp)) // 添加标注
      }
      var local = new BMap.LocalSearch(that.map, { // 智能搜索
        onSearchComplete: myFun
      })
      local.search(myValue)
    },
    getPos () {
      // 获取位置
      let geoL = this.geoLocation
      let that = this
      geoL.getCurrentPosition(r => {
        if (geoL.getStatus() == BMAP_STATUS_SUCCESS) {
          var mk = new BMap.Marker(r.point)
          that.map.addOverlay(mk)
          that.map.panTo(r.point)
        } else {
        }
      })
    },
    searchplace () {
      var ac = new BMap.Autocomplete( // 建立一个自动完成的对象
        { 'input': 'suggestId',
          'location': this.map
        })
      let that = this
      ac.addEventListener('onhighlight', function (e) { // 鼠标放在下拉列表上的事件
        var str = ''
        var _value = e.fromitem.value
        var value = ''
        if (e.fromitem.index > -1) {
          value = _value.province + _value.city + _value.district + _value.street + _value.business
        }
        str = 'FromItem<br />index = ' + e.fromitem.index + '<br />value = ' + value

        value = ''
        if (e.toitem.index > -1) {
          _value = e.toitem.value
          value = _value.province + _value.city + _value.district + _value.street + _value.business
        }
        str += '<br />ToItem<br />index = ' + e.toitem.index + '<br />value = ' + value
        that.G('searchResultPanel').innerHTML = str
      })

      var myValue
      ac.addEventListener('onconfirm', function (e) { // 鼠标点击下拉列表后的事件
        var _value = e.item.value
        myValue = _value.province + _value.city + _value.district + _value.street + _value.business
        that.G('searchResultPanel').innerHTML = 'onconfirm<br />index = ' + e.item.index + '<br />myValue = ' + myValue

        that.setPlace(myValue)
      })
    },
    // 加载地图
    loadScript () {
      loadingMap('baidu', 'w0xIuTu7ZCymWOc8U9Sma0pXiWFFK1Wc').then(() => {
        this.initMap()
      })
    },
    initMap () {
      let that = this
      this.map = new BMap.Map('mapPanel')
      // 初始城市上海
      this.map.centerAndZoom(new BMap.Point(121.480539, 31.235929), 18)
      this.map.addEventListener('dragend', () => {
        // this.showPoi();
      })
      let styleOptions = {
        strokeColor: '#1CBB67', // 边线颜色。
        fillColor: '#9FDEC6', // 填充颜色。当参数为空时，圆形将没有填充效果。
        strokeWeight: 4, // 边线的宽度，以像素为单位。
        strokeStyle: 'solid' // 边线的样式，solid或dashed。
      }
      this.geoLocation = new window.BMap.Geolocation()
      this.getPos()
      this.mapShow = true
      // 查找位置
      this.searchplace()
      this.map.addEventListener('click', this.addline)
      // 显示已存在的围栏
      // console.log('ss', this.enclosure)
      this.enclosurePoint = this.enclosure.enclosurePoint
      this.enclosurePoint.map((item, index) => {
        this.pointarr.push(new BMap.Point(item.pointLon, item.pointLat))
      })
      if (this.enclosurePoint.length > 1) {
        that.polygon = new BMap.Polygon(that.pointarr, styleOptions) // 创建多边形
        that.map.addOverlay(that.polygon)
      }
    },
    // 返回上一页
    goBack () {
      this.$router.go(-1)
    },
    addline (e) {
      console.log('e', e)
      if (this.isset === 1) {
        var pt = new BMap.Point(e.point.lng, e.point.lat)
        var myIcon = new BMap.Icon('https://wwmimgs.oss-cn-shenzhen.aliyuncs.com/develop/2019-06-21/lvdian.png', new BMap.Size(25, 36))
        var marker2 = new BMap.Marker(pt, { icon: myIcon }) // 创建标注
        this.map.addOverlay(marker2)
        this.enclosurePoint.push({
          pointLat: e.point.lat,
          pointLon: e.point.lng
        })
        this.pointarr.push(new BMap.Point(e.point.lng, e.point.lat))
        this.map.removeOverlay(this.polygon)
        if (this.enclosurePoint.length > 1) {
          this.polygon = new BMap.Polygon(this.pointarr, { strokeColor: 'blue', strokeWeight: 2, strokeOpacity: 0.5 }) // 创建多边形
          this.map.addOverlay(this.polygon)
        }
      }
    },
    // 设置围栏
    setFence () {
      let that = this
      if (this.isset === 1) {
        if (this.enclosurePoint.length < 3) {
          Toast('请至少在地图上选择3个点后，才可以完成围栏设置！')
        } else {
          this.submit()
        }
      } else {
        Dialog.confirm({
          title: '请点击地图上至少3个点完成围栏设置'
        }).then(() => {
          // on confirm
          that.isset = 1
          that.enclosurePoint = []
          that.pointarr = []
          that.map.removeOverlay(that.polygon)
        }).catch(() => {
          // on cancel
        })
      }
    }

  }
}
</script>

<style lang="less" scoped>
#mapPanel {
  min-height: 300px;
  height: 100%;
  width: 100%;
  position: absolute !important;
  background-color: rgba(76, 76, 76, 0.4);
}
.search-wrap {
  text-align: center;
  position: absolute;
  width: 92%;
  top: 58px;
  z-index: 100;
  left: 4%;
  padding-right: 15px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 1px 5px 0px rgba(51, 51, 51, 0.2);
  input {
    font-size: 14px;
    color: hsl(217, 6%, 75%);
    line-height: 48px;
  }
  .serach-icon {
    font-size: 22px;
    line-height: 48px;
  }
}
.mapsearch .van-search__content {
  background: #fff;
  border-radius: 8px;
}
.popcontainer {
  height: 11rem;
  width: 100%;
  background: #fff;
  padding: 0 12px;
  box-sizing: border-box;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.transback {
  background: transparent;
}
.topheader {
  width: 100%;
  img {
    margin: 0 auto;
    width: 60px;
    height: 16px;
    margin-bottom: -2px;
    display: block;
  }
}
.headertitle {
  font-size: 15px;
  color: #232b38;
  line-height: 40px;
  text-align: center;
}
.popupradio {
  font-size: 15px;
  color: #878d97;
  line-height: 30px;
}
.popupradio .radiolabel {
  color: #878d97;
  line-height: 30px;
}
.van-radio-checked .radiolabel {
  color: #232b38;
}
.line {
  height: 0px;
  border: 0.5px solid #e6e7ed;
  margin: 5px auto;
}
.headcell {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
}
.spacebetween {
  justify-content: space-between;
}
.celltitle {
  line-height: 40px;
  color: #232b38;
}
.cellinfo {
  line-height: 40px;
  color: #878d97;
  font-size: 13px;
}
.cellicon {
  width: 18px;
  height: 18px;
  margin-left: 12px;
}
.infobox {
  width: 8rem;
  min-height: 184px;
  border-radius: 10px;
  padding: 15px;
  background: #fff;
  font-size: 15px;
}
.infotitle {
  font-size: 16px;
  color: #000;
  line-height: 40px;
  text-align: center;
}
.infodesc {
  color: #878d97;
  line-height: 34px;
}
.bottombox {
  height: 48px;
  border-top: 0.5px solid #e6e7ed;
  background: #fff;
  font-size: 15px;
  line-height: 48px;
  color: #232b38;
}
.buttombutton {
  width: 50%;
  text-align: center;
}
.surebutton {
  color: #2c92f9;
}
.pickerbox {
  background: #fff;
  border-radius: 10px 10px 0 0;
  padding-top: 10px;
}
.bottomtext {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 48px;
  background-color: #fff;
  color: #232b38;
  font-size: 15px;
  line-height: 48px;
  text-align: center;
  border-radius: 10px 10px 0 0;
  img {
    width: 60px;
    height: 16px;
    display: block;
    position: absolute;
    top: -15px;
    right: calc((100% - 60px) / 2);
  }
}
</style>
