/**
 * 简单的封装axios
 */
import axios from 'axios'
import qs from 'qs'
import Cookies from 'js-cookie'
import router from '@/router'
import { Toast } from 'vant'

const $http = axios.create({ baseURL: '/api' })
// 请求时拦截参数
$http.interceptors.request.use(
  config => {
    config = {
      ...config
    }
    let token = Cookies.get('gps_xtoken_teacher')// 获取登录那儿请求返回的xtoken
    if (config.method === 'post') {
      config.data = qs.stringify(config.data)
    }
    // console.log('login', typeof token, router)
    // 带上token

    if (token && token !== 'undefined') {
      config.headers.xtoken = JSON.parse(token).xtoken
      // 单独拦截
    } else {
      // console.log('login', token, router)
      // 重定向到登录页面

    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
// 响应成功，处理结果
$http.interceptors.response.use(
  response => {
    if (response.data.code == 'MSYS0004') {
      router.push('/login')
      Cookies.set('gps_xtoken_teacher', '')// 获取登录那儿请求返回的xtoken
    }
    response = response.data
    const msg = response.msg
    return response
  },
  err => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = '请求错误'
          break
        case 401:
          err.message = '未授权，请登录'
          break
        case 403:
          err.message = '拒绝访问'
          break
        case 404:
          err.message = `请求地址出错: ${err.response.config.url}`
          break
        case 408:
          err.message = '请求超时'
          break
        case 500:
          err.message = '服务器内部错误'
          break
        case 501:
          err.message = '服务未实现'
          break
        case 502:
          err.message = '网关错误'
          break
        case 503:
          err.message = '服务不可用'
          break
        case 504:
          err.message = '网关超时'
          break
        case 505:
          err.message = 'HTTP版本不受支持'
          break
        default:
      }
    }
    Toast(err.message)
    return Promise.reject(err)
  }
)

export default $http
