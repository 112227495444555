<template>
  <div class="container">
    <!--添加设备-->
    <van-nav-bar title="添加设备"
                 right-text="扫描"
                 left-arrow
                 :border="false"
                 @click-left="onClickLeft"
                 @click-right="onClickRight" />

    <van-cell-group>
      <van-field v-model="equipment.IMEI"
                 class="filed-cell"
                 clearable
                 @input='handleinput'
                 label="设备码"
                 placeholder="请输入设备码">

      </van-field>
    </van-cell-group>
    <van-cell-group>
      <van-field v-model="equipment.equipmentName"
                 class="filed-cell"
                 clearable
                 label="设备名称"
                 placeholder="请输入设备名称" />
    </van-cell-group>
    <div class="user-info">
      <!---学校-->
      <div class="user-cont">
        <div class="titlereta">请选择学校</div>
        <div class="relation">
          <span class="containitem">{{equipment.school}}</span>
          <van-icon name="arrow"
                    @click="alertSet('school')"
                    class="enter" />
        </div>
      </div>
      <!--年级-->
      <div class="user-cont">
        <div class="titlereta">请选择年级</div>
        <div class="relation">
          <span class="containitem"> {{equipment.grade}}</span>
          <van-icon name="arrow"
                    @click="alertSet('grade')"
                    class="enter" />
        </div>
      </div>
      <!--班级-->
      <div class="user-cont">
        <div class="titlereta">请选择班级</div>
        <div class="relation">
          <span class="containitem"> {{equipment.deptName}}</span>
          <van-icon name="arrow"
                    @click="alertSet('class')"
                    class="enter" />
        </div>
      </div>
    </div>
    <!--<van-cell-group>
      <van-field v-model="equipment.IMEI"
                 class="filed-cell"
                 clearable
                 :error="usernameError">
        <span slot="label">请选择学校</span>
        <van-cell title="请选择学校"
                  is-link
                  slot="input" />
      </van-field>
    </van-cell-group>
    <van-cell-group>
      <van-field v-model="equipment.IMEI"
                 clearable
                 class="filed-cell"
                 :error="usernameError">
        <span slot="label">请选择学校</span>
        <span slot="input">
          <van-cell title="请选择年级"
                    is-link
                    slot="input" />
        </span>
      </van-field>
    </van-cell-group>
    <van-cell-group>
      <van-field v-model="equipment.deptId"
                 clearable
                 class="filed-cell"
                 :error="usernameError">
        <span slot="label">请选择学校</span>
        <span slot="input">
          <van-cell title="请选择班级"
                    is-link />
        </span>
      </van-field>
    </van-cell-group>-->
    <van-cell value="设备头像"
              :border='false'
              class="touxiang" />
    <singleUpload :iStyle="iStyle"
                  @input="input"
                  @afterr='afterr'
                  ref="singleUpload"
                  @checkadd='checkadd'
                  v-model="equipment.equipmentPic"
                  class="uploadclass"></singleUpload>
    <div @click="addequ"
         class="addclass common-button">添加</div>

    <!--弹出选择学校，，-->
    <van-popup v-model="show"
               position="bottom"
               :overlay="true"
               class="pop"
               style>
      <div class="pop-wrap">
        <div v-if="options.length<=0"
             class="nodata">暂无数据</div>
        <div v-else>
          <van-picker :columns="options"
                      :default-index="index"
                      @change="onChange" />
        </div>
        <div class="van-hairline--top-bottom van-picker__toolbar">
          <div role="button"
               tabindex="0"
               @click="oncancel"
               class="van-picker__cancel">取消</div>
          <div role="button"
               tabindex="0"
               @click="onConfirm"
               class="van-picker__confirm">确认</div>
        </div>
      </div>
    </van-popup>
    <!--弹出选择，年级，-->
    <van-popup v-model="gshow"
               position="bottom"
               :overlay="true"
               class="pop"
               style>
      <div class="pop-wrap">
        <div v-if="goptions.length<=0"
             class="nodata">暂无数据</div>
        <div v-else>
          <van-picker :columns="goptions"
                      :default-index="gindex"
                      @change="gonChange" />
        </div>
        <div class="van-hairline--top-bottom van-picker__toolbar">
          <div role="button"
               tabindex="0"
               @click="goncancel"
               class="van-picker__cancel">取消</div>
          <div role="button"
               tabindex="0"
               @click="gonConfirm"
               class="van-picker__confirm">确认</div>
        </div>
      </div>
    </van-popup>
    <!--弹出选择班级，-->
    <van-popup v-model="cshow"
               position="bottom"
               :overlay="true"
               class="pop"
               style>
      <div class="pop-wrap">
        <div v-if="coptions.length<=0"
             class="nodata">暂无数据</div>
        <div v-else>
          <van-picker :columns="coptions"
                      :default-index="cindex"
                      @change="conChange" />
        </div>
        <div class="van-hairline--top-bottom van-picker__toolbar">
          <div role="button"
               tabindex="0"
               @click="concancel"
               class="van-picker__cancel">取消</div>
          <div role="button"
               tabindex="0"
               @click="conConfirm"
               class="van-picker__confirm">确认</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import wx from 'weixin-js-sdk'
import singleUpload from '@/components/Upload/singleUploadcp' // D:\wenwenmao\xiaongka\H5\gps-h5\src\components\Upload\singleUpload.vue
import { Icon, Toast, Loading } from 'vant'
import http from '@/utils/http'
import { setInterval } from 'timers'
export default {

  components: {
    singleUpload,
    'van-icon': Icon
  },
  watch: {
    'equipment.IMEI': {
      handler (val, oldval) {
        console.log(this.notinput, val, oldval)
        if (this.notinput) {
          if (oldval == '' && val != '' || val != oldval) {
            if (JSON.stringify(clear()) != '{}') { this.loadingimei.clear() }
          }
        } else {
          // if (JSON.stringify(clear()) != '{}') { this.loadingimei.clear() }
        }
      },
      deep: true
    }
  },
  data () {
    return {
      iStyle: '',
      notinput: false, // 不是输入 true 是
      equipment: { equipmentPic: '', IMEI: '' },
      userCodeError: function () {

      },
      cindex: 0,
      index: 0,
      gindex: 0,
      oldshow: 'school',
      usernameError: false,
      options: [],
      coptions: [],
      goptions: [],
      optionsarr: [],
      goptionsarr: [],
      coptionsarr: [],
      show: false,
      gshow: false,
      cshow: false,
      noclass: false,
      noglade: false,
      noschool: false,
      isupload: false,
      selectdata: '', // 选择的数据
      anniu: 'school',
      change: false,
      code: '',
      olddata: '',
      selectsome: {},
      olddataname: '',
      loadingimei: {}
    }
  },
  methods: {
    alertSet (data) {
      console.log(data)
      switch (data) {
        case 'school':
          if (!this.noschool) {
            this.show = true
          } else {
            this.$toast('暂无学校可选')
          }
          break
        case 'grade':

          this.getGlade()
          break
        case 'class':

          this.getClass()
          break
      }
    },
    getSchool () {
      http.post('p-gps-schoolDept-query').then(res => {
        if (res.code == 'ok') {
          this.options = []
          this.optionsarr = []
          this.optionsarr = res.data
          if (this.optionsarr.length > 0) {
            this.optionsarr.map(v => {
              this.options.push(v.fullname)
              // this.options = ['杭州', '宁波', '温州', '嘉兴', '湖州']
            })
            this.noschool = false
          } else {
            this.$toast('暂无学校可选')
            this.noschool = true
          }

          console.log(res)
        } else {
          this.$toast(res.message)
        }
      })
    },
    getGlade () {
      console.log(this.equipment.schoolId)
      if (this.equipment.schoolId) {
        http.post('p-gps-gradeDept-query', { schoolId: this.equipment.schoolId }).then(res => {
          if (res.code == 'ok') {
            this.goptions = []
            this.goptionsarr = []
            this.goptionsarr = res.data
            if (this.goptionsarr.length > 0) {
              this.goptionsarr.map(v => {
                this.goptions.push(v.fullname)
                // this.options = ['杭州', '宁波', '温州', '嘉兴', '湖州']
              })

              this.gshow = true
            } else {
              this.$toast('暂无年级可选')
              this.noglade = true
            }
          } else {
            this.$toast(res.message)
          }
        })
      } else {
        this.$toast('请先选择学校')
      }
    },
    getClass () {
      this.coptions = []
      this.coptionsarr = []
      if (this.equipment.gradeId) {
        http.post('p-gps-classDept-query', { gradeId: this.equipment.gradeId }).then(res => {
          if (res.code == 'ok') {
            // this.options = res.data
            this.coptionsarr = res.data
            if (this.coptionsarr.length > 0) {
              this.coptionsarr.map(v => {
                this.coptions.push(v.fullname)
                // this.options = ['杭州', '宁波', '温州', '嘉兴', '湖州']
              })
              this.cshow = true
            } else {
              this.$toast('暂无班级可选')
              this.noclass = true
            }
          } else {
            this.$toast(res.message)
          }
        })
      } else {
        this.$toast('请先选择年级')
      }
    },
    oncancel () {
      this.show = false
    },
    goncancel () {
      this.gshow = false
    },
    concancel () {
      console.log('a')
      this.cshow = false
    },
    onConfirm () {
      this.show = false
      if (this.selectsome.school) {
        this.equipment.schoolId = this.selectsome.school.schoolId
        this.equipment.school = this.selectsome.school.school
      } else {
        this.equipment.schoolId = this.optionsarr[0].id
        this.equipment.school = this.optionsarr[0].fullname
      }
      this.equipment.gradeId = ''
      this.equipment.grade = ''
      this.equipment.deptId = ''
      this.equipment.deptName = ''
    },
    gonConfirm () {
      this.gshow = false
      if (this.selectsome.grade) {
        this.equipment.gradeId = this.selectsome.grade.gradeId
        this.equipment.grade = this.selectsome.grade.grade
      } else {
        this.equipment.gradeId = this.goptionsarr[0].id
        this.equipment.grade = this.goptionsarr[0].fullname
      }
      this.equipment.deptId = ''
      this.equipment.deptName = ''
    },

    conConfirm () {
      this.cshow = false
      if (this.selectsome.dept) {
        this.equipment.deptId = this.selectsome.dept.deptId
        this.equipment.deptName = this.selectsome.dept.deptName
      } else {
        this.equipment.deptId = this.coptionsarr[0].id
        this.equipment.deptName = this.coptionsarr[0].fullname
      }
    },

    onChange (picker, value, index) {
      this.selectsome.school = { schoolId: this.optionsarr[index].id, school: value }
      this.index = index
      // this.equipment.schoolId = this.optionsarr[index].id
      // this.equipment.school = value
    },
    conChange (picker, value, index) {
      this.selectsome.dept = { deptId: this.coptionsarr[index].id, deptName: value }
      this.cindex = index
      // this.equipment.deptId = this.coptionsarr[index].id
      // this.equipment.deptName = value
    },
    gonChange (picker, value, index) {
      this.selectsome.grade = { gradeId: this.goptionsarr[index].id, grade: value }
      this.gindex = index
      // this.equipment.gradeId = this.goptionsarr[index].id
      // this.equipment.grade = value
    },
    // 输入头像
    input (value) {
      console.log(value)
      this.equipment.equipmentPic = value
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    // 选择年级
    selGrade () {
      console.log('grade')
      this.popShow = true
    },
    handleinput (value) {
      this.notinput = false
      console.log(value, 11)
    },
    isWeiXin () {
      var ua = window.navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true
      } else {
        return false
      }
    },
    onClickRight () {
      this.notinput = true
      this.loadingimei = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 禁用背景点击
        loadingType: 'spinner',
        message: '加载中……'
      })
      // 微信浏览器判断
      if (this.isWeiXin()) {
        var that = this
        console.log('weixin')
        http.post('p-gps-wechant-sign', { url: window.location.href.split('#')[0] }).then(res => {
          if (res.code == 'ok') {
            wx.config({
              debug: false,
              appId: res.data.appid, // 必填,公众号的唯一标识
              timestamp: res.data.timeStamp, // 必填,生成签名的时间戳
              nonceStr: res.data.nonceStr, // 必填,生成签名的随机串
              signature: res.data.signature, // 必填,签名
              jsApiList: ['scanQRCode'] // 必填,需要使用的JS接口列表
            })
            wx.ready(function () {
              // config信息验证成功后会执行ready方法,所有接口调用都必须在config接口获得结果之后
              // config 是一个客户端的异步操作,所以如果需要在页面加载时调用相关接口,则须把相关接口放在ready函数中调用来确保正确执行.对于用户触发是才调用的接口,则可以直接调用,不需要放在ready函数中
              wx.checkJsApi({ // 判断当前客户端版本是否支持指定JS接口
                jsApiList: [
                  'scanQRCode'
                ],
                success: function (res) {
                  if (res.checkResult.scanQRCode === true) {
                    wx.scanQRCode({ // 扫一扫
                      desc: 'scanQRCode desc',
                      needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                      scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
                      success: function (res) {
                        // alert('result', res)
                        // Toast.loading({
                        //   duration: 0, // 持续展示 toast
                        //   mask: true,
                        //   forbidClick: true, // 禁用背景点击
                        //   message: '加载中...'
                        // })
                        that.equipment.IMEI = res.resultStr.split('imei=')[1]
                        that.loadingimei.clear()
                        // let secont = 30
                        // const timer = setInterval(() => {
                        //   alert(second)
                        //   second--
                        //   if (that.equipment.IMEI == '' && second) {
                        //   } else {
                        //     clearInterval(timer)
                        //     Toast.clear()
                        //   }
                        // }, 1000)
                        // this.code == res.resultStr
                        // const getCode = res.resultStr // 当needResult 为 1 时，扫码返回的结果
                      }
                    })
                  } else {
                    Toast('抱歉，当前客户端版本不支持扫一扫')
                  }
                },
                fail: function (res) { // 检测getNetworkType该功能失败时处理
                  Toast('扫描失败' + res)
                }
              })
            })
          } else {
            Toast('请求失败！'+(res.message+res.optionMsg))
          }
        })
      } else {
        Toast('请使用微信浏览扫描二维码')
      }
    },
    // 验证输入是否为空
    checkPull (value) {
      var arr = ['IMEI', 'equipmentName', 'deptId', 'equipmentPic']
      var isadd = []
      arr.map(v => {
        console.log(value[v], v, value)
        if (value[v]) {
          isadd.push(1)
        } else {
          isadd.push(0)
        }
      })
      console.log(isadd, isadd.indexOf(0))
      if (isadd.indexOf(0) !== -1) {
        return false
      } else {
        return true
      }
    },
    // 添加
    addequ () {
      if (this.isupload) {
        this.loading = true
        this.$refs.singleUpload.afterRead()
      } else {
        this.$toast('请选择设备头像')
      }
    },
    // 上传拖欠
    afterr (value) {
      console.log(value)
      this.isupload = value
    },
    checkadd () {
      if (this.checkPull(this.equipment)) {
        // this.$refs.singleUpload.afterRead()
        http.post('p-gps-equipment-add', this.equipment).then(res => {
          this.loading = true
          if (res.code == 'ok') {
            this.$router.push('Position')
          } else {
            Toast.fail(res.message)
          }
        })
      } else {
        this.$toast('请输入必填项')
        this.loading = false
      }
    }

  },
  mounted () {
    this.getSchool()
  }

}
</script>
<style lang="less" scoped>
.container {
  position: relative;
  color: #232b38;
  width: 100%;
  font-size: 14px;
  background: url("../../assets/imgs/mine/icons.png") no-repeat -75px -37px;
  height: 667px;
  background: #fff;
  padding-top: 10px;
  position: fixed;
  .user-info {
    background: #fff;
    margin: 0 13px 0 0px;
    border-radius: 10px;
    .enter {
      width: 9px;
      height: 15px;
      position: absolute;
      top: 23px;
      right: 4px;
      color: #babdc2;
      // background: url("../../assets/imgs/mine/icons.png") no-repeat -75px -37px;
      // background-size: 116px 91px;
    }
    .user-cont {
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #eee;
      position: relative;
      padding: 0 0 0 13px;
    }
  }
}
.relation {
  display: inline-block;
}
.addclass {
  position: absolute;
  top: 510px;
  width: 349px;
  border-radius: 30px;
}
.uploadclass {
  width: 100px;
  height: 100px;
  padding: 0 0;
  position: absolute;
  top: 373px;
  left: 13px;
}
.filed-cell {
  height: 60px;
  line-height: 60px;
  color: #232b38;
  font-size: 14px;
  padding: 0 13px;
}
.van-uploader__upload-icon {
  width: 100px;
  height: 100px;
}
.van-nav-bar__title {
  font-size: 18px;
  font-family: 微软雅黑;
  color: #232b38;
}
.van-nav-bar__text {
  font-size: 16px;
  color: #2c92f9;
}
.van-nav-bar {
  width: 375px;
  height: 48px;
}
.touxiang::after {
  border-bottom: 0;
}

.touxiang {
  margin-top: 0px;
}
.titlereta {
  display: inline-block;
}
// .touxiang::before {
//   position: absolute;
//   left: 8px;
//   color: #f44;
//   font-size: 14px;
//   content: "*";
// }
// .user-cont::before {
//   position: absolute;
//   left: 8px;
//   color: #f44;
//   font-size: 14px;
//   content: "*";
// }
.containitem {
  padding-left: 20px;
}
.nodata {
  height: 90px;
  text-align: center;
  margin-top: 40px;
}
</style>
