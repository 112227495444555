// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
// 移动端rem 适配
import 'amfe-flexible/index.js'
import '@/assets/styles/index.css'
import '@/assets/styles/reset.css'
import Vant from 'vant'
import 'vant/lib/index.css'
import Calendar from 'vue-mobile-calendar'


Vue.prototype.goBack = function () {
  window.history.back(-1);
}


Vue.use(Calendar)
Vue.use(Vant)
Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")