
import Position from '@/view/Position/Index'
import AddEqu from '@/view/Position/AddEqu'
import quanjing from '@/view/Position/quanjing'
import Equmentchios from '@/view/Position/Equmentchios'
import Phone from '@/view/Position/Phone'
export default [
  {
    path: '/',
    name: 'Position',
    component: Position
  },
  {
    path: '/Position',
    name: 'Position',
    component: Position
  }, {
    path: '/AddEqu', 
    name: 'AddEqu',
    component: AddEqu
  }, {
    path: '/Equmentchios', 
    name: 'Equmentchios',
    component: Equmentchios
  }, {
    path: '/quanjing',
    name: 'quanjing',
    component: quanjing
  }, {
    path: '/positionPhone', 
    name: 'positionPhone',
    component: Phone
  }
]
