<template>
  <div>
    <van-nav-bar title="设备轨迹" left-text left-arrow :border="false" @click-left="goBack" />
    <div class="search-wrap" @click="searchLocus">
      {{date}}
      <span v-if="beginTime">({{beginTime}} - {{endTime}})</span>
      <span class="more"></span>
    </div>
     <!-- 暂无相关轨迹点 S -->
    <div class="no-points-data" v-if="noPoint">没有相关轨迹信息</div>
    <!-- 暂无相关轨迹点 E -->
    <div id="mapPanel">
      <div class="map-loading-center">
        <van-loading type="spinner" color="#1989fa" style="margin: 0 auto" />
      </div>
    </div>
  </div>
</template>
<script>
import { loadingMap } from "@/utils/load";
import { mapState } from "vuex";
import mine from "@/store/mine/index";
import { LOCQUERY } from "@/store/mine/types";
export default {
  data() {
    return {
      map: null, // 地图实例
      walking: null, //
      points: [],
      centerPoint: null,
      index: 0,
      timer: null,
      polyLine: null,
      start: null,
      end: null
    };
  },
  created() {
    let imei = this.$route.params.imei;
    if (imei) {
      this.$store.dispatch(LOCQUERY, {
        IMEI: imei,
        startTime: this.date + " " + this.beginTime,
        endTime: this.date + " " + this.endTime
      });
    }
  },
  watch: {
    pointList: {
      handler: function() {
        if (this.pointList.length > 0) {
          this.pointList.map((item, index) => {
            this.points.push(new BMap.Point(item.lon, item.lat));
          });
          this.initRoute();
        } else {
          // 初始化地图
          this.map = new BMap.Map("mapPanel");
          this.map.centerAndZoom(new BMap.Point(118.60036234, 24.90165328), 16);
          console.log("watch-pointList");
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      noPoint: state => mine.state.noPoint,
      pointList: state => mine.state.pointList,
      beginTime: state => mine.state.beginTime,
      endTime: state => mine.state.endTime,
      date: state => mine.state.date
    })
  },
  methods: {
    addLine(arr) {
      this.map.clearOverlays();
      var linePoints = arr;
      let s = new BMap.Icon(
        require("../../../assets/imgs/icon_qidian.png"),
        new BMap.Size(34, 40)
      );
      let e = new BMap.Icon(
        require("../../../assets/imgs/icon_zhogndian.png"),
        new BMap.Size(34, 40)
      );
      // 添加起点、终点
      this.start = new BMap.Marker(
        new BMap.Point(linePoints[0].lng, linePoints[0].lat)
      );
      this.start.setIcon(s);
      this.map.addOverlay(this.start);
      this.end = new BMap.Marker(
        new BMap.Point(
          linePoints[linePoints.length - 1].lng,
          linePoints[linePoints.length - 1].lat
        )
      );
      this.end.setIcon(e);
      this.map.addOverlay(this.end);
      // 添加折线
      this.polyline = new BMap.Polyline(linePoints, {
        strokeColor: "#FF432F",
        strokeWeight: 3,
        strokeOpacity: 1
      });
      this.map.addOverlay(this.polyline);
      // setTimeout(() => {
        this.map.setViewport(arr);
      // }, 1000);
    },
    drivingLine(myP1, myP2, arr1) {
      var ptsArr = [];
      this.map.clearOverlays(); //清除地图上的覆盖物
      var driving = new BMap.DrivingRoute(
        this.map,
        {
          onSearchComplete: results => {
            if (driving.getStatus() == BMAP_STATUS_SUCCESS) {
              var plan = driving.getResults().getPlan(0);
              var num = plan.getNumRoutes();
              for (var j = 0; j < num; j++) {
                var pts = plan.getRoute(j).getPath();
                var polyline = new BMap.Polyline(pts);
                ptsArr = ptsArr.concat(polyline);
                polyline.setStrokeColor("#FF432F");
                polyline.setStrokeWeight(3);
                polyline.setStrokeStyle("solid");
                this.map.addOverlay(polyline);
              }

              var m1 = new BMap.Marker(myP1);
              console.log("myP2", myP2);
              var m2 = new BMap.Marker(myP2);
              var lab1 = new BMap.Label("起点", { position: myP1 });
              var lab2 = new BMap.Label("终点", { position: myP2 });

              this.map.addOverlay(m1);
              this.map.addOverlay(m2);
              this.map.addOverlay(lab1);
              this.map.addOverlay(lab2);
            }
          }
        },
        { renderOptions: { map: this.map, autoViewport: true } }
      );
      var group = Math.floor(arr1.length / 10);
      var mode = arr1.length % 10;
      if (mode != 0) {
        var waypoints = arr1.slice(group * 10, arr1.length - 1);
        driving.search(arr1[group * 10], arr1[arr1.length - 1], {
          waypoints: waypoints
        });
      }

      for (var i = 0; i < group; i++) {
        var waypoints = arr1.slice(i * 10 + 1, (i + 1) * 10);
        driving.search(arr1[i * 10], arr1[(i + 1) * 10], {
          waypoints: waypoints
        });
      }
      setTimeout(function() {
        this.map.setViewport(arr1);
      }, 1000);
    },
    initRoute() {
      this.map ? "" : this.initMap(); // 地图尚未实例化
      this.map.centerAndZoom(this.points[0], 16);
      this.map.enableScrollWheelZoom();
      // 添加 轨迹路线
      this.addLine(this.points);
    },
    // 选择时间
    searchLocus() {
      this.$store.commit("SETLOCUSPOINT");
      // this.$store.commit("SETLOCUSDATE");
      this.$router.push(
        "/mine/device/selectdate?imei=" + this.$route.params.imei
      );
    },
    play() {
      var point = this.points[this.index];
      if (this.index > 0) {
        this.map.addOverlay(
          new BMap.Polyline([this.points[this.index - 1], point], {
            strokeColor: "green",
            strokeWeight: 4,
            strokeOpacity: 1
          })
        );
      }
      // this.footmark.setPosition(point);
      this.index++;
      if (this.index < this.points.length) {
        this.timer = setTimeout(() => {
          this.play(this.index);
          this.map.panTo(this.points[this.index]);
        }, 500);
      } else {
        this.map.panTo(point);
      }
    },
    // 返回上一页
    goBack() {
      this.$store.commit("SETLOCUSPOINT");
      this.$store.commit("SETLOCUSDATE");
      this.$router.push("/mine/devicemanage");
    },
    // 初始化地图
    initMap() {
      this.map = new BMap.Map("mapPanel");
      // 初始城市上海
      this.map.centerAndZoom(new BMap.Point(121.480539, 31.235929), 15);
      this.map.addEventListener("dragend", () => {});
    }
  }
};
</script>

<style lang="less" scoped>
#mapPanel {
  height: 650px;
  width: 100%;
  background-color: rgba(76, 76, 76, 0.4);
}
.search-wrap {
  text-align: center;
  position: absolute;
  width: 94%;
  top: 58px;
  z-index: 100;
  left: calc((100% - 94%) / 2);
  background: #fff;
  color: #232b38;
  font-size: 17px;
  border-radius: 10px;
  padding: 17px 0;
  box-shadow: 0px 2px 10px 0px rgba(51, 51, 51, 0.2);
  span.more {
    width: 0;
    height: 0;
    border-width: 7px 7px 0;
    border-style: solid;
    border-color: #232b38 transparent transparent; /*灰 透明 透明 */
    position: relative;
    top: 12px;
    left: 6px;
  }
}
</style>
