export const GETUSERINFO = "GETUSERINFO"  // 获取当前登录人信息
export const UPDATEMEMBERINFO = "UPDATEMEMBERINFO"  // 更新当前登录人信息
export const DICTIONARY = "DICTIONARY"  // 常见问题
// 设备
export const DROPDOWNOPTION = "DROPDOWNOPTION"  // 下拉框选项
export const EQUIPMENTLIST = "EQUIPMENTLIST"  // 设备管理列表
export const EQUIPMENTDELETE = "EQUIPMENTDELETE"  // 解绑设备
export const EQUIPMENTDETAIL = "EQUIPMENTDETAIL"  // 设备详情
export const LEQUIPMENTDETAIL = "LEQUIPMENTDETAIL"  // 定位设备详情
export const EQUIPMENTUPDATE = "EQUIPMENTUPDATE"  // 编辑设备信息
export const LOCLAST = "LOCLAST"  // 设备位置经纬度查询
// 安全围栏
export const ENCOLSUREADD = "ENCOLSUREADD"  // 新增安全围栏
export const ENCOLSUREQUERY = "ENCOLSUREQUERY"  // 安全围栏查询
export const ENCOLSUREEDIT = "ENCOLSUREEDIT"  // 安全围栏编辑
export const EQUIPMENTSETUPUPDATE = "EQUIPMENTSETUPUPDATE"  // 编辑设备设置

// 设备轨迹点
export const LOCQUERY = "LOCQUERY"  

// 账户安全
export const GETVERIFYCODE = "GETVERIFYCODE" // 发送短信验证码
export const PHONECHANGEPWD = "PHONECHANGEPWD" // 校方通过手机号修改密码 

// 年级、班级列表
export const GRADELIST = "GRADELIST" 
export const CLASSLIST = "CLASSLIST" 