<template>
  <div>
    <van-nav-bar title="实时全景" left-arrow @click-left="goBack"/>
    <div id="panorama"></div>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    start () {
      let obj = sessionStorage.getItem('scene')
      var map = new BMap.Map('panorama')
      // 添加覆盖层
      map.addTileLayer(new BMap.PanoramaCoverageLayer())
      var panorama = new BMap.Panorama('panorama')
      panorama.setPosition(new BMap.Point(obj.lng, obj.lat)) 
    }
  },
  mounted () {
    // 渲染实时街景
    this.start()
  }
}
</script>
<style scoped>
#panorama {
  width: 100%;
  height: 700px;
}
</style>
