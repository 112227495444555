<!-- 设备管理 -->
<template>
  <div class="wrap">
    <van-nav-bar title="设备管理" left-text right-text left-arrow :border="false" @click-left="goBack"/>
    <!-- 标签页 S -->
    <van-tabs
      class="d-tabs"
      v-model="active"
      title-active-color="#232B38"
      title-inactive-color="#696F77"
      :swipeable="true"
      @change="changeType"
    >
      <van-tab :title="`全部(${info.totalNum || 0})`">
        <device-list
          @offDivice="offDivice"
          :type="active"
          :list="list"
          :page="info.page"
          :totalPage="info.totalPage"
          @findPage="findPage"
        ></device-list>
      </van-tab>
      <van-tab :title="`在线(${info.onlineNum || 0})`">
        <device-list
          @offDivice="offDivice"
          :type="active"
          :list="list"
          :page="info.page"
          :totalPage="info.totalPage"
          @findPage="findPage"
        ></device-list>
      </van-tab>
      <van-tab :title="`离线(${info.outNum || 0})`">
        <device-list
          @offDivice="offDivice"
          :type="active"
          :list="list"
          :page="info.page"
          :totalPage="info.totalPage"
          @findPage="findPage"
        ></device-list>
      </van-tab>
    </van-tabs>
    <!-- 标签页 E -->
  </div>
</template>
<script>
import DeviceList from "./child/DeviceList";
import { mapState } from "vuex";
import mine from "@/store/mine/index";
import { EQUIPMENTLIST, EQUIPMENTDELETE } from "@/store/mine/types";
export default {
  data () {
    return {
      active: "0", // 设备类型
      queryData: {
        page: 1,
        pageSize: 5,
        equipmentStatus: ''
      }
    };
  },
  beforeDestroy: function () {
    this.$store.commit('RESETPARAMS');
  },
  components: {
    DeviceList
  },
  computed: {
    ...mapState({
      info: state => mine.state.deviceInfo,
      list: state => mine.state.deviceList,
      allLoaded: state => mine.state.allLoaded
    }),
  },
  watch: {
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.$store.dispatch(EQUIPMENTLIST, this.queryData);
    },
    changeType () {
      this.$store.commit('RESETPARAMS')
      this.queryData.equipmentStatus = this.active
      this.type = this.active
      this.queryData.page = 1
      if (this.queryData.equipmentStatus == 0) {
        this.queryData.equipmentStatus = ''
      }
      this.$store.dispatch(EQUIPMENTLIST, this.queryData);
    },
    findPage (data) {
      this.queryData.page = data.page
      if (data.page <= this.info.totalPage) {
        this.getList()
      } else { // 请求的页面 > 总页数 （加载完成-无数据）
       this.$store.commit('ALLLOAD')
      }
    },
    // 返回上一页
    goBack () {
      this.$router.push("/mine/index");
    },
    // 解绑设备
    offDivice (data) {
      console.log("data", data)
      this.$store.dispatch(EQUIPMENTDELETE, data);
    }
  }
}
</script>

<style scoped>
.d-tabs .van-tabs__line {
  background-color: #2c92f9;
}
</style>
