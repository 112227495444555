<template>
  <div class="devices-wrap">
    <!-- 上拉刷新 -->
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" class="re-load">
      <!-- 暂无数据 S -->
      <div v-if="noData" class="no-data">
        <img src="../../../assets/imgs/icon_kong.png" />
        <div>暂无数据</div>
      </div>
      <!-- 暂无数据 E -->
      <div class="devices-items" v-else>
        <div v-for="(item, index) in list" class="devices-item" :key="index">
          <div>
            <img :src="item.equipmentPic || photo" />
            <div class="devices-box">
              <!-- 电量 status S -->
              <div>
                <div class="devices-name">{{item.equipmentName}}({{item.className}})</div>
                <div class="device-wrap">
                  <div class="devices-ele">
                    <div class="battery" :class="item.power <= 20?'red':''">
                      <div
                        class="power"
                        :style="{'transform': 'scaleX('+ item.power/ 100+')'}"
                        :class="item.power <= 20?'red':''"
                      ></div>
                    </div>
                    {{item.power}}%
                  </div>
                  <div class="devices-sta">
                     <img :src="item.equipmentStatusName == '在线'?require('../../../assets/imgs/mine/icon_zaixian@2x.png'):require('../../../assets/imgs/mine/icon_lixian@2x.png')" />
                    <!-- <span :class="item.equipmentStatusName == '在线'?'on':'off'"></span> -->
                    {{item.equipmentStatusName}}
                  </div>
                </div>
                <!-- 电量 status E -->
              </div>
              <div>{{item.site}}</div>
              <div>{{item.lastTime | formatTime}}</div>
            </div>
          </div>
          <!-- 定位 轨迹 设置 更多 S -->
          <div>
            <div
              @click="goPosition(item.id, item.IMEI, item.equipmentStatusName, item.locationTime)"
            >
              <span></span>定位
            </div>
            <div @click="goLocus(item.IMEI)">
              <span></span>轨迹
            </div>
            <div @click="goSet(item.id)">
              <span></span>设置
            </div>
            <div @click="showMore(item.id)">
              <span></span>更多
            </div>
          </div>
          <!-- 定位 轨迹 设置 更多 E -->
        </div>
        <!--加载更多 -->
        <div class="load">
          <van-loading type="spinner" class="load-loading" v-if="isLoad" />
          <span class="all-loaded" :class="allLoaded? '': 'border'" v-else>
            <span v-if="allLoaded">加载完成</span>
            <span v-else @click="onLoad">加载更多</span>
          </span>
        </div>
      </div>
    </van-pull-refresh>
    <!-- 更多 弹出层 S -->
    <van-popup v-model="popShow" position="bottom" :overlay="true" class="pop" style>
      <div class="pop-wrap">
        <div @click="editDevice">编辑</div>
        <div @click="offDevice">解绑</div>
        <div @click="popShow = false">取消</div>
      </div>
    </van-popup>
    <!-- 更多弹出层 S -->

    <!-- 解绑对话框 S-->
    <van-dialog
      class="off-dialog"
      v-model="dialogShow"
      @confirm="confirmOff"
      show-cancel-button
      confirm-button-text="确认"
      cancel-button-color="#000"
      confirm-button-color="#000"
      cancel-button-text="取消"
    >
      <div class="off-wrap">
        <div>
          <span></span>确认解绑设备吗
        </div>
        <div>按下确定后将于设备接触绑定</div>
      </div>
    </van-dialog>
    <!-- 解绑对话框 E-->
  </div>
</template>
<script>
import { formatDate } from "@/utils/datetime";
import { mapState } from "vuex";
import mine from "@/store/mine/index";
export default {
  data() {
    return {
      photo: 'http://wwmimgs.oss-cn-shenzhen.aliyuncs.com/gps/2019-07-24/%E9%BB%98%E8%AE%A42.png',
      dialogShow: false,
      count: 0,
      isLoading: false,
      popShow: false,
      id: "", // id: 编辑/解绑
      queryData: {
        page: 1,
        pageSize: 5,
        equipmentStatus: ""
      }
    };
  },
  computed: {
    ...mapState({
      allLoaded: state => mine.state.allLoaded,
      isLoad: state => mine.state.isLoad,
      noData: state => mine.state.noData
    })
  },
  props: ["type", "list", "page", "totalPage"],
  filters: {
    formatTime(time) {
      if (time == null || time === "") {
        return "暂无更新时间";
      }
      let date = new Date(time);
      return "更新于 " + formatDate(date, "yyyy-MM-dd hh:mm:ss");
    }
  },
  methods: {
    // 轨迹
    goLocus(imei) {
      this.$router.push({ path: `/mine/devicelocus/${imei}` });
    },
    // 更多
    showMore(id) {
      this.id = id;
      this.popShow = true;
    },
    // 加载
    onLoad() {
      this.$store.commit("CHANGELOAFDSTATUS", { isLoad: true });
      this.queryData.page += 1;
      if (this.page < this.totalPage) {
        this.$emit("findPage", this.queryData);
      } else {
        return;
      }
    },
    // 编辑设备
    editDevice() {
      this.$router.push({ path: `/mine/deviceedit/${this.id}` });
    },
    // 设备定位
    goPosition(id, imei, status, locationTime) {
      this.$router.push({
        path: `/mine/deviceposition/${id}`,
        query: { imei: imei, status: status, locationTime: locationTime }
      });
    },
    // 设备设置
    goSet(id) {
      this.$router.push({ path: `/mine/deviceset/${id}` });
    },
    //解绑设备
    confirmOff() {
      this.$emit("offDivice", { id: this.id });
    },
    offDevice() {
      this.popShow = false;
      this.dialogShow = true;
    },
    // 下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        this.$store.commit("RESETPARAMS");
        this.$emit("findPage", this.queryData);
        this.$toast("刷新成功");
      }, 500);
    }
  }
};
</script>


<style lang="less" scoped>
html {
  background: #fff;
}
.load {
  text-align: center;
  margin-top: 10px;
  .van-loading.load-loading {
    margin: 0 auto;
  }
  .all-loaded {
    display: inline-block;
    font-size: 15px;
    padding: 5px 10px;
    color: #8a8888;
  }
  .all-loaded.border {
    // border: 1px solid #d3d3d3;
  }
}
.devices-wrap {
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  .devices-item {
    background: #fff;
    margin: 10px 13px 0px 13px;
    padding: 10px 10px 15px 10px;
    border-radius: 10px;
    box-shadow: 0px 1px 10px 0px rgba(51, 51, 51, 0.06);
    overflow: hidden;
    & > div:nth-of-type(1) {
      display: flex;
      padding: 0 0 12px 0;
      img {
        width: 50px;
        height: 60px;
        border-radius: 3px;
        overflow: hidden;
      }
      & div.devices-box {
        margin: 0 0 0 11px;
        width: calc(100% - 68px);
        & > div:nth-of-type(1) {
          display: flex;
          justify-content: space-between;
          .device-wrap {
            min-width: 41%;
            display: flex;
            justify-content: flex-end;
          }
          // 名字 电量 是否在线
          .devices-name {
            margin-top: 1px;
            padding: 1px 0;
            color: #232b38;
            font-size: 15px;
            font-weight: 500;
            /** 超出隐藏*/
            max-width: 57%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-top: 3px;
          }
          .devices-ele {
            font-size: 11px;
            color: #232b38;
          }
          .devices-sta {
            font-size: 11px;
            color: #232b38;
            margin: 0 0 0 12px;
            img {
              width: 9px;
              height: 9px;
              position: relative;
              top: -1px;
            }
          }
        }
        & > div:nth-of-type(2) {
          // 地址
          color: #878d97;
          font-size: 12px;
          margin: 7px 0 0 0;
          /* 超出隐藏 */
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        & > div:nth-of-type(3) {
          // 更新时间
          color: #878d97;
          font-size: 12px;
          margin: 7px 0 0 0;
        }
      }
    }
    // 定位 轨迹 设置 更多
    & > div:nth-of-type(2) {
      display: flex;
      color: #232b38;
      font-size: 13px;
      padding: 7px 0 0 0;
      border-top: 1px solid #e6e7ed;
      justify-content: space-around;
      & > div {
        box-sizing: border-box;
        width: 25%;
      }
      & > div span {
        display: inline-block;
        width: 21px;
        height: 21px;
        position: relative;
        top: 5px;
        margin: 0 6px 0 0;
      }
      & > div:nth-of-type(1) {
        // 定位
        & span {
          background: url(../../../assets/imgs/mine/site_sprite.png) no-repeat
            0px 0px;
          background-size: 48px 48px;
        }
      }
      & > div:nth-of-type(2) {
        // 轨迹
        margin-left: 5%;
        & span {
          background: url("../../../assets/imgs/mine/site_sprite.png") no-repeat
            0px -28px;
          background-size: 48px 48px;
        }
      }
      & > div:nth-of-type(3) {
        // 设置
        margin-left: 5%;
        & span {
          background: url("../../../assets/imgs/mine/site_sprite.png") no-repeat -27px -28px;
          background-size: 48px 48px;
        }
      }
      & > div:nth-of-type(4) {
        // 更多
        text-align: right;
        padding: 0 6px 0 0;
        & span {
          background: url("../../../assets/imgs/mine/site_sprite.png") no-repeat -27px
            0;
          background-size: 48px 48px;
        }
      }
    }
  }
}
// 底部弹出框
.pop-wrap {
  overflow: hidden;
  & > div {
    color: #000;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
  }
  & > div:nth-of-type(1) {
    padding: 29px 0 38px 0;
  }
  & > div:nth-of-type(2) {
    padding: 0 0 28px 0;
  }
  & > div:nth-of-type(3) {
    color: #2c92f9;
    margin: 0 13px;
    border-top: 1px solid #e6e7ed;
    padding: 18px 0;
  }
}
// 解绑弹出框
.off-dialog {
  border-radius: 10px;
  .off-wrap {
    text-align: center;
    span {
      display: inline-block;
      position: relative;
      top: 5px;
      left: -5px;
      background: url("../../../assets/imgs/mine/icons.png") no-repeat 0px 0px;
      background-size: 116px 91px;
      width: 24px;
      height: 24px;
    }
    & > div:nth-of-type(1) {
      font-weight: 400;
      font-size: 18px;
      color: #000;
      margin: 39px 0 29px 0;
    }
    & > div:nth-of-type(2) {
      font-size: 14px;
      color: #ff432f;
      margin: 0 0 40px 0;
    }
  }
}
// 电池容量
.battery {
  position: relative;
  display: inline-block;
  height: 8px;
  width: 15px;
  // background: #1cbb67;
  border-radius: 7px;
  box-sizing: border-box;
}

.battery:before {
  content: "";
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  border: 1px solid #1cbb67;
  box-sizing: border-box;
}
.battery.red:before {
  border: 1px solid red;
}
.power {
  background-color: white;
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  border-radius: 5px;
  /* 电量的范围 */
  -webkit-transform: scaleX(0.5);
  transform: scaleX(0.5);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  background: #1cbb67;
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.28, 0.95);
  transition: -webkit-transform 1s cubic-bezier(0, 0, 0.28, 0.95);
  transition: transform 1s cubic-bezier(0, 0, 0.28, 0.95);
  transition: transform 1s cubic-bezier(0, 0, 0.28, 0.95),
    -webkit-transform 1s cubic-bezier(0, 0, 0.28, 0.95);
  box-sizing: border-box;
}
.power.red {
  background: red;
}
.re-load.van-pull-refresh {
  width: 100%;
  min-height: 800px;
}
</style>


