import * as types from './types'
import http from '@/utils/http'
import router from '@/router/index'
import Cookies from 'js-cookie' // D:\wenwenmao\xiaongka\H5\gps-h5\src\router\index.js
// 轻提示
import { Toast } from 'vant'
let state = {
  count: 0,
  data: [],
  vertifycodecheckdata: {}, // 短信模块-短信校验码校验
  userchangePwddata: {}, // GPS项目-用户修改密码
  memberlogindata: {}, // 用户查询
  vertifycodeSenddata: {} // 短信模块-发送短信校验码
}

let actions = {
  [types.VERTIFYCODECHECK] (context, params) { // 短信模块-短信校验码校验
    console.log(this)
    // state.vertifycodecheckdata
    http.post('/m-sms-vertifycode-check', params).then(data => {
      context.commit(types.VERTIFYCODECHECK, data.data)
      if (data.code == 'ok') {
        context.commit(types.VERTIFYCODECHECK, data.data)
        router.push('/SetPwd')
      } else {
        Toast.fail(data.message)
      }
    })
  },
  [types.USERCHANGEPWD] (context, params) { // GPS项目-用户修改密码
    http.post('/p-gps-user-phoneChangePwd', params).then(data => {
      context.commit(types.USERCHANGEPWD, data)
      if (data.code == 'ok') {
        Toast('密码设置完成')
        router.push('/login')
      } else {
        Toast.fail(this.userchangePwddata.message)
      }
    })
  },
  [types.MEMBERLOGIN] (context, params) { // denglu
    console.log(this)
    Cookies.set('gps_xtoken_teacher', '')
    this.state.login.memberlogindata = {}
    http.post('/p-gps-member-login', params).then(data => {
      if (data.code == 'ok') {
        context.commit(types.MEMBERLOGIN, data.data)
        // localStorage.setItem('phone', data.params.account)
        localStorage.setItem('phone', params.account)
        // console.log(router)
        router.push({ path: '/' })
      } else {
        Toast.fail('账号或密码输入错误')
      }
    })
  },
  [types.VERTIFYCODESEND] (context, params) { // 常见问题
    http.post('/m-sms-vertifycode-send', params).then(data => {
      if (data.code == 'ok') {
        context.commit(types.VERTIFYCODESEND, data.data)
      } else {
        Toast.fail(data.message)
      }
    })
  }
}

let mutations = {
  [types.VERTIFYCODECHECK] (state, data) {
    state.vertifycodecheckdata = data
  },
  [types.USERCHANGEPWD] (state, data) {
    console.log('data', data)
    state.userchangePwddata = data
  },
  [types.MEMBERLOGIN] (state, data) {
    console.log('update', data)
    state.memberlogindata = data
    // console.log(data.params)
    Cookies.set('gps_xtoken_teacher', data.xtoken)
  },
  // 设备
  [types.VERTIFYCODESEND] (state, data) {
    console.log('设备列表', data)
    state.vertifycodeSenddata = data
  },
  // 传自己的值
  MYDATA () { }
}
// getter setter

export default { state, actions, mutations }
