/**
 * 全局常量、方法封装模块
 * 通过原型挂载到Vue属性
 * 通过 this.Global 调用
 */

// 后台管理系统服务器地址
export const baseUrl = '/api/'
//  export const baseUrl = 'https://pms.wenwenmao.com/api/'
//  export const baseprintUrl = 'https://pms.wenwenmao.com/api/'
export const baseprintUrl = 'http://192.168.124.231:8601'
// 系统数据备份还原服务器地址
// export const backupBaseUrl = 'http://localhost:8002'
export const bkey = 'w0xIuTu7ZCymWOc8U9Sma0pXiWFFK1Wc' // 百度地图秘钥
// websoket 地址
export const websoketUrl = 'ws://192.168.124.231:8704/websocketPrint/'
export default {
  baseUrl,
  bkey,
  websoketUrl
  // backupBaseUrl
}
