 <!-- 设备设置 -->
<template>
  <div class="wrap">
    <van-nav-bar
      title="设备设置"
      left-text
      right-text="保存"
      left-arrow
      @click-right="save"
      :border="false"
      @click-left="goBack"
    />
    <div class="set-title">监护设置</div>
      <div class="ele-box">
        <van-cell-group>
          <van-field   maxlength="11"  type="tel" v-for="(item,index) in PhoneArray" :key="index" v-model="item.phone" :label="item.name" placeholder="请输入电话号码" />
      </van-cell-group>
      <div></div>
    </div>
    <div class="set-title"><a href="https://wap.fj.10086.cn/servicecb/groupUserVerify/index.jsp">实名认证</a></div>
    <div class="set-title">省电设置</div>
    <div class="ele-box">
      <!-- 自动定位时间 -->
      <div class="ele-item">
        <div class="ele-title">
          <div>自动定位时间</div>
          <div>
            <span>
              <span v-for="(i, key) in atuoPositionTime" :key="key">
                <span
                  v-if="equipInfo.locationTime == i.dictValue"
                >{{i.dictLaber.slice(0, i.dictLaber.indexOf("（"))}}</span>
              </span>
            </span>
            <span class="enter" @click="selectTime"></span>
          </div>
        </div>
        <div class="ele-content">提示 ：设置的时间越短，位置更新越快，轨迹越连贯，同时电量和流量消耗越快，建议不同的需求设置不同的时间。</div>
      </div>
      <!-- 休眠时间 -->
      <!-- <div class="ele-item">
        <div class="ele-title">
          <div>休眠时间</div>
          <div>
            <span
              v-if="equipInfo.dormancyStartTime && equipInfo.dormancyEndTime"
            >{{equipInfo.dormancyStartTime}} - {{equipInfo.dormancyEndTime}}</span>
            <span
              class="enter"
              @click="$router.push('/mine/sleeptime?dormancyStatus=' + equipInfo.dormancyStatus)"
            ></span>
          </div>
        </div>
        <div class="ele-content">提示 ：选择的时间段为休眠状态，在此时间段内设备将停止自动定位数据上传，但手动定位依然能正常获取到位置。</div>
      </div> -->
      <!-- 深度睡眠 -->
      <div class="ele-item">
        <div class="ele-title pr">
          <div>深度睡眠</div>
          <div>
            <van-switch class="resize" v-model="equipInfo.sleepStatus"></van-switch>
          </div>
        </div>
        <div class="ele-content border-none">提示：此模式会使设备断开网络连接，通过短信关闭。</div>
      </div>
      <div></div>
    </div>
    <div class="set-title">报警设置</div>
    <div class="set-box">
      <div>
        <div class="set-box-title">低电报警</div>
        <!-- <div class="set-notify-type">
          <input type="checkbox" v-model="equipInfo.powerWarnMode" value="1" name="proMode" />
          <span>公众号通知</span>
        </div>  -->
        <div class="set-notify-type app-type">
          <input type="checkbox" v-model="equipInfo.powerWarnMode" value="2" name="proMode" />
          <span>APP通知</span>
        </div>
        <van-switch class="resize" v-model="equipInfo.lowElectricWarnStatus"></van-switch>
      </div>
      <div>
        <div class="set-box-title">围栏报警</div>
        <!-- <div class="set-notify-type">
          <input type="checkbox" v-model="equipInfo.enclosureWarnMode" value="1" name="encMode" />
          <span>公众号通知</span>
        </div> -->
        <div class="set-notify-type app-type">
          <input type="checkbox" v-model="equipInfo.enclosureWarnMode" value="2" name="encMode" />
          <span>APP通知</span>
        </div>
        <van-switch class="resize" v-model="equipInfo.enclosureWarnStatus"></van-switch>
      </div>
    </div>
    <div class="set-title">其他设置</div>
    <van-cell title="设备灯开关" class="fence-wrap">
      <template slot="right-icon">
        <div>
          <van-switch class="resize" v-model="equipInfo.equipmentLamp"></van-switch>
        </div>
      </template>
    </van-cell>
    <div class="set-title">高级设置</div>
    <van-cell is-link @click="modelPop = true">
      <div slot="title">
        <div>
          高级设置
          <div class="use-model">
            <span v-for="(i, key) in sltModel" :key="key" class="o-model" v-if="i.status == 1">
              <span class="i-model">
                {{i.text}}
                <span>、</span>
              </span>
            </span>
          </div>
        </div>
      </div>
    </van-cell>
    <!-- 选择自动定位时间 弹出层 S -->
    <van-popup v-model="timePop" position="bottom" :overlay="true" class="pop">
      <div class="pop-wrap">
        <div>
          <van-picker :columns="columns" :default-index="equipInfo.locationTime - 1" @change="onTimeChange" ref="refPicker"/>
        </div>
        <div>
          <div @click="timeChangeCancel">取消</div>
          <div style="color:#2C92F9" @click="timeChangeComfirm">确认</div>
        </div>
      </div>
    </van-popup>
    <!-- 选择自动定位时间  弹出层 S -->

    <!-- 选择定位模式 弹出层 S -->
    <van-popup v-model="modelPop" position="bottom" :overlay="true" class="pop">
      <div class="pop-wrap-model">
        <div>
          <div
            v-for="(item, index) in locationModel"
            :key="index"
            class="model-item"
            @click="selectModel(item)"
            :class="item.checked ? 'active' : ''"
          >
            <div>
              <van-icon name="arrow" color="#2C92F9" class="icon-arrow" />
              <div>{{item.dictLaber}}</div>
              <div class="note">{{item.note}}</div>
            </div>
          </div>
        </div>
        <div>
          <div @click="setModel">确定</div>
        </div>
      </div>
    </van-popup>
    <!-- 选择定位模式 弹出层 S -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import mine from "@/store/mine/index";
import {
  DROPDOWNOPTION,
  EQUIPMENTDETAIL,
  EQUIPMENTSETUPUPDATE
} from "@/store/mine/types";
export default {
  data() {
    return {
      PhoneArray:[{
        phone:'',
        name:'号码一',
      },{
        phone:'',
        name:'号码二',
      },{
        phone:'',
        name:'号码三',
      }],
      timePop: false, // 时间选择 弹出框标记
      modelPop: false, // 定位模式 弹出框标记
      columns: [], // 自动定位时间
      sTime: "",
      queryData: {
        id: "",
        locationTime: "",
        dormancyStartTime: "",
        dormancyEndTime: "",
        dormancyStatus: "", //	休眠状态（1-开启，2-关闭）
        lowElectricWarnStatus: "", //	低电报警状态（1-开启，2-关闭）
        enclosureWarnStatus: "", //	围栏报警状态（1-开启，2-关闭）
        equipmentLamp: "", //	设备灯开关（1-开启，2-关闭）
        sleepStatus: "", //	睡眠状态（1-开启，2-关闭）
        powerWarnMode: "", //	低电报警方式（0不选,1公众号通知，2APP通知, 3全选）
        enclosureWarnMode: "", //	围栏报警方式（0不选,1公众号通知，2APP通知, 3全选）
        locationModeGPS: "", //	GPS定位模式（1-开启，2-关闭）
        locationModeWIFI: "", //	WIFI定位模式（1-开启，2-关闭）
        locationModeLBS: "", //	LBS定位模式（1-开启，2-关闭）
        sosPhone:'',
      }
    };
  },
  computed: {
    ...mapState({
      atuoPositionTime: state => mine.state.atuoPositionTime,
      locationModel: state => mine.state.locationModel,
      equipInfo: state => mine.state.equipInfo,
      sltModel: state => mine.state.sltModel,
      isSet: state => mine.state.isSet
    })
  },
  created() {
    this.queryData.id = this.$route.params.id;
    this.$store.commit("SETID", { id: this.queryData.id });
    this.$store.dispatch(DROPDOWNOPTION);
  },
  mounted() {
    if (!this.isSet) {
      this.$store.dispatch(EQUIPMENTDETAIL, { id: this.queryData.id });
    } else {
      // isSet 为true 不再重新请求数据
    }
  },
  watch: {
     "equipInfo.sosPhone": {
      handler: function() {
        if (this.equipInfo.sosPhone) {
          let phoneList=this.equipInfo.sosPhone.split(',') || [];
          this.PhoneArray=[{
            phone:phoneList[0] || '',
            name:'号码一',
          },{
           phone:phoneList[1] || '',
            name:'号码二',
          },{
           phone:phoneList[2] || '',
            name:'号码三',
          }]
        }
      },
      deep: true
    },
    "equipInfo.enclosureWarnStatus": {
      handler: function() {
        if (this.equipInfo.enclosureWarnStatus == false) {
          this.equipInfo.enclosureWarnMode = [];
        }
      },
      deep: true
    },
    "equipInfo.lowElectricWarnStatus": {
      handler: function() {
        if (this.equipInfo.lowElectricWarnStatus == false) {
          this.equipInfo.powerWarnMode = [];
        }
      },
      deep: true
    },
    "equipInfo.enclosureWarnMode": {
      handler: function() {
        if (this.equipInfo.enclosureWarnMode.length >= 1) {
          this.equipInfo.enclosureWarnStatus = true;
        } else {
          this.equipInfo.enclosureWarnStatus = false;
        }
      },
      deep: true
    },
    "equipInfo.powerWarnMode": {
      handler: function() {
        if (this.equipInfo.powerWarnMode.length >= 1) {
          this.equipInfo.lowElectricWarnStatus = true;
        } else {
          this.equipInfo.lowElectricWarnStatus = false;
        }
      },
      deep: true
    }
  },
  methods: {
    // 返回上一页
    goBack() {
      this.$store.commit("SETGET");
      this.$router.go(-1);
    },
    // 保存设置
    save() {
      let phone=this.PhoneArray.filter(item=>item.phone).map(item=>item.phone).join(',')
      this.$store.dispatch(EQUIPMENTSETUPUPDATE, {
        id: this.queryData.id,
        locationTime:
          this.queryData.locationTime || this.equipInfo.locationTime,
        dormancyStartTime: this.equipInfo.dormancyStartTime + ":00",
        dormancyEndTime: this.equipInfo.dormancyEndTime + ":00",
        dormancyStatus: this.formatStatus(this.equipInfo.dormancyStatus),
        lowElectricWarnStatus: this.formatStatus(
          this.equipInfo.lowElectricWarnStatus
        ),
        enclosureWarnStatus: this.formatStatus(
          this.equipInfo.enclosureWarnStatus
        ),
        equipmentLamp: this.formatStatus(this.equipInfo.equipmentLamp),
        sleepStatus: this.formatStatus(this.equipInfo.sleepStatus),
        powerWarnMode: this.remindWay(this.equipInfo.powerWarnMode),
        enclosureWarnMode: this.remindWay(this.equipInfo.enclosureWarnMode),
        locationModeGPS: this.sltModel[0].status,
        locationModeWIFI: this.sltModel[1].status,
        locationModeLBS: this.sltModel[2].status,
        sosPhone:phone
      });
    },
    // 格式化开关状态
    formatStatus(status) {
      if (status == true) {
        return 1;
      } else {
        return 2;
      }
    },
    // 通知方式
    remindWay(arr) {
      if (arr.length == 0) {
        // 不选
        return 0;
      } else if (arr.length == 1) {
        // 选择一种
        return arr[0];
      } else if (arr.length == 2) {
        // 全选
        return 3;
      }
    },
    selectTime() {
      this.timePop = true;
      this.columns = this.atuoPositionTime;
    },
    // 选择定位时间
    onTimeChange(picker, value, index) {
      this.sTime = value.dictValue;
    },
    timeChangeCancel() {
      this.timePop = false;
      setTimeout(()=> {
        this.$refs.refPicker.setIndexes([String(this.equipInfo.locationTime - 1)])
      }, 800)
    },
    timeChangeComfirm() {
      this.sTime = this.$refs.refPicker.getValues()[0].dictValue;
      this.queryData.locationTime = this.sTime;
      this.equipInfo.locationTime = this.sTime;
      this.$store.commit("SETAUTOTIME", {
        val: this.sTime,
        text: this.equipInfo.locationTime
      });
      this.timePop = false;
    },
    // 选择 定位模式
    selectModel(item) {
      this.$set(item, "checked", !item.checked);
    },
    setModel() {
      let arr = [];
      this.locationModel.forEach((item, index) => {
        // 点击选择的 定位模式value
        if (item.hasOwnProperty("checked")) {
          if (item.checked == true) {
            arr.push(item.dictValue);
          }
        }
      });
      this.$store.commit("SELETEMODEL", arr);
      this.modelPop = false;
    }
  }
};
</script>


<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;
  padding: 0 0 5px 0;
  background-color: #eff0f5;
  .set-title {
    color: #232b38;
    font-size: 18px;
    font-weight: bold;
    margin: 20px 0 14px 13px;
  }
  .use-model {
    float: right;
    color: #878d97;
    font-size: 15px;
    & .o-model:nth-last-of-type(1) {
      // 去除高级设置的 选项的最后一个顿号
      .i-model span {
        display: none;
        color: red;
      }
    }
  }
  .ele-box {
    padding: 0 13px;
    background: #fff;
    .ele-item {
      .ele-title.pr {
        padding: 21px 0 8px 0;
      }
      .ele-title {
        display: flex;
        justify-content: space-between;
        color: #232b38;
        font-size: 15px;
        padding: 21px 0 18px 0;
        span {
          color: #878d97;
        }
        .enter {
          display: inline-block;
          width: 9px;
          height: 15px;
          background: url("../../../assets/imgs/mine/icons.png") no-repeat -75px -37px;
          background-size: 116px 91px;
          position: relative;
          top: 1px;
          margin: 0 2px 0 12px;
        }
      }
      .ele-content {
        color: #878d97;
        font-size: 13px;
        padding-bottom: 18px;
        line-height: 18px;
        text-align: justify;
        border-bottom: 1px solid #e6e7ed;
      }
      .border-none {
        border-bottom: 0;
      }
    }
  }
  .set-box {
    background: #fff;
    padding: 0 13px;
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;
      .set-box-title {
        font-size: 15px;
        color: #232b38;
        font-weight: 400;
        padding: 21px 0 20px 0;
      }
      .set-notify-type {
        margin: 0 0 0 23px;
      }
      .set-notify-type.app-type {
        margin-right: 130px;
      }
    }
  }
  // 弹出框
  .pop-wrap {
    overflow: hidden;
    & > div {
      color: #000;
      font-size: 15px;
      font-weight: 400;
      text-align: center;
    }
    // 年级、班级列表
    & > div:nth-of-type(1) {
      padding: 29px 0 38px 0;
    }
    // 确认、取消
    & > div:nth-of-type(2) {
      display: flex;
      margin: 0 13px;
      border-top: 1px solid #e6e7ed;
      padding: 18px 0;
      justify-content: space-around;
    }
  }
  .pop-wrap-model {
    overflow: hidden;
    & > div {
      color: #000;
      font-size: 15px;
      font-weight: 400;
      text-align: left;
      padding: 0 13px;
    }
    .model-item {
      border-bottom: 1px solid #e6e7ed;
      padding: 25px 0 25px 0;
      font-size: 15px;
      font-weight: 500;
      position: relative;
      & > div {
        margin: 0 20px;
      }
      .icon-arrow {
        position: absolute;
        left: 0;
        display: none;
      }
    }
    .model-item.active {
      color: #2c92f9;
      .icon-arrow {
        display: block;
      }
    }
    // 确认、取消
    & > div:nth-of-type(2) {
      display: flex;
      margin: 0 13px;
      padding: 18px 0;
      justify-content: space-around;
    }
    .note {
      margin-top: 6px;
      font-size: 12px;
    }
  }
}
input[type="checkbox"] {
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
  width: 13px;
  height: 13px;
  -webkit-appearance: none;
  border: 2px solid #878d97;
  border-radius: 3px;
  outline: 0 !important;
  position: relative;
  top: -2px;
}
input[type="checkbox"]:checked {
  background: #2c92f9;
  border: 1px solid transparent;
}
</style>

