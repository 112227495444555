<template>
  <div class="wrap">
    <van-nav-bar
      :title="date + '(' + beginTime + '-' + endTime + ')'"
      left-text
      left-arrow
      :border="false"
      @click-left="goBack"
    />
    <inlineCalendar
      mode="single"
      @switch="handleSwitch"
      class="box"
      ref="calendar"
      @change="onChange"
    />
    <!-- {{beginTime}} {{endTime}} -->
    <van-cell-group>
      <van-cell title="开始时间" :value="begin || beginTime" is-link @click="showBeginFlag" />
      <van-cell title="结束时间" :value="end || endTime" is-link @click="showEndFlag" />
    </van-cell-group>
    <!-- 查询 S -->
    <div class="query common-button" :class="loading? 'in-loadimg': ''">
      <van-loading type="spinner" class="load-loading" v-if="loading" />
      <span @click="query">查询</span>
    </div>
    <!-- 查询 E -->
    <van-popup v-model="beginFlag" position="bottom" @close="onClose">
      <van-datetime-picker
        v-model="useTimeBegin"
        type="time"
        title="开始时间"
        @confirm="onStartConfirm"
        @cancel="onStartCancel"
        @change="changeStart"
      />
    </van-popup>
    <van-popup v-model="endFlag" position="bottom" @close="onClose">
      <van-datetime-picker
        v-model="useTimeEnd"
        type="time"
        title="结束时间"
        @confirm="onEndConfirm"
        @cancel="onEndCancel"
        @change="changeEnd"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import { mapState } from "vuex";
import { formatWithSeperatorOnday } from "@/utils/datetime";
import mine from "@/store/mine/index";
import { setTimeout } from "timers";
export default {
  data() {
    return {
      imei: "",
      year: undefined,
      month: undefined,
      date: "",
      begin: "",
      end: "",
      beginFlag: false,
      endFlag: false,
      useTimeBegin: "",
      useTimeEnd: "",
      loading: false
    };
  },
  computed: {
    ...mapState({
      beginTime: state => mine.state.beginTime,
      endTime: state => mine.state.endTime
    })
  },
  watch: {
    beginTime: {
      handler: function() {
        this.useTimeBegin = this.beginTime;
      },
      deep: true
    },
    endTime: {
      handler: function() {
        this.useTimeEnd = this.endTime;
      },
      deep: true
    }
  },
  created() {
    this.useTimeBegin = this.beginTime;
    this.useTimeEnd = this.endTime;
  },
  mounted() {
    this.imei = this.$route.query.imei;
    this.date = this.format();
    this.handleSwitch(this.$refs.calendar.showDate);
  },
  methods: {
    handleSwitch(date) {
      this.year = date.year;
      this.month = date.month;
    },
    onClose() {
      // 关闭弹出层
    },
    onStartCancel() {
      this.beginFlag = false;
      this.useTimeBegin = this.begin || this.beginTime;
    },
    onEndCancel() {
      this.endFlag = false;
      this.useTimeEnd = this.end || this.endTime;
    },
    changeStart(e) {},
    changeEnd(e) {},
    onStartConfirm() {
      if (this.useTimeBegin == "") {
        // 没有选择数值
        this.begin = "00:00";
      } else {
        this.begin = this.useTimeBegin;
      }
      // 设置结束时间大于开始时间
      this.beginFlag = false;
    },
    onEndConfirm() {
      if (this.useTimeEnd == "") {
        this.end = "00:00";
      } else {
        this.end = this.useTimeEnd;
      }
      this.endFlag = false;
    },
    showBeginFlag() {
      this.beginFlag = true;
    },
    showEndFlag() {
      this.endFlag = true;
    },
    // 返回上一页
    goBack() {
      this.$router.go(-1);
    },
    onChange(date) {
      this.date = this.format(date.$d.getTime());
    },
    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    format(timeStamp) {
      if (timeStamp) {
        var time = new Date(timeStamp);
      } else {
        var time = new Date();
      }
      var y = time.getFullYear(); // 年
      var m = time.getMonth() + 1; // 月
      var d = time.getDate(); // 日
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return y + "-" + this.add0(m) + "-" + this.add0(d);
    },
    valid() {
      this.begin = this.begin || this.beginTime;
      this.end = this.end || this.endTime;
      let bHour = Number(this.begin.split(":")[0]);
      let bMin = Number(this.begin.split(":")[1]);
      let eHour = Number(this.end.split(":")[0]);
      let eMin = Number(this.end.split(":")[1]);
      console.log(
        "3",
        this.beginTime,
        this.endTime,
        "2",
        this.begin,
        this.end,
        "3",
        bHour,
        eHour
      );
      if (bHour > eHour) {
        Toast("开始时间不能大于结束时间");
        return;
      } else {
        if (bMin > eMin) {
          Toast("开始时间不能大于结束时间");
          return;
        }
      }
      // 选定 日期和时间
      this.$store.commit("LOCSET", {
        date: this.date,
        begin: this.begin,
        end: this.end
      });
      setTimeout(() => {
        this.loading = false;
        this.imei
          ? this.$router.push({ path: `/mine/devicelocus/${this.imei}` })
          : this.$router.push({ path: `/guiji` });
      }, 1000);
    },
    // 查询
    query() {
      this.loading = true;
      if (this.beginTime) {
        if (this.endTime) {
          this.valid();
        } else {
          Toast("请选择结束时间");
        }
      } else {
        Toast("请选择开始时间");
      }
    }
  }
};
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;
  background: #fff;
  position: fixed;
  .common-button.query {
    margin-top: 33px;
  }
  .query.in-loadimg {
    // transition: all .6s;
    opacity: 0.7;
  }
  .common-button.in-loadimg {
    // transition: all .6s;
    padding: 14px 0;
  }
  padding-bottom: 60px;
}
.load-loading {
  color: #2d3e4b;
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  top: -2px;
}
</style>
