
<template>
  <div class="wrap">
    <van-nav-bar title="设备定位" left-text left-arrow :border="false" @click-left="goBack" />
    <!-- 头部 S -->
    <div class="search-wrap">
      <div class="van-search__content">
        <div class="van-cell__value van-cell__value--alone">
          <div class="van-field__body">
            <img :src="lEquipInfo.equipmentPic || photo" class="equip-img" />
            <span class="title-text">{{lEquipInfo.equipmentName}}</span>
            <img @click="switchEquip" class="title-img" src="../../../assets/imgs/xiala@2x.png" />
            <img class="add-img" @click="addEquip" src="../../../assets/imgs/tianjia@2x.png" />
          </div>
        </div>
      </div>
    </div>
    <!-- 暂无设备定位信息 S -->
    <!-- <div v-if="noPositionPoint" class="no-data">
      <img src="../../../assets/imgs/icon_kong.png" />
      <div>暂无设备定位信息</div>
    </div>-->
    <!-- 暂无设备定位信息 E -->

    <!-- 头部 E -->
    <!--  v-show="noPositionPoint == false"  -->
    <div id="mapPanel" class="my-map">
      <div class="map-loading-center">
        <van-loading type="spinner" color="#1989fa" style="margin: 0 auto" />
      </div>
    </div>

    <span class="text">{{lEquipInfo.equipmentName}} {{site}}</span>
    <div>
      <!-- 书包 手机 -->
      <div class="card-and-phone" :class="infoShow? 'up': ''">
        <div class="card" @click="switchLocation('card')">
          <span class="bg c card-position" :class="cMode? 'active':''"></span>
          <p>书包</p>
        </div>
        <div class="phone" @click="switchLocation('phone')">
          <span class="bg p phone-position" :class="pMode?'active':''"></span>
          <p>手机</p>
        </div>
      </div>
      <!-- 查看街景 -->
      <div class="view-scene" @click="viewScene" :class="infoShow? 'up': ''">
        <img v-if="sceneShow" src="../../../assets/imgs/icon_huanjign@2x.png" />
      </div>
      <!-- 向下的按钮 -->
      <div class="bottom-button" @click="showPop">
        <img v-if="infoShow == true" src="../../../assets/imgs/mine/icon_hebing.png" />
        <img v-else src="../../../assets/imgs/mine/icon_shangla.png" />
      </div>
      <!-- 弹出框 -->
      <van-popup v-model="infoShow" position="bottom" :overlay="false" class="pop">
        <span class="top-header" @click=" infoShow = false ">
          <img src="../../../assets/imgs/mine/icon_hebing.png" />
        </span>
        <div class="pop-wrap">
          <div class="e-info">
            <div>{{site}}</div>
            <div>
              {{updateTime | formatTime}}
              <i>{{status}}</i>
            </div>
            <div>
              <span>速度：{{speed}} km/h</span>
              <span class="gap">
                定位模式：
                <span v-if="type == 1">GPS</span>
                <span v-if="type == 2">WIFI</span>
                <span v-if="type == 3">LBS</span>
                <span v-if="type == ''">暂无信息</span>
              </span>
            </div>
            <div>
              <span class="f">经度：{{lng | formatLng}}</span>
              <span>纬度：{{lat | formatLat}}</span>
            </div>
          </div>
          <!-- 导航图标 -->
          <div class="e-img">
            <img src="../../../assets/imgs/icon_daohang@2x.png" @click="goNar" />
            <div>导 航</div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { loadingMap } from "@/utils/load";
import { LOCLAST } from "@/store/mine/types";
import { Dialog, Toast } from "vant";
import http from "@/utils/http";
import { formatDate } from "@/utils/datetime";
import mine from "@/store/mine/index";
import { mapState } from "vuex";

import { setTimeout } from "timers";

export default {
  data() {
    return {
      photo: 'http://wwmimgs.oss-cn-shenzhen.aliyuncs.com/gps/2019-07-24/%E9%BB%98%E8%AE%A42.png',
      cMode: true,
      pMode: false,
      sceneShow: true,
      map: null, // 地图实例
      geoLocation: null,
      mk: null, // 设备定位当前标记
      locationMk: null, // 手机定位当前标记
      myIcon1: null,
      myIcon2: null,
      infoShow: false, // 详情弹框
      status: "",
      myData: "",
      locIndex: null // 最新轨迹点 定时器
    };
  },
  filters: {
    formatTime(time) {
      if (time == null || time === "") {
        return "暂无更新时间";
      }
      let date = new Date(time);
      return '更新于 ' + formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    formatLat(lat) {
      if (lat == null || lat === "") {
        return "暂无信息";
      }
      var index =lat.toString().indexOf("\.");
      lat = lat.toString().substring(0,index + 5);
      return lat;
    },
    formatLng(lng) {
      if (lng == null || lng === "") {
        return "暂无信息";
      }
      var index =lng.toString().indexOf("\.");
      lng = lng.toString().substring(0,index + 5);
      return lng;
    }
  },
  computed: {
    ...mapState({
      lEquipInfo: state => mine.state.lEquipInfo,
      lat: state => mine.state.lat,
      lng: state => mine.state.lng,
      type: state => mine.state.type,
      site: state => mine.state.site,
      updateTime: state => mine.state.updateTime,
      speed: state => mine.state.speed,
      noPositionPoint: state => mine.state.noPositionPoint
    })
  },
  beforeDestroy() {
    // 清除定时器
    clearInterval(this.locIndex);
  },
  watch: {
    lat: {
      handler() {
        this.initMap();
      },
      deep: true
    }
  },
  mounted() {
    this.start();
    let locationTime = this.$route.query.locationTime * 1000; // 刷新设备定位的时间（毫秒
    this.locIndex = setInterval(() => {
      this.$store.dispatch(LOCLAST, { IMEI: this.$route.query.imei });
    }, locationTime);
  },
  methods: {
    // 选择切换 其他设备
    switchEquip() {
      this.$router.push("/Equmentchios");
    },
    // 添加设备
    addEquip() {
      this.$router.push("/AddEqu");
    },
    start() {
      this.status = this.$route.query.status;
      this.$store.commit("SETID", { id: this.$route.params.id });
      this.$store
        .dispatch(LOCLAST, { IMEI: this.$route.query.imei })
        .then(() => {
          setTimeout(() => {
            this.initMap();
          }, 100);
        });
    },
    viewScene() {
      if (this.lng && this.lat) {
        let obj = {
          lng: this.lng,
          lat: this.lat,
        }
        sessionStorage.setItem('scene', obj)
        this.$router.push({
          path: `/mine/devicepanorama/${this.lng}-${this.lat}`
        });
      } else {
        Toast("暂无设备所在位置全景信息");
      }
    },
    // 打开弹框
    showPop() {
      this.infoShow = true;
    },
    // 切换定位
    switchLocation(str) {
      if (str == "card") {
        // 设备的定位
        this.cMode = true;
        this.pMode = false;
        if (this.lng && this.lat) {
          this.map.panTo(this.mk.getPosition());
        } else {
          Toast("没有设备最新定位");
        }
      } else {
        // 当前的定位
        this.pMode = true;
        this.cMode = false;
        this.getPos();
      }
    },
    getPos() {
      // 获取当前位置
      let geoL = this.geoLocation;
      let that = this;
      geoL.getCurrentPosition(r => {
        // console.log('now-location', r)
        if (geoL.getStatus() == BMAP_STATUS_SUCCESS) {
          that.locationMk.setPosition(r.point);
          that.map.addOverlay(that.locationMk);
          that.map.panTo(r.point);
        } else {
        }
      });
    },
    initMap() {
      this.map = new BMap.Map("mapPanel");
      // 初始化位置
      this.map.centerAndZoom(new BMap.Point(this.lng, this.lat), 16);
      this.map.addEventListener("dragend", () => {});
      this.geoLocation = new window.BMap.Geolocation();
      this.myIcon1 = new BMap.Icon(
        "http://wwmimgs.oss-cn-shenzhen.aliyuncs.com/gps/2019-06-30/card_site.png",
        new BMap.Size(32, 32)
      );
      this.myIcon2 = new BMap.Icon(
        "http://wwmimgs.oss-cn-shenzhen.aliyuncs.com/gps/2019-06-30/phone_site.png",
        new BMap.Size(32, 32)
      );
      if (this.lng && this.lat) {
        this.mk = new BMap.Marker(new BMap.Point(this.lng, this.lat));
        this.mk.setIcon(this.myIcon1);
        this.map.addOverlay(this.mk);
      } else {
        this.switchLocation("phone");
      }
      this.locationMk = new BMap.Marker(this.lng, this.lat);
      this.locationMk.setIcon(this.myIcon2);
      // 地图、卫星图
      var mapType = new BMap.MapTypeControl({
        mapTypes: [BMAP_NORMAL_MAP, BMAP_SATELLITE_MAP], // 普通街道和卫星视图
        anchor: BMAP_ANCHOR_TOP_RIGHT
      });
      this.map.addControl(mapType);
      this.map.panTo(this.lng, this.lat);
    },
    // 返回上一页
    goBack() {
      this.$store.commit("SETPOSITIONPOINT", false);
      this.$router.go(-1);
    },
    // 导航 调起移动端百度地图
    goNar() {
      window.location.href = "http://map.baidu.com/mobile/";
    }
  }
};
</script>

<style lang="less" scoped>
#mapPanel {
  min-height: 300px;
  height: 100%;
  width: 100%;
  position: absolute !important;
  background-color: rgba(76, 76, 76, 0.7686274509803922);
}
.text {
  color: transparent;
}
// 全景
.view-scene {
  transition: all 0.6s; // 过渡动画
  text-align: center;
  position: absolute;
  bottom: 58px;
  z-index: 100;
  left: 13px;
  border-radius: 8px;
  img {
    width: 48px;
    height: 48px;
  }
}
.view-scene.up {
  transition: all 0.6s; // 过渡动画
  bottom: 120px;
}
// 手机和书包
.card-and-phone {
  transition: all 0.6s;
  text-align: center;
  box-sizing: border-box;
  background: #fff;
  border-radius: 10px;
  position: absolute;
  bottom: 58px;
  z-index: 100;
  right: 13px;
  padding: 9px;
  .bg {
    background: url("../../../assets/imgs/localtion_mode.png") no-repeat;
    background-size: 58px 50px;
  }
  .c {
    display: inline-block;
    width: 24px;
    height: 18px;
  }
  .card-position.active {
    background-position: -34px 0px;
  }
  .card-position {
    background-position: 0px 0px;
  }
  .phone-position {
    display: inline-block;
    width: 17px;
    height: 22px;
    background-position: -27px -28px;
  }
  .phone-position.active {
    background-position: 0px -28px;
  }
  .card-position.active + p {
    color: #2f94f9;
  }
  .phone-position.active + p {
    color: #ff422f;
  }
  .card {
    border-bottom: 1px solid #e6e7ed;
    padding-bottom: 12px;
  }
  .phone {
    padding-top: 7px;
  }
  p {
    color: #232b38;
    font-size: 11px;
    padding: 3px 0;
  }
}
.card-and-phone.up {
  transition: all 0.6s;
  bottom: 128px;
}
.search-wrap .van-search__content {
  background: #fff;
}
.search-wrap {
  overflow: hidden;
  text-align: center;
  position: absolute;
  width: 92%;
  top: 58px;
  z-index: 100;
  left: 4%;
  box-sizing: border-box;
  background: #fff;
  border-radius: 8px;
  padding: 5px 0 5px 0;
  .equip-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .add-img {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 10px;
  }
  .title-text {
    color: #232b38;
    font-size: 18px;
    margin-right: 6px;
    padding-left: 6px;
  }
  .title-img {
    width: 12px;
    height: 8px;
  }
}
.navigator-box {
  width: 40px;
  height: 40px;
  position: absolute;
  text-align: center;
  line-height: 33px;
  top: 115px;
  z-index: 100;
  left: 13px;
  background: url("../../../assets/imgs/Position/icon_zhinanzhen1.png")
    no-repeat;
}
.pop-wrap {
  width: 100%;
  background: #fff;
  padding: 0 12px;
  box-sizing: border-box;
  display: flex;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  align-items: center;
  div.e-info {
    // 数据信息
    margin: 20px 0;
    width: 72%;
    border-right: 1px solid #e6e7ed;
    padding-right: 18px;
    & div {
      font-size: 13px;
      color: #232b38;
      line-height: 22px;
      i {
        margin-left: 20px;
      }
      & > span.f {
        display: inline-block;
        min-width: 120px;
      }
      .gap {
        margin-left: 10px;
      }
    }
  }
   div.e-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    img {
      width: 53px;
      height: 53px;
    }
    div {
      font-size: 13px;
      color: #232b38;
    }
  }
}
.pop.van-popup {
  overflow: visible;
  overflow-y: visible;
}
// 弹出框
.pop {
  background: transparent;
}
.top-header {
  // 点击收缩
  width: 100%;
  img {
    margin: 0 auto;
    width: 60px;
    height: 16px;
    margin-bottom: -2px;
    display: block;
  }
}
.headertitle {
  font-size: 15px;
  color: #232b38;
  line-height: 40px;
  text-align: center;
}
.popupradio {
  font-size: 15px;
  color: #878d97;
  line-height: 30px;
}
.popupradio .radiolabel {
  color: #878d97;
  line-height: 30px;
}
.van-radio-checked .radiolabel {
  color: #232b38;
}
.line {
  height: 0px;
  border: 0.5px solid #e6e7ed;
  margin: 5px auto;
}
.bottom-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  img {
    margin: 0 auto;
    width: 60px;
    height: 16px;
    margin-bottom: 0;
    display: block;
  }
}
</style>
