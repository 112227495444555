import * as types from "./types"
import http from "@/utils/http"
// 轻提示
import { Toast } from 'vant';

let state = {
  count: 0,
  data: []
}
// 异步任务
let actions = {
  [types.TABLEDATA] (context, params) {
    http.post('m-sms-vertifycode-check').then(data => {
      context.commit(types.TABLEDATA, data.data)
    })
  }
}
// 同步任务
let mutations = {
    [types.INCREMENT](state, data) {
        state.count++
    },
    [types.DECREMENT](state, data) {
        state.count--
    },
    [types.TABLEDATA](state, data) {
        Toast("数据获取成功")
        console.log("data", data)
        state.data = data.list
    }
}
// getter setter

export default { state, actions, mutations }
