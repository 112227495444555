import ExampleIndex from '@/view/example/Index'
import VBaiduMap from '@/view/example/VBaiduMap'
import BMap from '@/view/example/BMap'

export default [

  {
    path: '/example/index',
    name: 'Example',
    component: ExampleIndex
  },
  {
    path: '/example/vbmap',
    name: 'VBaiduMap',
    component: VBaiduMap
  },
  {
    path: '/example/bmap',
    name: 'BMap',
    component: BMap
  }
]
