
<template>
  <div class="wrap">
    <div class="search-wrap mapsearch">
      <div class="van-search__content">
        <div class="van-cell__value van-cell__value--alone">
          <div class="van-field__body">
            <!-- <input type="search" placeholder="搜索地址" id="suggestId" class="van-field__control"> -->
            <img :src='equipmentPic'
                 class="equipimg" />
            <span class="titletext">{{Equinfo.equipmentName}}</span>
            <img class="titleimg"
                 @click='alertsetting'
                 src="../../assets/imgs/Position/xiala.png" />
            <img class="addimg"
                 @click='alertadd'
                 src="../../assets/imgs/Position/tianjia.png" />
          </div>
        </div>
      </div>
    </div>
    <!-- 暂无相关轨迹点 S -->
    <div v-if="noPoint"
         class="no-data">
      <img src="../../assets/imgs/icon_kong.png" />
      <div> {{message}}</div>
    </div>
    <div v-else
         id="mapPanel">
      <div class="map-loading-center">
        <van-loading type="spinner"
                     color="#1989fa"
                     style="margin: 0 auto" />
      </div>
    </div>
    <span class="text">{{lEquipInfo.equipmentName}} {{site}}</span>
    <!-- 书包 手机 -->
    <div class="card-and-phone"
         :class="infoShow? 'up': ''">
       <div class="card"
           @click="handleJumpPhone(lEquipInfo)">
        <img 
             src="https://wwmimgs.oss-cn-shenzhen.aliyuncs.com/xiaomao/2021/09/26/%E7%94%B5%E8%AF%9D.png" />
        <p :style="imgActive=='card'?'color:#2C92F9':''">电话</p>
      </div>
      <div class="card"
           @click="switchLocation('card')">
        <img v-if="imgActive=='card'"
             src="../../assets/imgs/Position/icon_xiongka2.png" />
        <img v-else
             src="../../assets/imgs/Position/icon_xiongka.png" />
        <p :style="imgActive=='card'?'color:#2C92F9':''">书包</p>
      </div>
      <div class="phone"
           @click="switchLocation('phone')">
        <img v-if="imgActive=='phone'"
             src="../../assets/imgs/shouji_red.png" />
        <img v-else
             src="../../assets/imgs/Position/icon_shouji2.png" />
        <p :style="imgActive=='phone'?'color:#E6421A':''">手机</p>
      </div>
    </div>
    <!-- 查看街景 -->
    <div class="view-scene"
         @click="viewScene"
         :class="infoShow? 'up': ''">
      <img v-if="sceneShow"
           src="http://wwmimgs.oss-cn-shenzhen.aliyuncs.com/gps/2019-06-29/icon_huanjing.png" />
    </div>
    <div class="bottom-button"
         @click="showPop">
      <img v-if="showPop == true"
           src="../../assets/imgs/mine/icon_hebing.png" />
      <img v-else
           src="../../assets/imgs/mine/icon_shangla.png" />
    </div>
    <div class="popupdiv">
      <!-- 弹出框 -->
      <van-popup v-model="infoShow"
                 position="bottom"
                 :overlay="false"
                 class="pop">
        <span class="top-header"
              @click=" infoShow = false ">
          <img src="../../assets/imgs/mine/icon_hebing.png" />
        </span>
        <div class="pop-wrap">
          <div class="e-info">
            <div>{{site}}</div>
            <div>
              {{updataTime | formatTime}}
              <i>{{status}}</i>
            </div>
            <div>
              <span>速度：{{speed}} km/h</span>
              <span class="gap">
                定位模式：
                <span v-if="type == 1">GPS</span>
                <span v-else-if="type == 2">WIFI</span>
                <span v-else-if="type == 3">LBS</span>
                <span v-else>暂无信息</span>
              </span>
            </div>
            <div>
              <span class="f">经度：{{lng | formatLng}}</span>
              <span>纬度：{{lat | formatLat}}</span>
            </div>
          </div>
          <!-- 导航图标 -->
          <div class="e-img">
            <img src="../../assets/imgs/Position/icon_daohang.png"
                 @click="goNar" />
            <div>导 航</div>
          </div>
        </div>
      </van-popup>
    </div>
    <div class="pos-icon"
         v-if="mapShow">
      <div class="obj"></div>
    </div>
    <v-bar :active="active"
           style="position: fixed;buttom:0;left:0"></v-bar>
  </div>
</template>
<script>
import { loadingMap } from '@/utils/load'
import { Dialog, Toast } from 'vant'
import http from '@/utils/http'
import { formatDate } from '@/utils/datetime'
import mine from '@/store/mine/index'
import { mapState } from 'vuex'
import { LOCLAST } from '@/store/mine/types'
import { setTimeout } from 'timers'

import vanBar from '@/view/componse/vartarbbar'
import { constants } from 'os'
export default {
  components: {
    'v-bar': vanBar
  },
  data () {
    return {
      sceneShow: true,
      imgActive: 'card',
      map: null, // 地图实例
      geoLocation: null,
      type: '',
      updataTime: '',
      mk: null, // 设备定位当前标记
      locationMk: null, // 手机定位当前标记
      myIcon1: null,
      myIcon2: null,
      infoShow: false, // 详情弹框
      status: '',
      myData: '',
      locIndex: null, // 最新轨迹点 定时器
      id: '',
      active: 0,
      default: 'http://wwmimgs.oss-cn-shenzhen.aliyuncs.com/gps/2019-07-24/icon_touxiang.png',
      panoramaService: null,
      marker2: null,
      mapShow: false,
      Listdata: '',
      icon2: ' http://wwmimgs.oss-cn-shenzhen.aliyuncs.com/gps/2019-06-30/phone_site.png ',
      icon1: ' http://wwmimgs.oss-cn-shenzhen.aliyuncs.com/gps/2019-06-30/card_site.png',
      IMEI: '',
      equipmentPic: this.default,
      request: {
        page: 1,
        pageSize: 8,
        name: ''

      },
      lng: '',
      lat: '',
      noPoint: false,
      message: '无绑定设备信息',
      Equinfo: {
        equipmentPic: ''
      }
    }
  },
  filters: {
    formatTime (time) {
      if (time == null || time === '') {
        return '暂无更新时间'
      }
      let date = new Date(time)
      return '更新于 ' + formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    },
    formatLat (lat) {
      if (lat == null || lat === '') {
        return '暂无信息'
      }
      var index = lat.toString().indexOf('\.')
      lat = lat.toString().substring(0, index + 5)
      return lat
    },
    formatLng (lng) {
      if (lng == null || lng === '') {
        return '暂无信息'
      }
      var index = lng.toString().indexOf('\.')
      lng = lng.toString().substring(0, index + 5)
      return lng
    }
  },
  computed: {
    ...mapState({
      lEquipInfo: state => mine.state.lEquipInfo,
      // lat: state => mine.state.lat,
      // lng: state => mine.state.lng,
      site: state => mine.state.site,
      speed: state => mine.state.speed,
      noPositionPoint: state => mine.state.noPositionPoint
    })
  },
  created () {
    this.testLIst()
  },
  methods: {
    handleJumpPhone(e){
       console.log(e.id)
        // this.$router.push('/positionPhone')
        this.$router.push({
          path:'/positionPhone',
          query:{id:e.id}
        })
    },
    // 设备列表是否发生变化
    testLIst () {
      this.Equinfo = sessionStorage.getItem('nowEquiment_teacher') ? JSON.parse(sessionStorage.getItem('nowEquiment_teacher')) : {}
      console.log(this.Equinfo)
      JSON.stringify(this.Equinfo) != '{}' ? (this.IMEI = this.Equinfo.IMEI ? this.Equinfo.IMEI : this.Equinfo.imei) : ''
      http.post('p-gps-equipmentEasy-query', this.request).then(res => {
        if (res.code == 'ok') {
          if (res.data.dataList && res.data.dataList.length != 0) {
            // console.log(0, this.etrue(res.data.dataList))
            if (!this.etrue(res.data.dataList)) { // 返回的数据存在session中
              this.Equinfo = res.data.dataList && res.data.dataList.length > 0 ? res.data.dataList[0] : {}
              sessionStorage.setItem('nowEquiment_teacher', JSON.stringify(this.Equinfo))
              this.equipmentPic = this.Equinfo.equipmentPic ? this.Equinfo.equipmentPic : this.default

              console.log(this.Equinfo)
            } else { // 不存在

            }
          } else {
            this.Equinfo = {}
            this.equipmentPic = this.default

            sessionStorage.setItem('nowEquiment_teacher', '')
          }
          console.log(this.Equinfo)
          this.start()
        }
      })
    },
    etrue (dataList) {
      var a = false
      dataList.map(v => {
        // console.log(v.IMEI == this.IMEI, v.IMEI, this.IMEI)
        if (v.IMEI == this.IMEI) {
          this.Equinfo = Object.assign({}, v)
          this.equipmentPic = this.Equinfo.equipmentPic ? this.Equinfo.equipmentPic : this.default

          sessionStorage.setItem('nowEquiment_teacher', JSON.stringify(this.Equinfo)) // 存储当前设备信息
          // break;
          a = true
        }
      })
      return a
    },
    // 弹出修改设备的窗口
    alertsetting () {
      this.$router.push('/Equmentchios')
    },
    // 切换定位
    switchLocation (str) {
      this.imgActive = str
      /// /debugger
      if (str == 'card') { // 设备的定位
        if (!this.lat || !this.lng) {
          if (this.IMEI) {
            this.findlocl()
          } else {
            this.switchLocation('phone')
            this.$toast('暂无位置信息')
          }
        } else if (this.lng && this.lat) {
          // console.log(this.mk)
          this.map.panTo(this.mk.getPosition())
        }
      } else { // 当前的定位
        // this.equipmentPic = this.default

        this.geoLocation = new window.BMap.Geolocation()
        this.getPos()
      }
    },
    // 删除之前定位的点
    deletePoint (data) {
      this.map.removeOverlay(data)
      return false
    },
    // 添加设备
    alertadd () {
      this.$router.push('AddEqu')
    },
    start () {
      this.loadScript()
      this.Listdata = {}
      if (JSON.stringify(this.Equinfo) != '{}') {
        this.equipmentPic = this.Equinfo.equipmentPic ? this.Equinfo.equipmentPic : this.default
        console.log(this.Equinfo.equipmentPic)
        this.lng = this.Equinfo.lng
        this.lat = this.Equinfo.lat
        this.IMEI = this.Equinfo.IMEI ? this.Equinfo.IMEI : this.Equinfo.imei
        let locationTime = this.Equinfo.locationTime * 1000 // 刷新设备定位的时间（毫秒

        this.locIndex ? clearInterval(this.locIndex) : ''
        this.findlocl()
        // console.log(locationTime)
        this.locIndex = setInterval(() => {
          this.findlocl()
        }, locationTime)
      } else {
        var that = this
        // p-gps-equipmentEasy-query 设备列表
        that.switchLocation('phone')

        this.noPoint = true
      }
    },
    findlocl () {
      let local = []
      // console.log(1111, this.IMEI, this.id)
      this.IMEI = this.Equinfo.IMEI ? this.Equinfo.IMEI : this.Equinfo.imei
      this.id = this.Equinfo.id
      this.status = this.Equinfo.equipmentStatusName
      this.$store.commit('SETID', { id: this.id })
      this.$store.dispatch(LOCLAST, { IMEI: this.IMEI })

      http.post('p-gps-locLast-query', { IMEI: this.IMEI }).then(res => {
        if (res.code == 'ok') {
          local.push({ lat: res.data.newPoint.loc.lat, lon: res.data.newPoint.loc.lon })
          this.lng = local[0].lon
          this.lat = local[0].lat
          this.type = res.data.newPoint.loc.type
          this.updataTime = res.data.newPoint.versionTime
          // console.log(this.type)
          if (this.lng && this.lat) {
            console.log(this.Equinfo)
            // this.imgActive = 'card'
            let obj = this.Equinfo
            obj.lng = this.lng ? this.lng : ''
            obj.lat = this.lat ? this.lat : ''
            var str = JSON.stringify(obj)
            sessionStorage.setItem('nowEquiment_teacher', str) // 存储当前设备信息
            console.log(this.Equinfo)
            if (this.mk) {
              this.deletePoint(this.mk)
            }
            // console.log()
            this.mk = new BMap.Marker(new BMap.Point(this.lng, this.lat))
            this.mk.setIcon(this.myIcon1)
            this.map.addOverlay(this.mk)
            this.map.centerAndZoom(new BMap.Point(this.lng, this.lat), 15)
            this.switchLocation('card')
          } else {
            // this.imgActive = 'phone'
            this.switchLocation('phone')
          }
        } else {
          this.$toast(res.message)
          // this.imgActive = 'phone'
          this.switchLocation('phone')
        }
      })
    },
    // 打开弹框
    showPop () {
      this.infoShow = true
    },
    getPos () { // 获取当前位置
      // console.log('获取当前定位')
      this.geoLocation = new window.BMap.Geolocation()
      let geoL = this.geoLocation
      let that = this
      // console.log(geoL)
      geoL.getCurrentPosition(r => {
        // console.log('now-location', r)
        if (geoL.getStatus() == BMAP_STATUS_SUCCESS) {
          that.locationMk = new BMap.Marker(r.point)
          // that.locationMk.setPosition(r.point)
          that.locationMk.setIcon(that.myIcon2)
          that.locationMk.setPosition(r.point)
          that.map.addOverlay(that.locationMk)
          that.map.panTo(r.point)
        } else {
        }
      })
    },
    // 导航 调起移动端百度地图
    goNar () {
      window.location.href = 'http://map.baidu.com/mobile/'
    },
    // 加载地图
    loadScript () {
      loadingMap('baidu', 'w0xIuTu7ZCymWOc8U9Sma0pXiWFFK1Wc').then(() => {
        this.map = new BMap.Map('mapPanel')
        // 初始化位置
        this.map.centerAndZoom(new BMap.Point(116.404, 39.915), 16)
        this.map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
        this.map.addEventListener('dragend', () => { })
        this.myIcon2 = new BMap.Icon(this.icon2, new BMap.Size(32, 32))
        this.myIcon1 = new BMap.Icon(this.icon1, new BMap.Size(32, 32))
        this.initMap()
      })
    },
    initMap () {
      if (this.lng && this.lat) {
        this.mk = new BMap.Marker(new BMap.Point(this.lng, this.lat))
        this.switchLocation('card')
        this.mk.setIcon(this.myIcon1)
        this.map.addOverlay(this.mk)
        this.map.panTo(this.lng, this.lat)
      } else {
      }
      // 地图、卫星图
      var mapType = new BMap.MapTypeControl(
        {
          mapTypes: [BMAP_NORMAL_MAP, BMAP_SATELLITE_MAP],
          anchor: BMAP_ANCHOR_TOP_RIGHT
        }
      )
      this.map.addControl(mapType)
    },
    // 返回上一页
    goBack () {
      this.$router.go(-1)
    },
    viewScene () {
      if (this.lng && this.lat) {
        this.$router.push('quanjing')
      } else {
        Toast('设备暂无定位')
      }
    }
  },
  beforeDestroy () {
    // 清除定时器
    clearInterval(this.locIndex)
  }
}
</script>

<style lang="less" scoped>
#mapPanel {
  min-height: 300px;
  height: 100%;
  width: 100%;
  position: absolute !important;
  background-color: rgba(76, 76, 76, 0.7686274509803922);
}
.text {
  color: transparent;
  visibility: hidden;
}
.search-wrap {
  text-align: center;
  position: absolute;
  width: 349px;
  height: 48px;
  line-height: 48px;
  top: 20px;
  z-index: 100;
  left: 4%;
  padding-right: 15px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 13px;
  box-shadow: 0px 1px 5px 0px rgba(51, 51, 51, 0.2);
  input {
    font-size: 14px;
    color: hsl(217, 6%, 75%);
    line-height: 48px;
  }
  .serach-icon {
    font-size: 22px;
    line-height: 48px;
  }
}
.mapsearch .van-search__content {
  background: #fff;
  border-radius: 13px;
}
.searchbox {
  margin-top: 40px;
}
.popupdiv {
  position: absolute;
  bottom: 50px;
  left: 0;
}
.pop-wrap {
  width: 100%;
  background: #fff;
  padding: 0 12px;
  box-sizing: border-box;
  display: flex;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  align-items: center;
  div.e-info {
    // 数据信息
    margin: 20px 0;
    width: 72%;
    border-right: 1px solid #e6e7ed;
    padding-right: 18px;
    & div {
      font-size: 13px;
      color: #232b38;
      line-height: 22px;
      i {
        margin-left: 20px;
      }
      & > span.f {
        display: inline-block;
        min-width: 120px;
      }
      .gap {
        margin-left: 10px;
      }
    }
  }
  div.e-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    img {
      width: 53px;
      height: 53px;
    }
    div {
      font-size: 13px;
      color: #232b38;
    }
  }
}
.pop.van-popup {
  overflow: visible;
  overflow-y: visible;
}
// 弹出框
.pop {
  background: transparent;
  margin-bottom: 50px;
}
.top-header {
  // 点击收缩
  width: 100%;
  img {
    margin: 0 auto;
    width: 60px;
    height: 16px;
    margin-bottom: -2px;
    display: block;
  }
}
.headertitle {
  font-size: 15px;
  color: #232b38;
  line-height: 40px;
  text-align: center;
}
.popupradio {
  font-size: 15px;
  color: #878d97;
  line-height: 30px;
}
.popupradio .radiolabel {
  color: #878d97;
  line-height: 30px;
}
.van-radio-checked .radiolabel {
  color: #232b38;
}
.line {
  height: 0px;
  border: 0.5px solid #e6e7ed;
  margin: 5px auto;
}
.headcell {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
}
.spacebetween {
  justify-content: space-between;
}
.celltitle {
  line-height: 40px;
  color: #232b38;
}
.cellinfo {
  line-height: 40px;
  color: #878d97;
  font-size: 13px;
}
.cellicon {
  width: 18px;
  height: 18px;
  margin-left: 12px;
}
.infobox {
  width: 8rem;
  height: 184px;
  border-radius: 10px;
  padding: 15px;
  background: #fff;
  font-size: 15px;
}
.titletext {
  color: #232b38;
  font-size: 18px;
  margin-right: 6px;
}
.equipimg {
  width: 38px;
  height: 38px;
  margin: 5px 11px 0 0;
  border-radius: 50%;
}
.titleimg {
  width: 12px;
  height: 8px;
}
.addimg {
  width: 24px;
  position: absolute;
  right: 0;
  top: 14px;
  // margin: 14px 15px 13px 0;
}
.infotitle {
  font-size: 16px;
  color: #000;
  line-height: 40px;
  text-align: center;
}
.infodesc {
  color: #878d97;
  line-height: 34px;
}
.bottombox {
  height: 48px;
  border-top: 1px solid #e6e7ed;
  background: #fff;
  font-size: 15px;
  line-height: 48px;
  color: #232b38;
}
.buttombutton {
  width: 50%;
  text-align: center;
}
.surebutton {
  color: #2c92f9;
  border-left: 1px solid #e6e7ed;
}
.pickerbox {
  background: #fff;
}
.bottom-button {
  position: fixed;
  bottom: 50px;
  left: 0;
  width: 100%;
  text-align: center;
  img {
    margin: 0 auto;
    width: 60px;
    height: 16px;
    margin-bottom: 0;
    display: block;
  }
}
// 全景
.view-scene {
  transition: all 0.6s; // 过渡动画
  text-align: center;
  position: absolute;
  bottom: 58px;
  z-index: 100;
  left: 13px;
  border-radius: 8px;
  // width: 24px;
  // height: 24px;
  img {
    width: 48px;
    height: 48px;
  }
}
.view-scene.up {
  transition: all 0.6s; // 过渡动画
  bottom: 214px;
}
// 手机和书包
.card-and-phone {
  transition: all 0.6s;
  text-align: center;
  box-sizing: border-box;
  background: #fff;
  border-radius: 10px;
  position: absolute;
  bottom: 58px;
  z-index: 100;
  right: 13px;
  padding: 9px;
  .card {
    border-bottom: 1px solid #e6e7ed;
    padding-bottom: 12px;
    img {
      width: 24px;
    }
  }
  .phone {
    padding-top: 7px;
    img {
      width: 17px;
    }
  }
  p {
    color: #232b38;
    font-size: 11px;
    padding: 3px 0;
  }
}
.card-and-phone.up {
  transition: all 0.6s;
  bottom: 214px;
}
</style>
