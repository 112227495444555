import bkey from '@/utils/global'
export function loadingMap (type) {
  return new Promise(function (resolve, reject) {
    // 百度地图引入
    let bmapLoad = () => {
      window.HOST_TYPE = '2'
      window.BMap_loadScriptTime = (new Date()).getTime()
      window.BMap = window.BMap || {}
      window.BMap.apiLoad = function () { delete window.BMap.apiLoad; if (typeof init === 'function') { init() } }
      let s = document.createElement('script')
      s.src = 'https://api.map.baidu.com/api?v=2.0&ak=' + bkey + '&services=&t=20180629105709&s=1'
      document.body.appendChild(s)
      s.onload = function () {
        type == 'baidu' ? resolve(BMap) : resolve(AMap)
      }
    }
    if (type != 'baidu') { amapLoad() } else { bmapLoad() };
  })
}
