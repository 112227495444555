<template>
  <div class="container">
    <div>
      <div class="search-wrap mapsearch b3radio">
        <div class="van-search__content">
          <div class="van-cell__value van-cell__value--alone">
            <div class="van-field__body">
              <img class="titleimg"
                   src="../../assets/imgs/Position/icon_sous.png" />
              <input type="text"
                     placeholder="请输入设备名称或IMEI"
                     v-model='resquest.name'
                     @change="handInput"
                     class="inputSearch">
              <span class="addimg"
                    @click='goback'>取消</span>
            </div>
          </div>
        </div>
      </div>
      <div class="divList b3radio">
        <div class="nowEqu">当前设备：{{equipmentName}}</div>

        <div class="relation"
             v-for='(item,index) in  dataList'
             :key='item.id'>
          <div class="duding">
            <van-card :desc="'班级：'+item.className"
                      :class=" index>0?'bt3':''+ ' card-bgc'"
                      @click='goposition(item)'
                      :title="item.equipmentName"
                      :thumb="item.equipmentPic">
              <div slot="footer"
                   class="rightButton">
                <img src='../../assets/imgs/Position/icon_zainaer.png'
                     class="zainaering"></img>
                <span class='zainaer'>在哪儿</span>
              </div>
            </van-card>
          </div>
        </div>
        <div v-if="noda&&!isLoad"
             class="no-data">
          <img src="../../assets/imgs/icon_kong.png" />
          <div> 无设备</div>
        </div>
        <!--加载更多 -->
        <div class="load">
          <van-loading type="spinner"
                       class="load-loading"
                       v-if="isLoad" />
          <span class="all-loaded"
                :class="allLoaded? '': 'border'"
                @click="onLoad"
                v-else>
            {{allLoaded? '加载完成': '加载更多'}}</span>
        </div>
      </div>

    </div>
  </div>
  </div>
</template>
<script>
import http from '@/utils/http'
export default {
  data () {
    return {
      resquest: {
        name: '', //	选填	设备名称查询
        page: 1, //		选填	当前页,不写默认为1
        pageSize: 10 //		选填	每页显示数目,不写默认为10
      },
      dataList: [],
      data: {},
      isLoad: false,
      equipmentName: '',
      allLoaded: false,
      noda: false
    }
  },
  methods: {
    start (data) {
      // console.log(111, data)
      this.noda = false

      this.equipmentName = sessionStorage.getItem('nowEquiment_teacher') ? JSON.parse(sessionStorage.getItem('nowEquiment_teacher')).equipmentName : ''
      // console.log(this.dataList)
      // console.log(this.equipmentName)
      if (!data) {
        this.resquest.page = 1
        this.dataList.splice(0)
      }
      http.post('p-gps-equipmentEasy-query', this.resquest).then(res => {
        this.data = res.data
        if (this.data.dataList && this.data.dataList.length != 0) {
          this.data.dataList = this.data.dataList.map(v => {
            if (!v.equipmentPic) {
              v.equipmentPic = 'http://wwmimgs.oss-cn-shenzhen.aliyuncs.com/gps/2019-07-24/%E9%BB%98%E8%AE%A42.png'
            }
            return v
          })
          console.log(data, 111)
          if (!data) {
            this.resquest.page = 1
            this.dataList.splice(0)
            this.dataList = this.data.dataList
          } else {
            this.dataList = this.dataList.concat(this.data.dataList)
          }

          // console.log(this.dataList, this.data.dataList)
        }
        if (this.resquest.page >= this.data.totalPage) {
          this.allLoaded = true
        }
        if (res.data.total == 0) {
          this.noda = true
        }
        this.isLoad = false
      })
    },
    // 搜索
    handInput () {
      // console.log(this.resquest.name)
      this.isLoad = true
      this.start(null)
    },
    goback () {
      this.$router.go(-1)
    },
    goposition (item) {
      console.log(item)
      var str = JSON.stringify(item)
      sessionStorage.setItem('nowEquiment_teacher', str) // 存储当前设备信息
      // debugger
      this.$router.push({ path: '/Position' })
    },
    // 加载
    onLoad () {
      // //console.log("当前页=", this.page, '总页数=',this.totalPage)
      // this.$store.commit('CHANGELOAFDSTATUS', { isLoad: true })
      if (this.allLoaded) {

      } else {
        if (this.resquest.page < this.data.totalPage) {
          this.resquest.page += 1
          this.start(1)
        } else {
          this.allLoaded = true
        }
      }
    }

  },
  mounted () {
    this.start(1)
  }
}
</script>
<style lang="less" scoped>
.load {
  bottom: 0;
  height: 50px;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  .van-loading.load-loading {
    margin: 0 auto;
  }
  .all-loaded {
    display: inline-block;
    font-size: 15px;
    padding: 5px 10px;
    color: #8a8888;
  }
}
.no-data img {
  margin-top: 0px !important;
}

.rightButton {
  position: absolute;
  right: 18px;
  top: 27%;
  width: 50px;
}
.search-wrap {
  text-align: center;
  height: 48px;
  line-height: 48px;
  z-index: 100;
  padding-right: 15px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 10px;
  margin: 2px 11px 0 11px;
}
.mapsearch .van-search__content {
  background: #fff;
  border-radius: 8px;
}
.searchbox {
  margin-top: 40px;
}
.container {
  box-sizing: border-box;
  width: 100%;
}
.van-card__thumb img {
  border-radius: 3px;
}
.van-card__content,
.van-card__header,
.van-card__thumb {
  background: #fff;
}
.addimg {
  font-size: 16px;
  position: absolute;
  right: 0;
}
.inputSearch {
  display: inline-block;
  font-size: 14px;
  margin-left: 5px;
  min-width: 70%;
}
.van-card__thumb {
  width: 50px;
  height: 60px;
  margin: 10px;
}
.van-card {
  height: 104px;
  padding: 0%;
  margin: 0.13333rem 0.4rem;
  border-bottom: 1px solid #eee;
}
.divList {
  margin-top: 14px;
  border: 1px solid #eee;
  background: #fff;
  border-radius: 10px;
  margin: 14px 13px 0 13px;
}
.nowEqu {
  color: #232b38;
  font-size: 16px;
  margin: 20px 0 15px 15px;
}
.van-card__content {
  width: 104px;
}
.zainaer {
  padding-top: 4px;
  font-size: 12px;
  color: #878d97;
  display: inline-block;
}
.van-card__title {
  font-size: 14px;
  color: #232b38;
  padding: 20px 0 10px 0px;
  max-height: -webkit-fill-available !important;
}
.van-card__desc .van-ellipsis {
  font-size: 12px;
  color: #878d97;
}
.relation {
  height: 80px;
  margin: 5px 0;
}
.zainaering {
  width: 30px;
}
.titleimg {
  width: 18px;
}
.b3radio {
  border-radius: 8px;
  box-shadow: 0px 1px 5px 0px rgba(51, 51, 51, 0.2);
}
.bt3 {
  border-top: 1px solid #e6e7ed;
}
.duding {
  position: relative;
}
</style>
