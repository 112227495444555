<template>
  <div>
    <van-nav-bar title="实时全景"
                 left-arrow
                 @click-left="onClickLeft" />
    <div id="panorama"></div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    start () {
      let obj = sessionStorage.getItem('nowEquiment_teacher')
      var map = new BMap.Map('panorama')
      map.addTileLayer(new BMap.PanoramaCoverageLayer())
      var panorama = new BMap.Panorama('panorama')
      // panorama.setPov({ heading: -40, pitch: 6 })
      panorama.setPosition(new BMap.Point(obj.lng, obj.lat)) // 根据经纬度坐标展示全景图
    }
  },
  mounted () {
    this.start()
  }
}
</script>
<style scoped>
#panorama {
  width: 100%;
  height: 598px;
}
</style>
