<template>
  <div class="container">
    <van-nav-bar title="设置登录密码"
                 left-arrow
                 :border="false"
                 @click-left="onClickLeft" />
    <van-cell-group class="cell_group_pwd">
      <van-field v-model="password"
                 class="count_pwd"
                 :type="ispassword"
                 clearable
                 maxlength='12'
                 placeholder="请输入6-12位字母加数字的组合密码">
        <img :src="yincang"
             class="van-icon"
             v-if="ispassword=='text'"
             slot="right-icon"
             @click="ispassword='password'" />
        <img :src="xianshi"
             slot="right-icon"
             class="van-icon"
             v-if="ispassword=='password'"
             @click="ispassword='text'" />
      </van-field>
    </van-cell-group>
    <div @click="handlelogin"
         class="login-button common-button">修改完成</div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { USERCHANGEPWD } from '@/store/login/types';
import login from '@/store/login/index';
import { Toast } from 'vant';
export default {
  data () {
    return {
      yincang: require('../../assets/imgs/icon_yincang@2x.png'),
      xianshi: require('../../assets/imgs/icon_xianshi@2x.png'),
      ispassword: 'password',
      password: '',
      passwdtextError: false,
      passwdtextErrormess: '',
      reg: /[^a-zA-Z0-9]{0,11}/,
      reg1: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/
    }
  },
  computed: {
    ...mapState({
      userchangePwddata: state => login.state.userchangePwddata
    })
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    // 验证密码
    // passwdtext () {
    //   // 验证输入的最后一个值是不是符合数字或者字母
    //   if (this.reg.test(this.password)) {
    //     this.passwdtextError = true
    //     this.passwdtextErrormess = '必须输入字母加数字的组合密码'
    //   } else {
    //     this.passwdtextError = false
    //     this.passwdtextErrormess = ''
    //   }
    // },
    handlelogin () {
      if (this.password) {
        if (!this.reg1.test(this.password)) {
          this.$toast('密码格式错误，必须输入6-12位字母加数字的组合密码')
        } else {
          let userd = JSON.parse(sessionStorage.getItem('userd'))
          let vertifyCode = userd.vertifyCode
          let phone = userd.phone
          this.$store.dispatch(USERCHANGEPWD, {
            password: this.password,
            type: 1,
            vertifyCode: vertifyCode,
            phone: phone,
            comeFrom: 'gps-user-forgetpwd' //	必填	此接口固定为：gps-user-forgetpwd
          })
          if (this.userchangePwddata.code == 'ok') {
            this.Toast('密码设置完成')
            this.$router.push({ path: '/login' })
          } else {
            Toast(this.userchangePwddata.message)
          }
        }
      } else {
        this.$toast('请输入密码')
      }
    }
  },
  mounted () { }
}
</script>
<style  scoped>
.count_pwd {
  font-family: Medium, "PingFangSC-Medium";
  font-size: 16px;
  color: #babdc2;
  height: 63px;
  margin: auto;
}
.van-icon {
  width: 20px;
  height: 14px;
  display: inline-block;
}
.container {
  position: relative;
  background: #ffffff;
  font-family: Medium, "PingFangSC-Medium";
  text-align: left;
  height: 666px;
  width: 375px;
}

.header_img {
  position: absolute;
  top: 14px;
  left: 14px;
}
.header {
  position: absolute;
  top: 16px;
  left: 152px;
  font-size: 18px;
  font-family: Medium, "PingFangSC-Medium";
  color: #2d3e4b;
}
.cell_group_pwd {
  position: absolute;
  top: 43px;
  left: 20px;
  width: 335px;
}
.login-button {
  position: absolute;
  top: 150px;
  left: 20px;
  width: 335px;
  margin: auto;
}
</style>
<style>
.van-cell__value {
  margin: auto;
}
</style>
