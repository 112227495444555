
import guiji from '@/view/locus/Index'

export default [
  {
    path: '/guiji',
    name: 'guiji',
    component: guiji
  }
]
