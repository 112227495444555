
<template>
  <div>
    <van-loading v-if="loading" class="loading" :style="iStyle" vertical />
    <van-uploader
      v-show="!loading"
      :accept="'image/*'"
      :before-read="beforeRead"
      :after-read="onRead"
      :style="iStyle"
      class="single-upload"
    >
      <img class="head-img" ref="refImg" />
    </van-uploader>
  </div>
</template>
<script>
import { getDateLocation } from "@/utils/datetime";
import { Toast } from "vant";
import compress from "./imgSet";
export default {
  data() {
    return {
      loading: false
    };
  },
  props: {
    value: {
      value: String,
      default: ""
    },
    iStyle: {
      value: String,
      default: ""
    }
  },
  mounted() {
    this.$refs.refImg.src =
      this.imageUrl ||
      "http://wwmimgs.oss-cn-shenzhen.aliyuncs.com/gps/2019-07-26/icon_touxiang%402x.png";
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          this.$refs.refImg.src = this.value;
        }
      },
      deep: true
    }
  },
  computed: {
    imageUrl() {
      return this.value;
    }
  },
  methods: {
    emitInput(val) {
      this.$emit("input", val);
    },
    beforeRead(file) {
      this.loading = true;
      return true;
    },
    onRead(file) {
      var self = this;
      let img = new Image();
      img.src = file.content;
      var big = file.file.size / 1024;
      if (big <= 2048) {
        this.url = file.content;
        this.afterRead(file.content, file);
      } else {
        img.onload = ()=> {
          let data = compress(img, 6);
          self.url = data;
          this.afterRead(self.url, file)
        };
      }
    },
    // 点击上传图片 到oss
    afterRead(data, file) {
      var that = this;
      let OSS = require("ali-oss");
      // base64格式的图片文件
      let urlData = data;
      const base64 = urlData.split(",").pop();
      const fileType = urlData
        .split(";")
        .shift()
        .split(":")
        .pop();
      function toBlob(urlData, fileType) {
        let bytes = window.atob(urlData);
        let n = bytes.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bytes.charCodeAt(n);
        }
        return new Blob([u8arr], { type: fileType });
      }
      // base64转blob
      const blob = toBlob(base64, fileType);
      // blob转arrayBuffer
      const reader = new FileReader();
      reader.readAsArrayBuffer(blob);
      reader.onload = event => {
        const client = new OSS({
          region: "oss-cn-shenzhen",
          accessKeyId: "LTAIdNHaMjUjOmmX",
          accessKeySecret: "wtr6YCZufyrDgc52I30kRhoHTVl5LQ",
          bucket: "wwmimgs"
        });

        // 文件名:文件夹名字 + 今日日期 + 文件名字
        const objectKey = `gps/${getDateLocation()}/${file.file.name}`;
        // arrayBuffer转Buffer
        const buffer = new OSS.Buffer(event.target.result);
        // 上传
        client
          .put(objectKey, buffer)
          .then(result => {
            console.log("上传成功：", result.url);
            this.$refs.refImg.src = result.url;
            this.emitInput(result.url);
            this.loading = false;
          })
          .catch(err => {
            console.log("上传失败", err);
          });
      };
    }
  }
};
</script>
<style lang="less" scoped>
.van-uploader {
  img {
    width: 100%;
    height: 100%;
  }
}
.uploadclassi {
  width: 100%;
  height: 100%;
  border: 2px solid #eef2f5;
  position: relative;
}
.van-icon-plus {
  width: 100px;
  height: 100px;
  font-size: 35px;
  vertical-align: middle;
  position: absolute;
  top: 33px;
  left: 33px;
}
.loading.van-loading {
  width: 30px;
  height: 30px;
}
</style>
