<template>
  <div>
    <van-nav-bar title="休眠时间" left-arrow @click-left="goBack"/>
    <van-cell-group>
      <van-cell title="休眠时间" is-link>
        <template slot="right-icon">
          <div>
            <van-switch class="resize" v-model="openStatus"></van-switch>
          </div>
        </template>
      </van-cell>
      <van-cell title="开始时间" is-link :value="beginTime" :border="false" @click="focusHandler(1)"></van-cell>
      <van-cell title="结束时间" is-link :value="endTime" @click="focusHandler(2)"></van-cell>
    </van-cell-group>
    <!-- 时间选择器 -->
    <transition name="movedown-fade">
      <div class="timepicker" v-if="showPicker">
        <div class="clock-wrap">
          <div class="clock">
            <div class="timedisplay">
              <!-- 上午 下午 -->
              <div class="timesort">
                <div
                  v-for="(item, index) in timeSort"
                  :key="index"
                  @click="changeTimeSort(item,index)"
                  :class="{active:index == isActive}"
                >{{item.text}}</div>
              </div>
              <!-- 时间 -->
              <div class="innerdisplay">
                <div class="time-left"></div>
                <div class="showtime">
                  <span
                    class="hour"
                    @click="mode = 'hour'"
                    :style="{ opacity: mode === 'hour' ? 1 : 0.7 }"
                  >{{fixHour(hour)}}</span>
                  <span>:</span>
                  <span
                    class="minutes"
                    @click="mode = 'minutes'"
                    :style="{ opacity: mode === 'minutes' ? 1 : 0.7 }"
                  >{{fixMinutes(minutes)}}</span>
                </div>
                <div class="time-right"></div>
              </div>
            </div>

            <div class="clockpanel">
              <div class="clockbg"></div>
              <div class="hourpicker">
                <div class="selector" :style="selectorRotateAngle"></div>
                <span
                  :key="i"
                  class="hourtxt"
                  v-for="i in 12"
                  :style="getHourStyle(i % 12)"
                  @click="mode === 'hour' ? hour = i : minutes = 5 * i"
                >{{mode === 'hour' ? i : (5 * i) % 60 || '00'}}</span>
              </div>
            </div>

            <div class="actionbuttons">
              <button @click="showPicker = false">
                <div>
                  <span>取消</span>
                </div>
              </button>
              <button @click="time = getTime()">
                <div>
                  <span>确定</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div
      class="mask"
      @click="showPicker=false"
      :style="{ opacity: showPicker ? 1 : 0, left: showPicker ? 0 : '-100%'}"
    ></div>
    <!--  -->
  </div>
</template>
<script>
const positions = [
  [0, 5],
  [54.5, 16.6],
  [94.4, 59.5],
  [109, 114],
  [94.4, 168.5],
  [54.5, 208.4],
  [0, 223],
  [-54.5, 208.4],
  [-94.4, 168.5],
  [-109, 114],
  [-94.4, 59.5],
  [-54.5, 19.6]
];

export default {
  name: "SleepTime",
  data () {
    return {
      openStatus: true,
      isActive: 0,
      timeSort: [
        { sort: 1, text: "上 午" },
        { sort: 2, text: "下 午" }
      ],
      beginTime: "",
      endTime: "",
      showPicker: false,
      mode: "hour",
      time: "",
      hour: 12,
      minutes: 0,
      type: "",
      sort: "1"
    };
  },
  computed: {
    selectorRotateAngle () {
      if (this.mode === "hour") {
        return {
          transform: `rotateZ(${this.hour * 30 + "deg"})`
        };
      } else {
        return {
          transform: `rotateZ(${this.minutes * 6 + "deg"})`
        };
      }
    },
  },
  created() {
    let status;
    if(this.$route.query.dormancyStatus == 'true') {
      status = true
    } else {
       status = false
    }
    this.openStatus = status
  },
  methods: {
    // 选择 上午、下午
    changeTimeSort (item, index) {
      console.log(item, index)
      this.isActive = index;
      this.sort = item.sort;
    },
    goBack () {
      let isOpen;
      if(this.openStatus == true) {
        isOpen = 1
      } else {
        isOpen = 2
      }
      this.$store.commit('SETDRMANCYSTATUS', isOpen)
      this.$router.go(-1);
    },
    focusHandler (type) {
      this.showPicker = true;
      this.type = type;
    },
    getHourStyle (i) {
      const hasSelected =
        (this.mode === "hour" && this.hour % 12 === i) ||
        (this.mode === "minutes" && this.minutes % 60 === i * 5);
      const styleObj = {
        transform: `translate(${positions[i][0] + "px"}, ${positions[i][1] +
          "px"})`,
        background: hasSelected ? "#009688" : "rgba(255, 255, 255, 0)",
        color: !hasSelected ? "#2c3e50" : "#FFF"
      };
      return styleObj;
    },

    fixHour (h) {
      if(this.sort == '1') { // 上午
        return h < 10 ? "0" + h : h;
      }
      if(this.sort == '2') { // 下午
         return h + 12;
      }
    },

    fixMinutes (m) {
      return m % 60 < 10 ? "0" + (m % 60) : m % 60;
    },

    getTime () {
      if (this.type == '1') { // 开始时间
        const { hour, minutes } = this;
        this.showPicker = false;
        this.beginTime =
          this.fixHour(hour) +
          ":" +
          (minutes < 10 ? "0" + minutes : minutes);
        this.$store.commit('SETBEGTIME', this.beginTime)
      } else {  // 结束时间
        const { hour, minutes } = this;
        this.showPicker = false;
        this.endTime =
          this.fixHour(hour) +
          ":" +
          (minutes < 10 ? "0" + minutes : minutes);
        this.$store.commit('SETENDTIME', this.endTime)
      }
    }
  }
};
</script>

<style scoped>
input {
  outline: none;
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1400;
  will-change: opacity;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.movedown-fade-enter-active,
.movedown-fade-leave-active {
  transition: all 0.5s ease;
}

.movedown-fade-enter,
.movedown-fade-leave {
  transform: translate3d(0, -20px, 0);
  opacity: 0;
}

.timepicker {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  width: 100%;
  height: 100%;
  padding-top: 75px;
}

.clock-wrap {
  width: 280px;
  max-width: 768px;
  border-radius: 2px;
  margin: 0 auto;
  background-color: rgb(255, 255, 255);
}

.clock {
  box-shadow: rgba(0, 0, 0, 0.247059) 0px 14px 45px,
    rgba(0, 0, 0, 0.219608) 0px 10px 18px;
  border-radius: 2px;
}

.timedisplay {
  display: flex;
  justify-content: center;
  /* align-items: baseline; */
  padding: 14px 0;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: #009688;
  color: white;
}
/* 上午 下午 */
.timesort {
  font-size: 16px;
  padding: 16px 10px 0 0;
}
.timesort > div {
  opacity: 0.4;
}
.timesort > div:nth-of-type(2) {
  padding: 10px 0 0 0;
}
.timesort div.active {
  opacity: 1;
}

.innerdisplay {
  margin: 6px 0px;
  line-height: 58px;
  height: 58px;
  font-size: 58px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  /* border: 1px solid red; */
}

.time-left,
.time-right {
  flex: 1 1 0%;
  position: relative;
  line-height: 17px;
  height: 17px;
  font-size: 17px;
}

.showtime {
  margin: 0px 10px;
}

.hour,
.minutes {
  cursor: pointer;
}

.clockpanel {
  height: 280px;
  padding: 10px;
  position: relative;
  box-sizing: content-box;
}

.clockbg {
  position: absolute;
  top: 20px;
  width: 260px;
  height: 260px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.0666667);
}

.hourpicker {
  height: 100%;
  width: 100%;
  border-radius: 100%;
  position: relative;
  /*pointer-events: none;*/
  box-sizing: border-box;
}

.hourtxt {
  display: inline-block;
  position: absolute;
  width: 32px;
  height: 28px;
  border-radius: 50%;
  left: calc(50% - 16px);
  top: 10px;
  text-align: center;
  padding-top: 7px;
  font-size: 18px;
  cursor: pointer;
  /* border: 1px solid red; */
}

.selector {
  height: 40%;
  background: #009688;
  width: 2px;
  left: calc(50% - 1px);
  position: absolute;
  bottom: 50%;
  transform-origin: center bottom 0px;
  pointer-events: none;
}

.actionbuttons {
  padding: 8px;
  width: 100%;
  text-align: right;
  margin-top: 0px;
  border-top: none;
}

.actionbuttons button {
  border: 10px;
  box-sizing: border-box;
  display: inline-block;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  outline: none;
  font-size: inherit;
  font-weight: inherit;
  transform: translate(0px, 0px);
  height: 36px;
  line-height: 36px;
  min-width: 88px;
  color: #009688;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  border-radius: 2px;
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);
  text-align: center;
  -webkit-user-select: none;
}

.actionbuttons span {
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  vertical-align: middle;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
}
</style>


