<template>
  <div>
    <van-card class='card_list'
              :desc="'类型：'+style"
              :title="'设备名称：'+title"
              :thumb="imageURL">
      <div class="van-card__desc van-ellipsis">围栏报警：{{time}}</div>
      <div class='right_slots'>
        <van-button size="mini"
                    @click="doSome">按钮</van-button>
      </div>
    </van-card>
  </div>
</template>
<script>
export default {
  name: 'cardList',
  data () {
    return {
      time: 11111,
      imageURL: 'https://wwmimgs.oss-cn-shenzhen.aliyuncs.com/app/48f17428eb688fc65f6683e35a1f56bb.jpg',
      style: '234324',
      title: '1233'

    }
  },
  methods: {
    doSome () {

    }
  }
}
</script>
<style lang="stylus">
.card_list
  position relative
  .van-card__content
    font-size 18px
</style>
